import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Result } from '../_models';
import { environment } from '../../../environments/environment';

@Injectable()
export class EmailTemplateService {
    private canonicalName: string;
    //private messageSource = new BehaviorSubject({});
    //currentMessage = this.messageSource.asObservable();

    //private actionFn = new BehaviorSubject('');
    //currentAction = this.actionFn.asObservable();
    APIURL = '';
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;
    constructor(private http: HttpClient) {
        const newurl = window.location.href;

        if (this.isOnPremise) this.APIURL = this.appUrl;
        else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
        }

        if (this.APIURL.search('localhost') > -1) {
            // this.APIURL = 'https://main.api.sharewinds.com/api/v1/';
            this.APIURL = environment.localHostUrl;
        }
        //this.APIURL = 'http://localhost:56622/api/v1/';
    }

    getEmailTemplates(GroupId: string) {
        return this.http.get<Result>(`${this.APIURL}EmailTemplate/get-email-template-list/` + GroupId);
    }
    SaveEmailTemplates(EmailTextForm: any) {
        return this.http.post<Result>(`${this.APIURL}EmailTemplate/save-email-template/`, EmailTextForm);
    }
    GetEmailTags(emailId: string) {
        return this.http.get<Result>(`${this.APIURL}EmailTemplate/get-email-tags/` + emailId);
    }
    GetEmailTags2(emailId: string) {
        return this.http.get<Result>(`${this.APIURL}EmailTemplate/get-email-tags2/` + emailId);
    }

    GetEmailBody(emailId: string) {
        return this.http.get<Result>(`${this.APIURL}EmailTemplate/get-email-body/` + emailId);
    }

    getEmailGroups() {
        return this.http.get<Result>(`${this.APIURL}EmailTemplate/get-email-groups-list/`);
    }
   

    ResetTemplate(emailId, LangId) {
        return this.http.post<Result>(`${this.APIURL}EmailTemplate/reset-template/${emailId}/${LangId}`, emailId);
    }
    GetEmailBodyByLang(emailId, LangId) {
        return this.http.get<Result>(
            `${this.APIURL}EmailTemplate/get-email-body-lang/${emailId}/${LangId}`
        );
    }
}
