import { COMMA, E, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
    Component,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    Input,
    OnChanges,
    SimpleChanges,
    OnDestroy
} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BaseService, LanguageService, TreeService } from '@shared/services';
import { MatSort } from '@angular/material/sort';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { ResourcesService } from '@shared/services/resources.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-search-rall',
    templateUrl: './search-rall.component.html',
    styleUrls: ['./search-rall.component.scss']
})
export class SearchRAllComponent implements OnInit, OnChanges, OnDestroy {
    loading = true;
    constructor(
        public router: Router,
        private _resourceService: ResourcesService,
        private langservice: LanguageService,
        private _baseService: BaseService
    ) {
        if (this.IsRole == true) {
            this.currSelectedRess = [];
            this.selection = new SelectionModel(true);
            this.selectedResource = new EventEmitter<any>();
            this.selectedResources = new EventEmitter<any>();

            this.displayedColumns = ['select', 'resourceName', 'locationName', 'categoryName', 'accesstype'];
        }

        if (this.isForReports) {
            this.currSelectedRess = [];
            this.selection = new SelectionModel(true);
            this.selectedResource = new EventEmitter<any>();
            this.selectedResources = new EventEmitter<any>();
            this.displayedColumns = ['select', 'resourceName', 'locationName', 'categoryName'];
        }
    }

    private _unsubscribeAll$ = new Subject<boolean>();

    activeStyleUserTable: any;
    accessType = [
        { value: '2', accessType: 'Add' },
        { value: '3', accessType: 'Edit' },
        { value: '5', accessType: 'Delete' }
    ];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    fruits: any[] = [];
    accessTypeValue: any;
    applyAccessType(element, event) {
        if (event.length == 1) element.accessType = event[0];
        if (event.length == 3) element.accessType = '8';

        if (event.length == 2) {
            if (event.find((e) => e == '2') && event.find((e) => e == '3')) {
                element.accessType = '4';
            }
            if (event.find((e) => e == '2') && event.find((e) => e == '5')) {
                element.accessType = '6';
            }
            if (event.find((e) => e == '3') && event.find((e) => e == '5')) {
                element.accessType = '7';
            }
        }
    }
    @Input('canEdit')
    canEdit = true;
    @Input('IsRole')
    IsRole = false;

    @Input('isForReports')
    isForReports = false;

    @Input('RoleResourceList')
    RoleResourceList = [];

    @Input('multiselect')
    multiselect = true;

    @Input('RemoveFav')
    RemoveFav = false;

    @Input('rememberSelection')
    rememberSelection = false;

    @Input('isForVsRes')
    isForVsRes = false;

    @Input('currSelectedRess')
    currSelectedRess = [];

    @Output('selectedResource')
    selectedResource: EventEmitter<any> = new EventEmitter<any>();

    @Output('selectedResources')
    selectedResources: EventEmitter<any> = new EventEmitter<any>();
    @Output('IsDbClickAction')
    IsDbClickAction: EventEmitter<any> = new EventEmitter<any>();
    @Input('FixedTimeList')
    FixedTimeList = null;
    @Input('IsFixedTime')
    IsFixedTime = false;

    @Input('timeSlotsType')
    timeSlotsType = null;

    @Input('ExcludeFixedBookingResources')
    ExcludeFixedBookingResources = false;
    @Input('ExcludeInventoryAndCapcityResource')
    ExcludeInventoryAndCapcityResource = false;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    displayedColumns: string[] = [
        'select',
        'addToFav',
        'resourceName',
        'locationName',
        'categoryName',
        'timeSlotType',
        'multibookingflag'
    ];
    tableData = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);
    resourceLst = [];
    icon = 'star_border';
    setStyle(addTofav) {
        return { color: 'black' };
    }
    AddToFav(row) {
        var favRes = { resourceId: row.resourceId };
        var fav = [];
        if (row.addToFav != true) {
            this._resourceService
                .AddToFav(favRes)
                .pipe(takeUntil(this._unsubscribeAll$), first())
                .subscribe((result) => {
                    if (result.data != null) {
                        row.addToFav = true;
                        this.getFavList();
                    }
                });
        } else {
            fav.push(favRes);
            this._resourceService
                .RemoveFromFav(fav)
                .pipe(takeUntil(this._unsubscribeAll$), first())
                .subscribe((result) => {
                    if (result) {
                        row.addToFav = false;
                        this.getFavList();
                    }
                });
        }
    }
    getFavList() {
        this._resourceService
            .getFavList()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                if (result.data != null) {
                    if (result.data.length != 0)
                        localStorage.setItem('lastSelectedResources', JSON.stringify(result.data));
                }
            });
    }

    ngOnInit() {
        if (this.IsRole == true) {
            this.displayedColumns = ['select', 'resourceName', 'locationName', 'categoryName', 'accesstype'];
        }
        if (this.isForReports) {
            this.displayedColumns = ['select', 'resourceName', 'locationName', 'categoryName'];
        }

        if (this.isForVsRes) {
            this.displayedColumns = ['select', 'resourceName', 'resourceCode'];
            this.getVisualResourcelistOnly();
        } else this.getresourcelist();

        this.selection.changed.next = (val) => {
            if (this.IsRole == true) {
                this.selection.selected.forEach((element) => {
                    var check = this.RoleResourceList.find((e) => e.resourceid == element.resourceId);
                    if (check) {
                        if (check.accessType == '8') element.accessTypeList = ['2', '3', '5'];
                        else if (check.accessType == '7') {
                            element.accessTypeList = ['3', '5'];
                        } else if (check.accessType == '6') {
                            element.accessTypeList = ['2', '5'];
                        } else if (check.accessType == '4') {
                            element.accessTypeList = ['2', '3'];
                        } else {
                            if (check.accessType != '1') element.accessTypeList = [check.accessType];
                        }
                    } else {
                        if (element.accessType == null) {
                            element.accessType = '1';
                        }
                    }
                });
            }
            this.selectedResources.emit(val);
        };
    }

    getAccessTypes() {
        this.langservice
            .GetLookupbyKey('ResourceAccessType')
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.accessType = result.data;
            });
    }

    addfilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            this.fruits.push({ name: value.trim() });
            this.filterdatasource(this.fruits);
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    isAllSelected() {
        let numSelected = 0;
        if (this.selection.selected) {
            numSelected = this.selection.selected.length;
        }
        const numRows = this.tableData.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.tableData.data.forEach((row) => this.selection.select(row));
        //console.log( this.selection.selected);
        // this.selectedResource.emit(this.selection.selected);
    }

    checkboxLabel(row): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }
    getselectedResources() {
        this.selectedResource.emit(this.selection.selected);
    }
    @Input('EnableDoubleClickSelection')
    EnableDoubleClickSelection: false;
    selectresource(row) {
        this.selectedResource.emit(row);
        this.activeStyleUserTable = row.resourceId;
        // this.selection = row;
    }
    dbselectresource(row) {
        this.selectedResource.emit(row);
        this.activeStyleUserTable = row.resourceId;
        this.IsDbClickAction.emit(true);

        // this.selection = row;
    }
    accessTypeE: any;

    IsRoleResource(tableElment) {
        // this.selection = new SelectionModel(true, []);

        if (this.IsRole && this.RoleResourceList.length > 0) {
            this.RoleResourceList.forEach((r) => {
                this.tableData.data.forEach((row) => {
                    if (r.resourceid == row.resourceId) {
                        this.selection.select(row);
                    }
                });
            });
            var check = this.RoleResourceList.find((e) => e.resourceid == tableElment.resourceId);

            if (check) {
                return true;
            }

            return false;
        }
        return false;
    }
    getresourcelist() {
        this._resourceService
            .getresourcesbylocationandcategory(
                this.timeSlotsType,
                this.ExcludeFixedBookingResources,
                this.ExcludeInventoryAndCapcityResource
            )
            .pipe(
                takeUntil(this._unsubscribeAll$),
                first(),
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe((result) => {
                this.tableData.data = result.data;
                this.tableData.sort = this.sort;

                this.resourceLst = result.data;

                if (
                    this.rememberSelection &&
                    this.tableData.data &&
                    this.tableData.data.length > 0 &&
                    this.currSelectedRess &&
                    this.currSelectedRess.length > 0
                ) {
                    this.currSelectedRess.forEach((r) => {
                        this.tableData.data.forEach((row) => {
                            if (r.resourceId == row.resourceId) this.selection.select(row);
                        });
                    });
                }

                // var lastSelectRes = localStorage.getItem('lastSelectedResources');
                // if (lastSelectRes)
                //   var rememberdResourcesIdsList = JSON.parse(lastSelectRes);

                // if (this.rememberSelection && this.tableData.data && this.tableData.data.length > 0 && rememberdResourcesIdsList) {

                //             rememberdResourcesIdsList.forEach(r => {

                //                         this.tableData.data.forEach(row => {
                //                           if (r.resourceId == row.resourceId)
                //                             this.selection.select(row);
                //                         });

                //           });
                // }
                this.loading = false;
            });
    }

    arrayDiffLists(a, b, id) {
        const bIds = {};
        b.forEach(function (obj) {
            bIds[obj[id]] = obj;
        });
        // Return all elements in A, unless in B
        return a.filter(function (obj) {
            return !(obj[id] in bIds);
        });
    }

    filterdatasource(array) {
        this.tableData = new MatTableDataSource(this.resourceLst);
        this.tableData.sort = this.sort;

        if (array.length > 0) {
            let filteredLst = [];
            const resetsearch = '';
            array.forEach((element) => {
                this.tableData.filter = element.name.trim().toLowerCase();
                const newlst = this.arrayDiffLists(this.tableData.filteredData, filteredLst, 'resourceId');
                filteredLst = filteredLst.concat(newlst);
                this.tableData.filter = resetsearch.trim().toLowerCase();
            });
            this.tableData = new MatTableDataSource(filteredLst);
            this.tableData.sort = this.sort;
        }
    }
    remove(fruit): void {
        const index = this.fruits.indexOf(fruit);

        if (index >= 0) {
            this.fruits.splice(index, 1);
            this.filterdatasource(this.fruits);
        }
    }
    transformer(node: any, level: number) {
        return {
            expandable: !!node.childs && node.childs.length > 0,
            name: node.resourceCategoryName,
            id: node.resourceCategoryId,
            level: level
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.isForReports) {
            if (changes['currSelectedRess']) {
                if (changes.currSelectedRess.currentValue.length < 1) {
                    this.selection.clear();
                }
            }
        } else {
            if (!this.multiselect) {
                this.displayedColumns.splice(0, 1);
                if (this.RemoveFav) {
                    this.displayedColumns.splice(0, 1);
                }
            }
        }
    }

    reloadafterdeletion() {
        this.selection = new SelectionModel(true, []);
        this.getresourcelist();
    }
    removeAll() {
        this.fruits = [];
        this.tableData = new MatTableDataSource(this.resourceLst);
        this.tableData.sort = this.sort;
    }

    editResource(row) {
        if (this.canEdit) this.router.navigate(['resource', row.resourceId]);
    }
    CheckExist(Id) {
        if (!this.FixedTimeList) {
            return false;
        }
        const findBooking = (element) => element.resourceId == Id;
        const Res = this.FixedTimeList.findIndex(findBooking);
        if (Res > -1) {
            if (this.FixedTimeList[Res].hasOwnProperty('isClosed')) {
                if (this.FixedTimeList[Res].isClosed === true) return false;
                else return true;
            }
            return true;
        }
        return false;
    }

    // loadGroups() {
    //   this._baseService
    //     .getResourcGroups()
    //     .pipe(takeUntil(this._unsubscribeAll$), first())
    //     .subscribe((result) => {
    //       this.Groups = result.data;
    //     });
    // }

    getVisualResourcelistOnly() {
        this._baseService
            .getResourcGroups()
            .pipe(
                takeUntil(this._unsubscribeAll$),
                first(),
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe((result) => {
                this.tableData.data = result.data;
                this.tableData.sort = this.sort;

                this.resourceLst = result.data;

                if (
                    this.rememberSelection &&
                    this.tableData.data &&
                    this.tableData.data.length > 0 &&
                    this.currSelectedRess &&
                    this.currSelectedRess.length > 0
                ) {
                    this.currSelectedRess.forEach((r) => {
                        this.tableData.data.forEach((row) => {
                            if (r.resourceId == row.resourceId) this.selection.select(row);
                        });
                    });
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
    }
}
