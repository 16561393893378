import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Result } from '../_models';
import { environment } from '../../../environments/environment';

@Injectable()
export class ErrorService {
    private canonicalName: string;
    private messageSource = new BehaviorSubject({});
    private messages = [];
    private ChangeLangFunction = new BehaviorSubject('');
    ChangeLang = this.ChangeLangFunction.asObservable();

    currentMessage = this.messageSource.asObservable();

    private actionFn = new BehaviorSubject('');

    currentAction = this.actionFn.asObservable();

    private colleanesFn = new BehaviorSubject('');

    colleanesAction = this.colleanesFn.asObservable();

    APIURL = '';
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;

    constructor(private http: HttpClient) {
        const newurl = window.location.href;

        if (this.isOnPremise) this.APIURL = this.appUrl;
        else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
        }

        if (this.APIURL.search('localhost') > -1) {
            // this.APIURL = 'https://main.api.sharewinds.com/api/v1/';
            this.APIURL = environment.localHostUrl;
        }
    }

    getDBMassage(id: string) {
        return this.http.get<Result>(`${this.APIURL}Message/get-db-message/` + id);
    }

    getAPMassage(id: string): Observable<Result> {
        if (this.messages.length > 0 && this.messages.findIndex((message) => message.id == id) > -1) {
            return of(this.messages).pipe(
                map((data) => {
                    return {
                        isSuccess: true,
                        errorCode: null,
                        message: null,
                        messageType: null,
                        lookupData: null,
                        data: data.find((message) => +message.id == +id)
                    } as Result;
                })
            );
        } else {
            return this.http.get<Result>(`${this.APIURL}Message/` + id);
        }
    }

    getAllMassages(): Observable<any> {
        return this.http.get<any>(`${this.APIURL}Message/All`).pipe(
            tap((result) => {
                this.messages = result.data;
            })
        );
    }
    changeMessage(mssgData) {
        //console.log(mssgData);
        this.messageSource.next(mssgData);
    }

    action(actionFn) {
        this.actionFn.next(actionFn);
    }
    collabesFn(colleanesFn) {
        this.colleanesFn.next(colleanesFn);
    }

    TranslateLang() {
        this.ChangeLangFunction.next('');
    }

    ActivateContact(contact: any) {
        return this.http.post<Result>(`${this.APIURL}security/suser/edit-Contact-by-email`, contact);
    }
}
