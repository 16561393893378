import { Component, OnInit, OnDestroy } from '@angular/core';
import swal from 'sweetalert2';
import { BaseService, ErrorService } from '../../services';
import { first, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-mssg',
    templateUrl: './mssg.component.html',
    styleUrls: ['./mssg.component.scss']
})
export class MssgComponent implements OnInit, OnDestroy {
    private _unsubscribeAll$ = new Subject<boolean>();

    action;
    cancelAction;
    dangerAlert = false;
    alerts: any[] = [
        {
            type: '',
            msg: ``,
            timeout: 1
        }
    ];
    // subscription: Subscription = new Subscription();

    addAlert(typeData, msgData): void {
        this.dangerAlert = false;
        this.alerts = [];

        let time = 3000;
        if (typeData === 'danger') {
            this.dangerAlert = true;
            time = 99999999999;
        }
        if (this.alerts.length === 0) {
            this.alerts.push({
                type: typeData,
                msg: msgData,
                timeout: time
            });
        } else {
            this.alerts.splice(0, 1);
            this.alerts.push({
                type: typeData,
                msg: msgData,
                timeout: time
            });
        }
    }
    errorMsg(data) {
        swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: data.messageTitle,
            text: data.messageText,
            type: data.icon.toLowerCase(),
            showCancelButton: data.buttonTwoTitle ? true : false,
            confirmButtonColor: '#FF8989',
            cancelButtonColor: '#BCBCCB',
            // iconColor:  '#FF8989',
            cancelButtonText: data.buttonTwoTitle,
            confirmButtonText: data.buttonOneTitle
        }).then((result) => {
            if (result.value) {
                if (data.buttonOneAction) {
                    this._errorService.action(this.action);
                }
            } else {
                if (this.cancelAction) this._errorService.action(this.cancelAction);
                if (data.buttonTwoAction) {
                    this._errorService.action(this.action);
                }
            }
        });
    }
    onClosedAlert(dismissedAlert): void {
        this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
        this.alerts = [];
        this.dangerAlert = false;
    }
    errorMsgLocation(result) {
        this.alerts = [];
        this._errorService.changeMessage({ id: null, action: null });
        if (result.data.location === 'Pop Up') {
            this.errorMsg(result.data);
        } else if (result.data.location === 'Top green') {
            this.addAlert('success', result.data.messageText);
        } else if (result.data.location === 'Top red') {
            this.addAlert('danger', result.data.messageText);
        } else {
            this.addAlert('danger', 'Failed to Save Changes');
        }
    }
    constructor(private _BaseService: BaseService, private _errorService: ErrorService) {}

    ngOnInit() {
        this.alerts = [];

        this._errorService.currentMessage
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((message) => this.getMssg(message));
    }

    getMssg(messageData) {
        if (!messageData.id) {
            return;
        }
        this.action = messageData.action;
        this.cancelAction = messageData?.cancelAction;
        this.alerts = [];
        this._errorService
            .getAPMassage(messageData.id)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => this.errorMsgLocation(result));
    }
    ngOnDestroy() {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
        // this.subscription.unsubscribe();
    }
}
