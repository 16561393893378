import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Result } from '../_models';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private canonicalName: string;

    APIURL = '';
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;
    constructor(private translate: TranslateService, private http: HttpClient) {
        const newurl = window.location.href;

        if (this.isOnPremise) this.APIURL = this.appUrl;
        else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
        }

        if (this.APIURL.search('localhost') > -1) {
            // this.APIURL = 'https://main.api.sharewinds.com/api/v1/';
            this.APIURL = environment.localHostUrl;
        }
        // this.APIURL = 'http://localhost:56622/api/v1/';
    }

    private storageSub = new Subject<string>();

    watchStorage(): Observable<any> {
        return this.storageSub.asObservable();
    }
    GetAllCultures() {
        return this.http.get<Result>(`${this.APIURL}Localization/GetAllCultures`);
    }
    GetAllActiveCultures() {
        return this.http.get<Result>(`${this.APIURL}Localization/GetAllActiveCultures`);
    }
    GetCultureByid(id) {
        return this.http.get<Result>(`${this.APIURL}Localization/get-Culture/` + id);
    }
    AddCulture(Culture) {
        return this.http.post<Result>(`${this.APIURL}Localization/add-Culture`, Culture);
    }
    AddCultureHolidays(CultureId, Holidays) {
        return this.http.post<Result>(`${this.APIURL}WorkShop/add/` + CultureId, Holidays);
    }
    getCultureHolidays(CultureId) {
        return this.http.get<Result>(`${this.APIURL}WorkShop/get/` + CultureId);
    }
    AddHoliday(holiday) {
        return this.http.post<Result>(`${this.APIURL}WorkShop/AddHoliday`, holiday);
    }
    getAllHolidays() {
        return this.http.get<Result>(`${this.APIURL}WorkShop/getAllHolidays`);
    }
    editHoliday(holiday) {
        return this.http.post<Result>(`${this.APIURL}WorkShop/editHoliday`, holiday);
    }
    DeleteCultureHolidays(holiday) {
        return this.http.post<Result>(`${this.APIURL}WorkShop/DeleteCultHolidays`, holiday);
    }
    DeleteHolidays(holidays) {
        return this.http.post<Result>(`${this.APIURL}WorkShop/DeleteHolidays`, holidays);
    }
    editCulture(Culture) {
        return this.http.post<Result>(`${this.APIURL}Localization/edit-Culture`, Culture);
    }
    ChangeStatus(Culture, value) {
        return this.http.post<Result>(`${this.APIURL}Localization/changestatus-Culture/` + value, Culture);
    }
    GetAllLang() {
        return this.http.get<Result>(`${this.APIURL}Localization/get_lang`);
    }
    GetAllCurrency() {
        return this.http.get<Result>(`${this.APIURL}Localization/GetCurrencies`);
    }
    GetLookupbyKey(key: any) {
        return this.http.get<Result>(`${this.APIURL}LookUp/Get_Status_by_lookUpKey/` + key);
    }

    getCurrLangAbrrv() {
        var currLang = localStorage.getItem('langCode');

        return currLang;
    }

    changeLangUsingCultureName(Culture) {
        let language = Culture.langCode;
        // localStorage.setItem('cultureName',Culture.cultureName);

        let CultureId = Culture.cultureId;
        let dateFormat = Culture.dateFormat;
        if (language != null) {
            language = language.toLowerCase();
            this.storageSub.next(language);
            localStorage.setItem('langCode', language.toLowerCase());
        }

        localStorage.setItem('userLanguage', CultureId);

        localStorage.setItem('dateFormate', dateFormat);
        localStorage.setItem('Dir', Culture.direction);
        this.changeLang(language.toLowerCase(), Culture.direction);
    }

    changeLang(language: string, direction: string) {
        const dom: any = document.querySelector('body');

        this.translate.use(language);
        if (direction === 'R') {
            dom.classList.add('rtl');
        } else {
            dom.classList.remove('rtl');
        }
        //window.location.reload();
    }
    changeUserLang(newLang) {
        // this.changeLang(newLang);
        let id;

        return this.http.get<Result>(`${this.APIURL}Security/SUser/change-culture/` + newLang.cultureId);
    }

    changeDirForGroupOfElementByClassName(className) {
        var elements = document.querySelectorAll('.' + className);
        var dir = localStorage.getItem('Dir');

        elements.forEach((ele) => {
            if (dir && dir == 'R') {
                ele.setAttribute('dir', 'rtl');
            } else {
                ele.setAttribute('dir', 'ltr');
            }
        });
    }
    getCurrentUserLang() {
        var uLang = localStorage.getItem('userLanguage');
        return uLang;
    }

    getCultTransObject(CultId = null) {
        if (CultId == null)
            CultId = localStorage.getItem('userLanguage');
        return this.http.get<Result>(`${this.APIURL}Localization/GetCultTransObject/` + CultId);
        //return this.http.get<Result>(`http://localhost:8988/api/v1/Localization/GetCultTransObject/` + CultId);
    }

    savaCultTransEditd(editedTransObj, CultId = null) {
        if (CultId == null)
            CultId = localStorage.getItem('userLanguage');

        return this.http.post<Result>(`${this.APIURL}Localization/SavaCultTransEditd/` + CultId, editedTransObj);
        //return this.http.post<Result>(`http://localhost:8988/api/v1/Localization/SavaCultTransEditd/` + CultId, editedTransObj);
    }

    resetCultTransToDefault(CultId = null) {
        if (CultId == null)
            CultId = localStorage.getItem('userLanguage');
        return this.http.post<Result>(`${this.APIURL}Localization/ResetCultTransToDefault/` + CultId, {});
    }



}
