import {
    Component,
    OnInit,
    ViewChild,
    TemplateRef,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseService, ErrorService, LanguageService } from '@shared/services';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { first, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ServicesService } from '@shared/services/Service.service';

@Component({
    selector: 'app-search-r-groups',
    templateUrl: './search-r-groups.component.html',
    styleUrls: ['./search-r-groups.component.scss']
})
export class SearchRGroupsComponent implements OnInit, OnChanges, OnDestroy {
    private _unsubscribeAll$ = new Subject<boolean>();

    constructor(
        private translate: TranslateService,
        public router: Router,
        public route: ActivatedRoute,
        private _service: ServicesService,
        private _errorService: ErrorService,
        private _langService: LanguageService,
        private baseService: BaseService
    ) {}

    searchValue = '';
    changeNewContant = false;
    laddaSearchProgress: boolean | number = false;

    @ViewChild(MatSort, { static: true }) sort: MatSort;

    displayedColumns: string[] = ['select', 'resourceName'];
    dataSource = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);

    allResourcesShownResourecsBasedOnGroupeSelection = [];
    resourcesTblDataSource = new MatTableDataSource([]);
    resourcesSelection = new SelectionModel(true, []);
    resourcesTblDisplayedColumns: string[] = ['select', 'resourceName'];

    ImgLabel: 'Photo';
    isBusy = false;

    @Input('openMode')
    openMode = 'reports'; // or app

    @Output('selectedResources')
    selectedResources: EventEmitter<any> = new EventEmitter<any>();

    @Output('selectedGroups')
    selectedGroups: EventEmitter<any> = new EventEmitter<any>();

    @Input('currSelectedRess')
    currSelectedRess = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['currSelectedRess']) {
            if (changes.currSelectedRess.currentValue.length < 1) {
                this.resourcesSelection.clear();
                this.selection.clear();
            }
        }
    }

    GetAllResourceGroupsWithChilds() {
        this.searchValue = '';
        this.isBusy = true;
        this.baseService
            .getResourcGroups()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                // Map To change props Names
                var namedList = [];
                result.data.forEach((groupEntry) => {
                    var namedListOfChilds = [];
                    groupEntry.childResources.forEach((childResEntry) => {
                        namedListOfChilds.push({
                            resourceId: childResEntry.resourceid,
                            resourceName: childResEntry.resourcename
                        });
                    });
                    groupEntry.childResources = namedListOfChilds;
                    namedList.push(groupEntry);
                });

                this.dataSource = new MatTableDataSource(namedList);
                if (namedList && namedList.length > 0 && this.currSelectedRess && this.currSelectedRess.length > 0) {
                    this.currSelectedRess.forEach((r) => {
                        namedList.forEach((GroupEntry) => {
                            GroupEntry.childResources.forEach((childEntry) => {
                                if (r.resourceId == childEntry.resourceId) {
                                    this.selection.select(GroupEntry);
                                    this.resourcesSelection.select(childEntry);
                                }
                            });
                        });
                    });
                }

                this.dataSource.sort = this.sort;
                this.isBusy = false;
            });
    }
    firstInit = true;

    ngOnInit() {
        this.selection.changed.next = (selectedGroup) => {
            this.selectedGroups.emit(selectedGroup);
            var relatedResources = [];
            if (selectedGroup.added.length > 0) {
                selectedGroup.added.forEach((element) => {
                    relatedResources = relatedResources.concat(element.childResources);
                });

                relatedResources.forEach((enrtyAdded) => {
                    this.allResourcesShownResourecsBasedOnGroupeSelection.push(enrtyAdded);
                    this.resourcesTblDataSource = new MatTableDataSource(
                        this.allResourcesShownResourecsBasedOnGroupeSelection
                    );
                    if (!this.firstInit) this.resourcesSelection.select(enrtyAdded);
                    else {
                        this.currSelectedRess.forEach((r) => {
                            if (enrtyAdded.resourceId === r.resourceId) this.resourcesSelection.select(enrtyAdded);
                        });
                        this.firstInit = false;
                    }
                });
            } else if (selectedGroup.removed.length > 0) {
                selectedGroup.removed.forEach((element) => {
                    relatedResources = relatedResources.concat(element.childResources);
                });

                relatedResources.forEach((enrtyRemoved) => {
                    this.allResourcesShownResourecsBasedOnGroupeSelection.splice(
                        this.allResourcesShownResourecsBasedOnGroupeSelection.indexOf(enrtyRemoved),
                        1
                    );
                    this.resourcesTblDataSource = new MatTableDataSource(
                        this.allResourcesShownResourecsBasedOnGroupeSelection
                    );
                    this.resourcesSelection.deselect(enrtyRemoved);
                });
            }
        };

        this.resourcesSelection.changed.next = (selectedResource) => {
            this.selectedResources.emit(selectedResource);
        };

        this.GetAllResourceGroupsWithChilds();
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    checkboxLabel(row): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    isAllResourcesSelected() {
        const numSelected = this.resourcesSelection.selected.length;
        const numRows = this.resourcesTblDataSource.data.length;
        return numSelected === numRows;
    }

    masterToggleForResourceTbl() {
        this.isAllResourcesSelected()
            ? this.resourcesSelection.clear()
            : this.resourcesTblDataSource.data.forEach((row) => this.resourcesSelection.select(row));
    }

    checkboxLabelForResourceTbl(row): string {
        if (!row) {
            return `${this.isAllResourcesSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.resourcesSelection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }

    setCheckboxValue(event, atrr) {}

    ngOnDestroy() {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
    }
}
