import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { JwtInterceptor } from '@shared/guard';
import { LocationStrategy, HashLocationStrategy, DatePipe, registerLocaleData } from '@angular/common';
import { HttpCancelService } from './shared/services/http-cancel.service';
import {
    SecurityService,
    ErrorService,
    BaseService,
    TreeService,
    AuthenticationService,
    UsersService,
    LanguageService
} from '@shared/index';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FormBuilder, FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { EmailTemplateService } from '@shared/services/EmailTemplateService';
import { onClientMessagesService } from '@shared/services/onClientMessagesService.service';
import { HubService } from './hub.service';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import en from '@angular/common/locales/en';
import { ClickModifiersPluginService } from './click-modifiers-plugin.service';

registerLocaleData(en);
// import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

// import { ManageHttpInterceptor } from './shared/helpers/managehttp.interceptor';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgIdleKeepaliveModule.forRoot(),
        LanguageTranslationModule,

        AppRoutingModule,
        SharedModule,
        FormsModule
    ],
    declarations: [AppComponent],
    providers: [
        AuthGuard,
        SecurityService,
        LanguageService,
        BaseService,
        TreeService,
        ErrorService,
        HttpCancelService,
        AuthenticationService,
        UsersService,
        EmailTemplateService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        onClientMessagesService,
        HubService,
        { provide: NZ_I18N, useValue: en_US },
        {
            provide: EVENT_MANAGER_PLUGINS,
            useClass: ClickModifiersPluginService,
            multi: true
        }
        // { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true } can't navigate with this fe:resource edit page
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
