import { TableProperties, ColumnType } from './table-properties';
import { ComboData } from ".";

export class FilterType{
    columnType: ColumnType;
    min?: any;
    max?: any;
    minFrom?:any;
    minTo?:any;
    maxFrom?:any;
    maxTo?:any;    
}

export class Filter {
    prop?: string;
    label?: string;
    placeholder?: string;
    isEmpty?: boolean;
    value?: any;
    listDefault?: string;
    list?: ComboData[];
    listMap?: {name?: string, value?: any, selected?: any, cascadeProp?: any};
    isDisabled?: boolean;
    type?: FilterType;
}