export const DateFormats={
     parse: {
          dateInput: 'MM/DD/YYYY',
        },
        display: {
          dateInput: 'MM/DD/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'MMM/YYYY',
          monthYearA11yLabel: 'MMM/YYYY',
        },
      };


      export class SetFormate{
        DateFormats={
          parse: {
               dateInput: 'MMM/DD/YYYY',
             },
             display: {
               dateInput: 'MM/DD/YYYY',
               monthYearLabel: 'MMM YYYY',
               dateA11yLabel: 'MMM/YYYY',
               monthYearA11yLabel: 'MMM/YYYY',
             },
           };
    
        changeFormate(){
          // var dd='DD/MMM/YYYY'
         if (localStorage.getItem('dateFormate')!=null ) 
         { this.DateFormats.display.dateInput=localStorage.getItem('dateFormate');
          this.DateFormats.parse.dateInput=localStorage.getItem('dateFormate');
        }
        const  DateFormats=this.DateFormats;
          return  DateFormats;

         

        }
      }