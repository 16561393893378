import { FieldType } from './form-item';
import { Filter } from './filter';
import { Validators } from '@angular/forms';

export enum ColumnType {
    text,
    checkbox,
    radio,
    datepicker,
    datepickerRange,
    timepicker,
    number,
    combobox,
    percent,
    rangeSlider,
    popupButtonType,
    comboboxWithSearch
}

export class ColumnTableProperty {
    isSection?: boolean;
    isFullWidth?: boolean;
    isTable: boolean;
    isAdd?: boolean;
    isEdit: boolean;
    editReadOnly?: boolean;
    name: string;
    prop: string;
    type?: FieldType;
    readOnly?: boolean;
    foreignList?: any[];
    foreignMap?: { name?: string, value?: any, selected?: any, cascadeProp?: any };
    validator?: Validators[];
    needValidation?: boolean;
    isValid?: boolean;
    validationMessage?: string[];
    minDate?: string;



}
export class TableProperties {
    primaryProperty: string[];
    addButtonName?: string;
    editButtonName?: string;
    deleteButtonName?: string;
    deleteMessage?: string;
    filters?: Filter[];
    columns?: ColumnTableProperty[];


}