import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { calendar } from 'ngx-bootstrap/chronos/moment/calendar';
import { Result } from '../_models';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ServicesService {
    private canonicalName: string;
    private isBusy: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public isBusy$ = this.isBusy.asObservable();
    public changeIsBusy(value: boolean) {
        this.isBusy.next(value);
    }
    APIURL = '';
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;
    constructor(private http: HttpClient) {
        const newurl = window.location.href;

        if (this.isOnPremise) this.APIURL = this.appUrl;
        else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
        }

        if (this.APIURL.search('localhost') > -1) {
            this.APIURL = environment.localHostUrl;
        }

         //this.APIURL = 'http://localhost:8988/api/v1/';

    }

    Add(data) {
        return this.http.post<Result>(`${this.APIURL}Services/add`, data);
    }
    Edit(data) {
        return this.http.post<Result>(`${this.APIURL}Services/edit`, data);
    }
    DeleteList(data) {
        return this.http.put<Result>(`${this.APIURL}Services/delete-by-transaction`, data);
    }

    GetAllActive() {
        return this.http.get<Result>(`${this.APIURL}Services/getActive`);
    }

    GetServiceById(id) {
        return this.http.get<Result>(`${this.APIURL}Services/GetServiceById/` + id);
    }

    getServcieResources(id) {
        return this.http.get<Result>(`${this.APIURL}Services/getServcieResources/` + id);
    }


    AssignResoureceToService(id,data) {
        return this.http.post<Result>(`${this.APIURL}Services/AssignResoureceToService/` + id , data);
    }

    UnAssignResoureceFromService(id, data) {
        return this.http.post<Result>(`${this.APIURL}Services/UnAssignResoureceFromService/` + id, data);
    }
}
