export const environment = {
    production: false,
    isOnPremise: false,
    //isOnPremise: true,
    //appUrl: 'https://devenv.api.sharewinds.com/api/v1/',
    //appUrl: 'http://localhost:8988/api/v1/',
    appUrl: 'https://devenv.api.sharewinds.com/api/v1/',

    // appUrl: 'http://192.168.2.28:711/api/v1/',
    //appUrl: 'https://devenv.api.sharewinds.com/api/v1/',
    localHostUrl: 'https://devenv.api.sharewinds.com/api/v1/',
    //localHostUrl: 'http://localhost:8988/api/v1/',
    reportUrl: '',
    authUrl: 'https://authdev.sharewinds.com',
    sManagerUrl: 'https://smanagerdev.sharewinds.com',
    environmentName: 'dev',
    timeoutMinutes: 200
};
