import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    private canonicalName: string;
    private messageSource = new BehaviorSubject({});
    currentMessage = this.messageSource.asObservable();

    private actionFn = new BehaviorSubject('');
    currentAction = this.actionFn.asObservable();
    APIURL = '';
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;
    constructor(private http: HttpClient) {
        const newurl = window.location.href;

        if (this.isOnPremise) this.APIURL = this.appUrl;
        else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
        }

        if (this.APIURL.search('localhost') > -1) {
            // this.APIURL = 'https://main.api.sharewinds.com/api/v1/';
            this.APIURL = environment.localHostUrl;
        }

        // if (localStorage.getItem('canonicalName') !== null) {
        //     this.canonicalName = localStorage.getItem('canonicalName') + '/';
        // } else {
        //     this.canonicalName = '';
        // }
    }
    errorMsg = '';

    getDBMassage(id: string) {
        // return this.http.get<any>(`${environment.appUrl}${this.canonicalName}Message/get-db-message/` + id);
        return this.http.get<any>(`${this.APIURL}Message/get-db-message/` + id);
    }

    getAPMassage(id: string) {
        //return this.http.get<any>(`${environment.appUrl}${this.canonicalName}Message/` + id);
        return this.http.get<any>(`${this.APIURL}Message/` + id);
    }
    getAllMassage() {
        // return this.http.get<any>(`${environment.appUrl}${this.canonicalName}Message/All`);
        return this.http.get<any>(`${this.APIURL}Message/All`);
    }
    changeMessage(mssgData) {
        this.messageSource.next(mssgData);
    }

    action(actionFn) {
        this.actionFn.next(actionFn);
    }
}
