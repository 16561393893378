import { ValidationTypeDto } from './';
import { ColumnDto } from './';

export class ValidationDto {
    validationId: number;
    validationTypeId: number;
    isValidated: boolean;
    columnId: number;
    column: ColumnDto[];
    validationType: ValidationTypeDto;
}