import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, ViewChild, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { LanguageService, TreeService } from '@shared/services';
import { MatSort } from '@angular/material/sort';
import { first, takeUntil } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { ResourcesService } from '@shared/services/resources.service';
import { Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of childs.
 */

/** Flat node with expandable and level information */
interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
}

/**
 * @title Tree with flat nodes
 */
@Component({
    selector: 'app-search-rlocation',
    templateUrl: './search-rlocation.component.html',
    styleUrls: ['./search-rlocation.component.scss']
})
export class SearchRLocationComponent implements OnDestroy {
    activeStyleUserTable: any;
    loading = true;
    resourceLst = [];
    constructor(
        public router: Router,
        private langservice: LanguageService,
        private _treeService: TreeService,
        private _resourceservice: ResourcesService
    ) {
        if (this.IsRole == true || this.isForReports == true) {
            this.currSelectedRess = [];
            this.selection = new SelectionModel(true);
            this.selectedResource = new EventEmitter<any>();
            this.selectedResources = new EventEmitter<any>();
        }
    }

    private _unsubscribeAll$ = new Subject<boolean>();

    activeStyleNode = '';
    TREE_DATA: any;

    @Input('IsRole')
    IsRole = false;

    @Input('isForReports')
    isForReports = false;

    @Input('EnableDoubleClickSelection')
    EnableDoubleClickSelection: false;
    @Input('FixedTimeList')
    FixedTimeList = null;
    @Input('IsFixedTime')
    IsFixedTime = false;

    @Input('timeSlotsType')
    timeSlotsType = null;
    @Input('ExcludeFixedBookingResources')
    ExcludeFixedBookingResources = false;
    @Input('ExcludeInventoryAndCapcityResource')
    ExcludeInventoryAndCapcityResource = false;

    treeControl = new FlatTreeControl<ExampleFlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );

    treeFlattener = new MatTreeFlattener(
        this.transformer,
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.childs
    );

    @Input('currSelectedRess')
    currSelectedRess = [];

    @Input('canEdit')
    canEdit = true;

    @Input('rememberSelection')
    rememberSelection = false;

    @Input('RoleResourceList')
    RoleResourceList = [];

    @Input('multiselect')
    multiselect = true;
    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    @Output('selectedResource')
    selectedResource: EventEmitter<any> = new EventEmitter<any>();
    @Output('IsDbClickAction')
    IsDbClickAction: EventEmitter<any> = new EventEmitter<any>();
    @Output('selectedResources')
    selectedResources: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    displayedColumns: string[] = [
        'select',
        'addToFav',
        'resourceName',
        'categoryName',
        'timeSlotType',
        'multibookingflag'
    ];
    tableData = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);
    selectedLoc = '';
    transformer(node: any, level: number) {
        return {
            expandable: !!node.childs && node.childs.length > 0,
            name: node.locationName,
            id: node.locationId,
            level: level
        };
    }
    dbselectresource(row) {
        this.selectedResource.emit(row);
        this.activeStyleUserTable = row.resourceId;
        this.IsDbClickAction.emit(true);

        // this.selection = row;
    }
    IsRoleResource(tableElment) {
        if (this.IsRole && this.RoleResourceList.length > 0) {
            this.RoleResourceList.forEach((r) => {
                this.tableData.data.forEach((row) => {
                    if (r.resourceid == row.resourceId) {
                        row.accessType = r.accessType;
                        this.selection.select(row);
                    }
                });
            });
            var check = this.RoleResourceList.find((e) => e.resourceid == tableElment.resourceId);

            if (check) {
                return true;
            }
            return false;
        }
        return false;
    }
    setStyle(addTofav) {
        return { color: 'black' };
    }
    AddToFav(row) {
        var favRes = { resourceId: row.resourceId };
        var fav = [];
        if (row.addToFav != true) {
            this._resourceservice
                .AddToFav(favRes)
                .pipe(takeUntil(this._unsubscribeAll$), first())
                .subscribe((result) => {
                    if (result.data != null) {
                        row.addToFav = true;
                        this.getFavList();
                    }
                });
        } else {
            fav.push(favRes);
            this._resourceservice
                .RemoveFromFav(fav)
                .pipe(takeUntil(this._unsubscribeAll$), first())
                .subscribe((result) => {
                    if (result) {
                        row.addToFav = false;
                        this.getFavList();
                    }
                });
        }
    }
    getFavList() {
        this._resourceservice
            .getFavList()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                if (result.data != null) {
                    if (result.data.length != 0)
                        localStorage.setItem('lastSelectedResources', JSON.stringify(result.data));
                }
            });
    }
    accessType = [
        { value: '2', accessType: 'Add' },
        { value: '3', accessType: 'Edit' },
        { value: '5', accessType: 'Delete' }
    ];

    ngOnInit() {
        if (this.IsRole) {
            this.displayedColumns = ['select', 'resourceName', 'categoryName', 'accesstype'];
        }

        if (this.isForReports) {
            this.displayedColumns = ['select', 'resourceName', 'categoryName'];
        }

        this.getTreeLocation();
        this.selection.changed.next = (val) => {
            if (this.IsRole == true) {
                this.selection.selected.forEach((element) => {
                    var check = this.RoleResourceList.find((e) => e.resourceid == element.resourceId);
                    if (check) {
                        if (check.accessType == '8') element.accessTypeList = ['2', '3', '5'];
                        else if (check.accessType == '7') {
                            element.accessTypeList = ['3', '5'];
                        } else if (check.accessType == '6') {
                            element.accessTypeList = ['2', '5'];
                        } else if (check.accessType == '4') {
                            element.accessTypeList = ['2', '3'];
                        } else {
                            if (check.accessType != '1') element.accessTypeList = [check.accessType];
                        }
                    } else {
                        if (element.accessType == null) {
                            element.accessType = '1';
                        }
                    }
                });
            }

            this.selectedResources.emit(val);
        };
    }

    applyAccessType(element, event) {
        if (event.length == 1) element.accessType = event[0];
        if (event.length == 3) element.accessType = '8';

        if (event.length == 2) {
            if (event.find((e) => e == '2') && event.find((e) => e == '3')) {
                element.accessType = '4';
            }
            if (event.find((e) => e == '2') && event.find((e) => e == '5')) {
                element.accessType = '6';
            }
            if (event.find((e) => e == '3') && event.find((e) => e == '5')) {
                element.accessType = '7';
            }
        }
    }
    getAccessTypes() {
        this.langservice
            .GetLookupbyKey('ResourceAccessType')
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.accessType = result.data;
            });
    }
    ngOnChanges(changes: SimpleChanges) {
        if (this.isForReports) {
            if (changes['currSelectedRess']) {
                if (changes.currSelectedRess.currentValue.length < 1) {
                    this.selection.clear();
                }
            }
        } else {
            if (!this.multiselect) {
                this.displayedColumns.splice(0, 1);
            }
        }
    }
    getTreeLocation() {
        this._treeService
            .getTreeLocation()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.dataSource.data = result.data;
                this.loading = false;
            });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.tableData.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.tableData.data.forEach((row) => this.selection.select(row));
        // this.selectedResource.emit(this.selection.selected);
    }
    checkboxLabel(row): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }
    getselectedResources() {
        this.selectedResource.emit(this.selection.selected);
    }
    reloadafterdeletion() {
        this.selection = new SelectionModel(true, []);
        this.getTreeLocation();
        if (this.selectedLoc !== '') {
            this.getresbyloc(this.selectedLoc);
        } else {
            this.tableData = new MatTableDataSource();
        }
    }
    selectresource(row) {
        this.activeStyleUserTable = row.resourceId;
        //this.selection = row;

        this.selectedResource.emit(row);
    }

    nodeClick(node) {
        this.activeStyleNode = node.id;
        this.selectedLoc = node.id;
        this.getresbyloc(node.id);
        //console.log(node);
    }

    editResource(row) {
        if (this.canEdit) this.router.navigate(['resource', row.resourceId]);
    }
    getresbyloc(id) {
        this._resourceservice
            .getresourcesbylocid(
                id,
                this.timeSlotsType,
                this.ExcludeFixedBookingResources,
                this.ExcludeInventoryAndCapcityResource
            )
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.resourceLst = result.data;
                this.tableData = new MatTableDataSource(result.data);

                if (
                    this.rememberSelection &&
                    this.tableData.data &&
                    this.tableData.data.length > 0 &&
                    this.currSelectedRess &&
                    this.currSelectedRess.length > 0
                ) {
                    this.currSelectedRess.forEach((r) => {
                        this.tableData.data.forEach((row) => {
                            if (r.resourceId == row.resourceId) this.selection.select(row);

                            if (this.isForReports) this.selection.select(row);
                        });
                    });
                }
            });
    }

    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
    CheckExist(Id) {
        if (!this.FixedTimeList) {
            return false;
        }
        const findBooking = (element) => element.resourceId == Id;
        const Res = this.FixedTimeList.findIndex(findBooking);
        if (Res > -1) {
            if (this.FixedTimeList[Res].hasOwnProperty('isClosed')) {
                if (this.FixedTimeList[Res].isClosed === true) return false;
                else return true;
            }
            return true;
        }
        return false;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
    }
}
