import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';
import * as signalR from '@microsoft/signalr';
import { EventEmitter } from 'events';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { async } from 'rxjs/internal/scheduler/async';
import { first, takeUntil } from 'rxjs/operators';
import { BookingService, SecurityService } from './shared';

@Injectable({
    providedIn: 'root'
})
export class HubService implements OnDestroy {
    private _unsubscribeAll$ = new Subject<boolean>();
    public hubConnection: signalR.HubConnection;
    hubRetryCount = 0;
    obsOfHubState = new EventEmitter();

    private _obs: BehaviorSubject<string> = new BehaviorSubject('Connecting');
    public readonly obs: Observable<string> = this._obs.asObservable();
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;

    constructor(private _bookingService: BookingService, private ngZone: NgZone, public router: Router, private secServ: SecurityService) {
        this.initServ();
    }

    initServ() {
        this.BuildAndStartHubConnection();
        this.setResourcesWorkingHoursOfflineList();

        var xInterval = setInterval(() => {
            if (this.hubConnection.state != signalR.HubConnectionState.Connected && this.hubRetryCount <= 12) {
                this.hubConnection.stop();
                this.BuildAndStartHubConnection();
                this.hubRetryCount++;
                console.log('Try starting the hub connection attemp number ' + this.hubRetryCount);
            } else {
                clearInterval(xInterval);
            }
            this._obs.next(this.hubConnection.state);
        }, 10000);
    }

    BuildAndStartHubConnection() {
        const newurl = window.location.href;
        let HubEndPoint = '';
        let clientUserIdTerm = '';

        let secObj = JSON.parse(localStorage.getItem('securityObject'));
        let userId = secObj?.userId;
        //console.log('UIDD  ' + userId);

        if (userId) clientUserIdTerm = '?userId=' + userId;
        else clientUserIdTerm = '';

        if (this.isOnPremise) {
            HubEndPoint = environment.appUrl + 'Pusher';
            HubEndPoint = HubEndPoint.replace('/api/v1/', '/');
        } else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            HubEndPoint = newAPI.split('#')[0] + 'Pusher';
        }

        if (HubEndPoint.search('localhost') > -1) {
            // HubEndPoint = 'http://localhost:56622/Pusher';
            //HubEndPoint = 'https://devenv.api.sharewinds.com/Pusher';
            HubEndPoint = environment.localHostUrl + 'Pusher';
            HubEndPoint = HubEndPoint.replace('/api/v1/', '/');
        }

        HubEndPoint = HubEndPoint + clientUserIdTerm;

        this.hubConnection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            // .withUrl('https://swwayfinding.sharewinds.com/pusher')
            .withUrl(HubEndPoint)
            .withAutomaticReconnect([2000, 7000, 7000, 7000, 20000, 30000, 50000])
            .build();

        this.hubConnection
            .start()
            .then(() => {
                this.onSuccsConnect();
            })
            .catch((err) => {
                return console.error(err.toString());
            });
    }

    onSuccsConnect() {
        console.log('SignalR Connected!');
        this.configurHubMethods();
        this._obs.next(this.hubConnection.state);

        this.hubConnection.onreconnecting((err) => {
            this._obs.next(this.hubConnection.state);
        });

        this.hubConnection.onreconnected((connId) => {
            this._obs.next(this.hubConnection.state);
        });

        this.hubConnection.onclose((err) => {
            this._obs.next(this.hubConnection.state);
        });
    }

    configurHubMethods() {
        if (this.hubConnection.state == signalR.HubConnectionState.Connected) {
            this.hubConnection.on('workingHoursChanged', () => {
                this.setResourcesWorkingHoursOfflineList();
            });
            this.hubConnection.on('onOutlookBoundChanged', () => {
                this.getBoundedEmails();
            });
        }
    }

    OnWorkingHoursChanged() {
        if (this.hubConnection.state == signalR.HubConnectionState.Connected) {
            this.hubConnection.send('OnWorkingHoursChanged');
        }
    }

    resourcesWorkingHoursList = [];
    setResourcesWorkingHoursOfflineList() {
        let currentUrlNow = this.router.url;
        // var audio = new Audio('../assets/sounds/notification_sound.mp3');
        // audio.play();
        this._obs.next('Receiving Updates...');
        this._bookingService
            .GetResourcesWorkingHours()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                if (result.isSuccess) {
                    var lst = result.data;
                    if (lst && lst.length >= 1) {
                        //localStorage.setItem('resourcesWorkingHours', JSON.stringify(lst));
                        this.resourcesWorkingHoursList = lst;
                        if (currentUrlNow == '/calender') {
                            this.ngZone.run(() => {
                                let currentUrl = this.router.url;
                                // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                                // this.router.onSameUrlNavigation = 'reload';
                                // this.router.navigate([currentUrl]);
                                this.router
                                    .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
                                    .then(() => {
                                        this.router.navigate([currentUrl]);
                                    });
                            });
                        }
                    }
                }
                this._obs.next(this.hubConnection.state);
            });
    }

    closeConnetion() {
        // if (this.hubConnection) {
        //     this.hubConnection.stop().then(() => {
        //         console.log('Hub Connection Closed!')
        //     });
        // }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
    }

    getBoundedEmails() {
        var secObjAsString = localStorage.getItem('securityObject');
        var secObj = JSON.parse(secObjAsString);
        this.secServ
            .getUserBoundedEmails(secObj.contactId)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                if (result.isSuccess) {
                    localStorage.setItem('ouBoundedList', JSON.stringify(result.data));
                }
            });
    }

}
