import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Result } from '../_models';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ResourcesService {
    private canonicalName: string;

    APIURL = '';
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;
    constructor(public router: Router, private http: HttpClient) {
        const newurl = window.location.href;

        if (this.isOnPremise) this.APIURL = this.appUrl;
        else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
        }

        if (this.APIURL.search('localhost') > -1) {
            // this.APIURL = 'https://main.api.sharewinds.com/api/v1/';
            this.APIURL = environment.localHostUrl;
        }

        // this.APIURL= 'http://testbe.api.sharewinds.com/api/v1/';
        // this.APIURL = 'http://localhost:8988/api/v1/';
    }

    getTimeZoneList() {
        return this.http.get<Result>(`${this.APIURL}CountryTimeZone/`);
    }
    getDefaultTimeZone() {
        return this.http.get<Result>(`${this.APIURL}CountryTimeZone/GetDefaultTimeZone/`);
    }
    SetDefaultTimeZone(CountryId) {
        return this.http.post<Result>(`${this.APIURL}CountryTimeZone/SetDefaultTimeZone/${CountryId}`, CountryId);
    }
    ActivateTimeZone(ActivateDefault) {
        return this.http.post<Result>(
            `${this.APIURL}CountryTimeZone/ActivateTimeZone/${ActivateDefault}`,
            ActivateDefault
        );
    }
    getresourcesbylocationandcategory(
        timeslotType = null,
        ExcludeFixedBookingResources = false,
        ExcludeInventoryAndCapcityResource = false
    ) {
        // return this.http.get<Result>(`${this.APIURL}Asset/GetAllByLocationAndCategory`);

        let securityObject = JSON.parse(localStorage.getItem('securityObject'));
        var AvRes = securityObject.avaResources;
        var userId = securityObject.userId;
        var cultureId = securityObject.cultureId;
        var timeSlotQParam = `?timeSlotType=${timeslotType}&ExcludeFixedBookingResources=${ExcludeFixedBookingResources}&ExcludeInventoryAndCapcityResource=${ExcludeInventoryAndCapcityResource}`;
        return this.http.post<Result>(
            `${this.APIURL}Resource/GetAllByLocationAndCategoryTryEnhc/` + userId + '/' + cultureId + timeSlotQParam,
            AvRes
        );
        //return this.http.post<Result>(`http://localhost:8988/api/v1/Resource/GetAllByLocationAndCategoryTryEnhc/` + userId + '/' + cultureId + timeSlotQParam , AvRes);
    }

    // api/v1/PR/Get_PR_Resource_Available_Slots/{ResourceId}
    GetPredefinedResourceAvailableSlots(bookingId, resourceId, serviceId) {
        return this.http.get<Result>(
            `${this.APIURL}PR/Get_PR_Resource_Available_Slots/${bookingId}/${resourceId}/${serviceId}`
        );
    }

    AddToFav(obj) {
        return this.http.post<Result>(`${this.APIURL}RoleResource/add`, obj);
    }
    RemoveFromFav(obj) {
        return this.http.put<Result>(`${this.APIURL}RoleResource/DeleteFav`, obj);
    }
    getFavList() {
        return this.http.get<Result>(`${this.APIURL}RoleResource/GetResFav`);
    }
    checkAddAccessType(resourceId) {
        return this.http.get<Result>(`${this.APIURL}BBooking/Check-addAccessType/` + resourceId);
    }

    deleteresourceLst(reslst: any) {
        return this.http.post<Result>(`${this.APIURL}Asset/DeleteResources`, reslst);
    }
    deleteresourceLstWizerd(reslst: any) {
        return this.http.post<Result>(`${this.APIURL}Asset/DeleteResourcesWizerd`, reslst);
    }

    CheckWPBookings(resourceId) {
        return this.http.get<Result>(`${this.APIURL}Asset/Check-WP-Bookings/` + resourceId);
    }

    Removeresourcesign(resource: any) {
        return this.http.post<Result>(`${this.APIURL}Asset/remove-resource-sign`, resource);
    }

    getresourcesbycatid(
        catid: any,
        timeSlotsType = 'Open',
        ExcludeFixedBookingResources = false,
        ExcludeInventoryAndCapcityResource = false
    ) {
        var param = `?timeSlotType=${timeSlotsType}&ExcludeFixedBookingResources=${ExcludeFixedBookingResources}&ExcludeInventoryAndCapcityResource=${ExcludeInventoryAndCapcityResource}`;
        return this.http.get<Result>(`${this.APIURL}Asset/GetResources-byCategory/` + catid + param);
    }

    getresourcesbylocid(
        locid: any,
        timeSlotsType = 'Open',
        ExcludeFixedBookingResources = false,
        ExcludeInventoryAndCapcityResource = false
    ) {
        var param = `?timeSlotType=${timeSlotsType}&ExcludeFixedBookingResources=${ExcludeFixedBookingResources}&ExcludeInventoryAndCapcityResource=${ExcludeInventoryAndCapcityResource}`;
        return this.http.get<Result>(`${this.APIURL}Asset/GetResources-byLocation/` + locid + param);
    }
    getimage(filename: string) {
        return this.http.get<Result>(`${this.APIURL}SWMaps/images/` + filename);
    }
    getimageUrl(filename: string) {
        return `${this.APIURL}SWMaps/images/` + filename;
    }
    uploadimage(fileToUpload: File) {
        const formData = new FormData();
        const headers = new HttpHeaders({ name: 'Accept', value: 'application/json' });
        formData.append('file', fileToUpload, fileToUpload.name);
        return this.http.post<Result>(`${this.APIURL}SWMaps/Upload`, formData, { headers: headers });
    }
    addresource(resource: any) {
        return this.http.post<Result>(`${this.APIURL}Asset/add-resource`, resource);
    }
    editresource(resource: any) {
        return this.http.post<Result>(`${this.APIURL}Asset/update-resource`, resource);
        //return this.http.post<Result>(`http://localhost:8988/api/v1/Asset/update-resource`, resource);
    }

    Checkresourcesign(resource: any) {
        return this.http.post<Result>(`${this.APIURL}Asset/check-resource-sign`, resource);
    }

    getresourcebyid(resourceid, serviceId = null) {
        // return this.http.get<Result>(`${this.APIURL}Asset/getResource-byId/` + resourceid);

        let QPramTerm = '';
        if (serviceId) QPramTerm = '?serviceId=' + serviceId;

        return this.http.get<Result>(`${this.APIURL}Resource/getResource-byId/` + resourceid + QPramTerm);
    }
    getResourcebyName(name) {
        return this.http.get<Result>(`${this.APIURL}Asset/getResource-byName/` + name);
    }
    getfixedassets() {
        return this.http.get<Result>(`${this.APIURL}Asset/GetFixedAssts`);
    }
    getfixedassetbyid(id) {
        return this.http.get<Result>(`${this.APIURL}Asset/GetFixedassesById/` + id);
    }
    addfixedasset(asset: any) {
        return this.http.post<Result>(`${this.APIURL}Asset/add-FixedAsset`, asset);
    }
    editfixedasset(asset: any) {
        return this.http.post<Result>(`${this.APIURL}Asset/update-FixedAsset`, asset);
    }
    deletefixedassets(assets: any) {
        return this.http.post<Result>(`${this.APIURL}Asset/delete-FixedAssets`, assets);
    }
    deleteResourcesfixedassets(resourceid, assets: any) {
        return this.http.post<Result>(`${this.APIURL}Asset/delete-ResourcesFixedAssets/` + resourceid, assets);
    }

    getApprovingUserName(id) {
        return this.http.get<Result>(`${this.APIURL}Asset/getApprovingUserName/` + id);
    }

    getApprovingUserId(userName) {
        return this.http.get<Result>(`${this.APIURL}Asset/getApprovingUserId/` + userName);
    }

    getTranslatedResNames(resList: any) {
        return this.http.post<Result>(`${this.APIURL}Localization/GetTranslatedSelectedResourcesNames`, resList);
    }

    getUserResFav() {
        return this.http.get<Result>(`${this.APIURL}RoleResource/GetResFav`);
    }

    checkDuplicationOfResCode(resId, resCode) {
        return this.http.get<Result>(`${this.APIURL}Asset/checkDuplicationOfResCode/` + resId + '/' + resCode);
        // return this.http.get<Result>(`https://localhost:44342/api/v1/Asset/checkDuplicationOfResCode/` + resId + '/' + resCode);
    }

    GetResourceOpreationTimes(resId) {
        return this.http.get<Result>(`${this.APIURL}Asset/GetResourceOpreationCalendar/` + resId);
        // return this.http.get<Result>(`http://localhost:8988/api/v1/Asset/GetResourceOpreationCalendar/` + resId);
    }

    getResourceQtyAvailability(
        bookingId: string,
        resourceId: string,
        data: any,
        predefinedType: string,
        serviceId = null
    ) {
        let qParamVal = '';
        if (serviceId) {
            qParamVal = '?serviceId=' + serviceId;
        }

        return this.http.get<Result>(
            `${this.APIURL}BBooking/ResourceQtyAvailability/${bookingId}/${resourceId}/${data.qty}/${data.calendarDate}/${data.calendarendDate}/${data.calendarFromTime}/${data.calendarToTime}/${predefinedType}${qParamVal}`
        );
    }

    GetLocationLayoutDetails(locationId) {
        return this.http.get<Result>(`${this.APIURL}layout/GetLocationLayoutDetails/` + locationId);
        //return this.http.get<Result>(`http://localhost:8988/api/v1/layout/GetLocationLayoutDetails/` + locationId);
    }
    postLocationLayoutDetails(data) {
        return this.http.post<Result>(`${this.APIURL}layout/SaveLocationLayoutDetalis`, data);
        //return this.http.post<Result>(`http://localhost:8988/api/v1/layout/SaveLocationLayoutDetalis`, data);
    }

    GetGenericLayoutDetails(layoutId) {
        return this.http.get<Result>(`${this.APIURL}layout/GetGenericLayoutDetails/` + layoutId);
        //return this.http.get<Result>(`http://localhost:8988/api/v1/layout/GetGenericLayoutDetails/` + layoutId);
    }

    postGenericLayoutDetails(data) {
        return this.http.post<Result>(`${this.APIURL}layout/SaveGenericLayoutDetalis`, data);
        //return this.http.post<Result>(`http://localhost:8988/api/v1/layout/SaveGenericLayoutDetalis`, data);
    }
    postGenericLayoutDetailsWithGeneratedMode(data) {
        return this.http.post<Result>(
            `${this.APIURL}layout/SaveGenericLayoutDetalisWithResourceGeneratedModeEnabled`,
            data
        );
        //return this.http.post<Result>(`http://localhost:8988/api/v1/layout/SaveGenericLayoutDetalis`, data);
    }
    updateJustLayoutNames(data) {
        return this.http.post<Result>(`${this.APIURL}layout/updateJustLayoutNames`, data);
        //return this.http.post<Result>(`http://localhost:8988/api/v1/layout/SaveGenericLayoutDetalis`, data);
    }
    deleteSelectedLayouts(selectedLayouts) {
        return this.http.post<Result>(`${this.APIURL}layout/deleteLayouts`, selectedLayouts);
        //return this.http.post<Result>(`http://localhost:8988/api/v1/layout/SaveGenericLayoutDetalis`, data);
    }
}
