import { Validators } from '@angular/forms';

export enum FieldType{
    textType,
    numberType,
    checkboxType,
    comboboxType,
    dateType,    
    timeType,
    multiselectDropdownType,
    passwordType,
    dateRangeType,
    rangeSliderType,
    popupButtonType,
    textBoxType,
    comboboxWithSearch,
    percentType,
}

export class FormItem {
    label: string;
    value: any;
    rowId: number;
    isFullWidth?: boolean;
    minDateValue?: Date;
    minDate?: string;
    isDisabled: boolean=true;
    fieldType: FieldType;
    prop: string;
    comboList?: any[];
    validator?: Validators[];
    isValidate?: boolean;
    validationMessage?: string[];
}
