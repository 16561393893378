import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angular2-qrcode';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DemoMaterialModule } from './matrialModule';
import { OrderModule } from 'ngx-order-pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MessageSystemComponent } from './component/message-system/message-system.component';
import { AutofocusDirective } from './_directives/autofocus.directive';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';

import { SearchPipe } from './pipes/search.pipe';
import { TimeTo12Pipe } from './pipes/time-to-12.pipe';
import { FileUploadModule } from 'ng2-file-upload';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { SimpleNotificationsModule } from 'angular2-notifications';
// import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
// import {JqxModule} from './jqwModels';
// import {JQWidgetModule} from './jqwidgetModels';
import { AutosizeMe } from './_directives';

import { ReadMoreComponent } from './component/read-more/read-more.component';
import { LaddaModule } from 'angular2-ladda';
import { NgxEditorModule } from 'ngx-editor';
import { CustomeDatePipe } from './pipes/convertDateInString.pipe';
import { MatCardModule } from '@angular/material/card';
import { TableComponent } from './component/table/table.component';

import { MssgComponent } from './component/mssg/mssg.component';
import { SelectUserComponent } from './component/select-user/select-user.component';
import { LocalizationComponent } from '../layout/localization/localization.component';
import { UploadImgB64Component } from './component/upload-img-b64/upload-img-b64.component';
import { UploadImgFileComponent } from './component/upload-img-file/upload-img-file.component';
import { CalenderComponent } from './component/calender/calender.component';
import { SearchRLocationComponent } from './component/calender/search-rlocation/search-rlocation.component';
import { SearchRAllComponent } from './component/calender/search-rall/search-rall.component';
import { SearchRCategoryesTreeComponent } from './component/calender/search-rcategoryes-tree/search-rcategoryes-tree.component';
import { ContactMFComponent } from './component/MasterFiles/contact-mf/contact-mf.component';
import { SignComponent } from './component/MasterFiles/sign/sign.component';
import { CapacityComponent } from './component/MasterFiles/capacity/capacity.component';
import { FixedAssetsComponent } from './component/MasterFiles/fixed-assets/fixed-assets.component';
import { jqxSchedulerComponent } from '../../assets/jqWidgetFiles/jqwidgets-ts/angular_jqxscheduler';
import { SwGenericAlertComponent } from './component/swAlerts/sw-generic-alert.component';
import { jqxEditorComponent } from '../../assets/jqWidgetFiles/jqwidgets-ts/angular_jqxeditor';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { TabViewModule } from 'primeng/tabview';
import { JqwTimeCaptureComponent } from './component/jqw-time-capture/jqw-time-capture.component';
import { TimeCaptureComponent } from './component/time-capture/time-capture.component';
import { DatePickerHeaderTimeCaptuerComponent } from './component/time-capture/date-picker-header-time-captuer/date-picker-header-time-captuer.component';
import { UserSecurityComponent } from 'app/layout/users/creat-edit-user/user-security/user-security.component';
import { CredentialsComponent } from 'app/layout/users/creat-edit-user/credentials/credentials.component';
import { AttachmentUDNComponent } from './component/attachment-udn/attachment-udn.component';
import { FilterArrayPipe } from './helpers/filter-array.pipe';
import { ServicesComponent } from 'app/layout/Services/services/services.component';
import { SearchRServicesComponent } from './component/calender/search-r-services/search-r-services.component';
import { SearchRGroupsComponent } from './component/calender/search-r-groups/search-r-groups.component';
import { SearchCategoriesTreeComponent } from './component/calender/search-categories-checkbox-tree/search-categories-checkbox-tree.component';
import { SearchLocationCheckboxTreeComponent } from './component/calender/search-location-checkbox-tree/search-location-checkbox-tree.component';
import { CaptureDataButtonComponent } from './component/capture-data-button/capture-data-button.component';
import { StylePaginatorDirective } from './_directives/stylePaging.directive';
import { MasterPageLayoutComponent } from './component/MasterFiles/master-page-layout/master-page-layout.component';
import { jqxLayoutComponent } from '../../assets/jqWidgetFiles/jqwidgets-ts/angular_jqxlayout';
import { MatExpansionModule } from '@angular/material/expansion';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        NgxMaterialTimepickerModule,
        // NgxDaterangepickerMd.forRoot(),
        TranslateModule,
        FormsModule,
        DemoMaterialModule,
        ReactiveFormsModule,
        FileUploadModule,
        QRCodeModule,
        NgxSpinnerModule,
        BsDropdownModule.forRoot(),
        OrderModule,
        NgSelectModule,
        CKEditorModule,
        ModalModule.forRoot(),
        PerfectScrollbarModule,
        TabsModule.forRoot(),
        ProgressbarModule.forRoot(),
        // NgxDatatableModule,
        // SimpleNotificationsModule.forRoot(),
        LaddaModule,
        AlertModule.forRoot(),
        SplitButtonModule,
        ToastModule,
        ScheduleModule,
        InputTextModule,
        CheckboxModule,
        ButtonModule,
        RadioButtonModule,
        NgxMatTimepickerModule,

        RippleModule,
        NgxEditorModule,
        MatCardModule,
        MatExpansionModule
    ],

    exports: [
        FormsModule,
        AttachmentUDNComponent,
        AutofocusDirective,
        NgxMaterialTimepickerModule,
        AutosizeMe,
        ProgressbarModule,
        TabsModule,
        // NgxDaterangepickerMd,
        ScheduleModule,
        // SimpleNotificationsModule,
        PerfectScrollbarModule,
        NgxMatTimepickerModule,
        QRCodeModule,
        NgxSpinnerModule,
        BsDropdownModule,
        NgSelectModule,
        FileUploadModule,
        TranslateModule,
        DemoMaterialModule,
        OrderModule,
        LaddaModule,
        ReactiveFormsModule,
        SearchPipe,
        TimeTo12Pipe,
        CKEditorModule,
        ReadMoreComponent,
        MessageSystemComponent,
        ModalModule,
        AlertModule,
        // NgxDatatableModule,
        NgxEditorModule,
        CustomeDatePipe,
        SplitButtonModule,
        TimeCaptureComponent,
        UserSecurityComponent,
        ToastModule,
        InputTextModule,
        CheckboxModule,
        ButtonModule,
        RadioButtonModule,
        RippleModule,
        CalenderComponent,
        SearchRLocationComponent,
        SearchRAllComponent,
        SearchRCategoryesTreeComponent,
        SearchCategoriesTreeComponent,
        TableComponent,
        LocalizationComponent,
        UploadImgB64Component,
        MssgComponent,
        SelectUserComponent,
        UploadImgFileComponent,
        jqxSchedulerComponent,
        ContactMFComponent,
        SignComponent,
        CapacityComponent,
        FixedAssetsComponent,
        MatCardModule,
        SwGenericAlertComponent,
        jqxEditorComponent,
        JqwTimeCaptureComponent,
        CredentialsComponent,
        FilterArrayPipe,
        ServicesComponent,
        SearchRServicesComponent,
        SearchRGroupsComponent,
        SearchLocationCheckboxTreeComponent,
        CaptureDataButtonComponent,
        StylePaginatorDirective,
        MasterPageLayoutComponent,
        jqxLayoutComponent,
        MatExpansionModule
    ],

    declarations: [
        AutofocusDirective,
        ReadMoreComponent,
        MessageSystemComponent,
        AutosizeMe,
        CalenderComponent,
        SearchRLocationComponent,
        UserSecurityComponent,
        SearchRAllComponent,
        SearchRCategoryesTreeComponent,
        SearchCategoriesTreeComponent,
        TableComponent,
        LocalizationComponent,
        UploadImgB64Component,
        MssgComponent,
        SelectUserComponent,
        UploadImgFileComponent,
        AttachmentUDNComponent,
        jqxSchedulerComponent,
        ContactMFComponent,
        SignComponent,
        CapacityComponent,
        FixedAssetsComponent,
        SearchPipe,
        TimeTo12Pipe,
        CustomeDatePipe,
        SwGenericAlertComponent,
        jqxEditorComponent,
        JqwTimeCaptureComponent,
        TimeCaptureComponent,
        DatePickerHeaderTimeCaptuerComponent,
        CredentialsComponent,
        FilterArrayPipe,
        ServicesComponent,
        SearchRServicesComponent,
        SearchRGroupsComponent,
        SearchLocationCheckboxTreeComponent,
        CaptureDataButtonComponent,
        StylePaginatorDirective,
        MasterPageLayoutComponent,
        jqxLayoutComponent
    ]
})
export class SharedModule {}
