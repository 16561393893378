export class FilterForecast {
    year: number;
    companyId: string;
}

export class Forecast {

    userId: string;
    date: Date;
    target: number;
    companyId: string;
    companyName: string;
    dealerForecast: number;
    cvsF: string;
}
