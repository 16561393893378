import { Branch } from './branch';
import { Country } from './country';

export class City {
    cityId: string;
    countryId: string;
    cityText: string;
    countryText: string;
    valid: boolean;
    country: Country;
    branch: Branch[];
}