export interface DeleteRestrictions {
    isSuccess: boolean;
    errorCode: number;
    message: string;
    messageType: string;
    data: Data;
}
interface Data {
    reasonsOfRestrictionMeanings: { [key: string]: string };
    resultAndReasons: ResultAndReason[];
}
interface ResultAndReason {
    resourceId: string;
    canDeactivateOrChange: boolean;
    reasonsOfRestriction: number[];
}
