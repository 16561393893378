import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import {
    Component,
    OnInit,
    ViewChild,
    TemplateRef,
    OnDestroy,
    Input,
    Output,
    AfterViewInit,
    EventEmitter
} from '@angular/core';
import swal from 'sweetalert2';
import { MatSort } from '@angular/material/sort';
import { first, takeUntil } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { UsersService, ErrorService } from '../../shared/services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
    FormBuilder,
    FormGroup,
    Validators,
    ValidatorFn,
    FormControl,
    FormGroupDirective,
    NgForm,
    ValidationErrors
} from '@angular/forms';
// import { Localization } from 'src/app/shared/models/localization';
import { Localization } from '@shared/_models/localization';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-localization',
    templateUrl: './localization.component.html',
    styleUrls: ['./localization.component.scss']
})
export class LocalizationComponent implements OnInit, OnDestroy {
    private _unsubscribeAll$ = new Subject<boolean>();
    // subscription: Subscription = new Subscription();

    constructor(
        private fb: FormBuilder,
        private _userService: UsersService,
        private modalService: BsModalService,
        public router: Router,
        public route: ActivatedRoute,
        private _errorService: ErrorService,
        private _usersService: UsersService
    ) { }

    @Input('masterItemId')
    masterItemId;
    @Input('CanAdd')
    CanAdd = true;
    @Output('CultureObj')
    CultureObj: EventEmitter<any> = new EventEmitter<any>();
    @Input('masterColumnName')
    masterColumnName;
    @Input('originalTableName')
    originalTableName;

    @Output('closeLocalizationPopup')
    closeLocalizationPopup: EventEmitter<any> = new EventEmitter<any>();

    DeletemasterItemId: string;

    // locationname = 'locationname';
    alerts: any[] = [
        {
            type: '',
            msg: ``,
            timeout: 1
        }
    ];
    searchValue = '';
    modalRef: BsModalRef | null;

    dangerAlert = false;
    laddaSearchProgress: boolean | number = false;
    LocalizationsList = [];
    selectedStatus = 'Active';
    userList = [];
    activeStyleUserTable = '';
    statusList = [{ name: 'All' }, { name: 'Active' }, { name: 'Inactive' }];
    actionStatus = null;
    actionsList = [{ name: 'Activate' }, { name: 'Delete' }, { name: 'Inactivate' }];
    localizationFrom: FormGroup;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    displayedColumns: string[] = ['cultureId', 'masterText'];
    dataSource = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);
    Cultures = [];
    shoeForm = false;
    saveContant = false;
    localization = new Localization();
    localizationLst = [];
    newcultures = [];
    private initForm(): void {
        this.localizationFrom = this.fb.group({
            localizationId: new FormControl(null),
            masterItemId: new FormControl(null),
            cultureId: new FormControl(null),
            masterText: new FormControl(null),
            createUser: new FormControl(null),
            modifyUser: new FormControl(null),
            createStamp: new FormControl(null),
            modifyStamp: new FormControl(null),
            masterColumnName: new FormControl(null),
            cultureName: new FormControl(null),
            languageId: new FormControl(null),
            languageName: new FormControl(null),
            direction: new FormControl(null)
        });
    }
    selectUser(data) { }
    AddNewItem() {
        this.changeNewContant = true;
        const newItem = {
            masterItemId: this.masterItemId,
            cultureId: '',
            masterText: 0,

            createUser: '',
            modifyUser: '',
            createStamp: 0,
            modifyStamp: '',
            masterColumnName: this.masterColumnName,
            originalTableName: this.originalTableName,
            cultureName: 0,

            languageId: 0,
            languageName: 0,
            direction: 0
        };
        this.LocalizationsList?.unshift(newItem);
        this.dataSource = new MatTableDataSource(this.LocalizationsList);
        this.dataSource.sort = this.sort;
    }

    removeItem() {
        this.changeNewContant = true;
        this.LocalizationsList.shift();
        this.dataSource = new MatTableDataSource(this.LocalizationsList);
        this.dataSource.sort = this.sort;
    }

    openModal(template: TemplateRef<any>) {

        this.loadLists();
        if (this.masterItemId != '' && this.masterItemId != null && this.masterItemId != 'undefined') {
            this.getTransListForItem(this.masterItemId, this.masterColumnName);
        }


        const configs = {
            keyboard: false,
            ignoreBackdropClick: true,
            class: ' modal-lg modal-primary ModelLayout commenModel animated fadeInRight',
            animated: false
        };

        this.modalRef = this.modalService.show(template, configs);
        if (this.masterItemId != '' && this.masterItemId != null && this.masterItemId != 'undefined') return;
        else {
            this.dataSource = new MatTableDataSource(this.savedDataSource);
            this.LocalizationsList = this.savedDataSource || [];
        }
        // this.modalRef = this.modalService.show(template);
    }
    changeContant() { }

    editUser(data) {
        this.router.navigate(['users/CEUsers', data.contactId]);
    }
    addAlert(typeData, msgData): void {
        this.dangerAlert = false;

        let time = 3000;
        if (typeData === 'danger') {
            this.dangerAlert = true;
            time = 99999999999;
        }
        if (this.alerts.length === 0) {
            this.alerts.push({
                type: typeData,
                msg: msgData,
                timeout: time
            });
        } else {
            this.alerts.splice(0, 1);
            this.alerts.push({
                type: typeData,
                msg: msgData,
                timeout: time
            });
        }
    }

    onSelect(event) { }

    onClosedAlert(dismissedAlert): void {
        this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
        this.alerts = [];
        this.dangerAlert = false;
    }

    sortData(datat) { }

    getTransListForItem(id, name) {
        this.searchValue = '';
        this._usersService
            .getListOfLocalizations(id, name)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.LocalizationsList = result.data;
                this.dataSource = new MatTableDataSource(this.LocalizationsList);
                if (result.data.length > 0) {
                    this.DeletemasterItemId = result.data[0].masterItemId;
                }
                this.dataSource.sort = this.sort;
                // console.log(this.LocalizationsList);
            });
    }

    filterStatus(status) {
        const newList = [];
        this.dataSource = new MatTableDataSource(this.LocalizationsList);

        if (status === '') {
        } else {
            this.dataSource.filteredData.forEach((element) => {
                if (element.isvalid === status) {
                    newList.push(element);
                }
            });
            this.dataSource = new MatTableDataSource(newList);
        }
        this.dataSource.sort = this.sort;

        this.applyFilter(this.searchValue);
    }

    loadLists() {
        this._userService
            .GetCultures()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.Cultures = result.data;

                // result.data.array.forEach(element => {
                //   this.Cultures.push({cultureId: element.cultureId, cultureName: element.cultureName, disabled: 'readOnly'});
                // });
                //     const index = this.Cultures.indexOf(localStorage.getItem('userLanguage'));
                //     this.Cultures.splice(index, 1);
            });
    }

    ngOnInit() {
        this.alerts = [];
        this.initForm();
        // if (this.masterItemId != '' && this.masterItemId != null && this.masterItemId != 'undefined') {
        //     this.getTransListForItem(this.masterItemId, this.masterColumnName);
        // }
        // this.loadLists();
        this._errorService.currentAction
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((action) => this.actionMsg(action));
    }

    ngOnChanges(): void {
        //this.getTransListForItem(this.masterItemId, this.masterColumnName);
    }

    afterSaveSub: Subscription;
    canGo = true;
    public savedDataSource;
    saveAddEdit() {
        this.savedDataSource = this.dataSource.data;
        this.localizationLst = [];
        this.LocalizationsList.forEach((key) => {
            var exist = this.localizationLst.find((l) => l.cultureId == key.cultureId);
            if (exist) {
                this.canGo = false;
                this.newMessage(162, '');
            } else this.canGo = true;

            this.localization = new Localization();
            this.localization.masterColumnName = key.masterColumnName;
            this.localization.cultureId = key.cultureId;
            this.localization.masterItemId = key.masterItemId;
            this.localization.masterText = key.masterText;
            this.localization.localizationId = key.localizationId;
            this.localization.originalTableName = this.originalTableName;
            this.localizationLst.push(this.localization);
        });

        if (!this.canGo) return;

        if (this.CanAdd == true) {
            if (this.localizationLst.length == 0) {
                if (this.DeletemasterItemId != null) {
                    this.localization = new Localization();
                    this.localization.masterItemId = this.DeletemasterItemId;
                    this.localizationLst.push(this.localization);
                }
            }

            this.afterSaveSub = this._usersService
                .addLocalizationLst(this.localizationLst)
                .pipe(takeUntil(this._unsubscribeAll$), first())
                .subscribe((result) => {
                    if (result.isSuccess) {
                        this.newMessage(112, '');
                        this.changeNewContant = false;
                        this.closeAddEditModal();
                    } else {
                    }
                });
        } else {
            this.CultureObj.emit(this.localizationLst);
            this.newMessage(112, '');
            this.changeNewContant = false;
        }
    }
    public saveAddEditGeneric(localizationLst: any) {
        this._usersService
            .addLocalizationLst(localizationLst)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                if (result.isSuccess) {
                    //this.newMessage(112, '');
                } else {
                }
            });
    }
    changeNewContant = false;

    closeAddEditModal() {
        if (!this.changeNewContant) {
            // this.getTransListForItem(this.masterItemId, this.masterColumnName);

            this.modalRef.hide();
        } else {
            this.newMessage(144, 'ClosePopupLocalization');
        }
    }

    ClosePopupLocalization() {
        this.changeNewContant = false;
        this.closeLocalizationPopup.emit();
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    actionMsg(data) {
        if (data !== '') {
            if (this[data]) {

                if (typeof this[data] === 'function') {
                    this[data]();
                    this._errorService.action('');
                } else {
                    console.error(`${data} is not a function`);
                }


            } //else
            //console.warn(`${data} function not event exist in ${typeof (this)}`);
        }
    }

    newMessage(id, action) {
        this._errorService.changeMessage({ id: id, action: action });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
        // this.subscription.unsubscribe();
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }
}
