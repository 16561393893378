import { Injectable } from '@angular/core';
import * as messages from '../../../assets/appMessages.json';

@Injectable({
    providedIn: 'root'
})
export class onClientMessagesService {
    messagesList: any;
    constructor() {
        Object.keys(messages).forEach((key) => {
            var val = messages[key];
            this.messagesList = val;
        });
    }

    getMessage(messageKey) {
        var langCode = localStorage.getItem('langCode');
        if (!langCode) langCode = 'en';

        messageKey = messageKey + '-' + langCode;
        return this.messagesList[messageKey];
    }
}
