import { AppUserClaim } from './app-user-claim';

export class AppUserAuth {
    userName: string = '';
    userImg: string = '';
    bearerToken: string = '';
    companyId: string = '';
    companyCode: string = '';
    countryId: string = '';
    dateFormat: string = '';

    countryCode: string = '';
    parentUserId: string = '';
    parentUserName: string = '';
    isAuthenticated: boolean = false;
    isFCA: boolean = false;
    isAdmin: boolean = false;
    userId: string;
    // claims: AppUserClaim[] = [];
    sideMenu: any[] = [];
    expressMenu: any[] = [];

    TwoFactorAuthRequired?: any;
    companyName?: any;
    companyPhone?: any;
    companyEmail?: any;
    userEmployeePosition?: any;
}
