import {
    Component,
    OnInit,
    ViewEncapsulation,
    OnDestroy,
    ViewChild,
    TemplateRef,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';
import { first, takeUntil } from 'rxjs/operators';

import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';

import { BaseService } from '@shared/services';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-attachment-udn',
    templateUrl: './attachment-udn.component.html',
    styleUrls: ['./attachment-udn.component.scss']
})
export class AttachmentUDNComponent implements OnInit, OnDestroy {
    private _unsubscribeAll$ = new Subject<boolean>();

    // tslint:disable-next-line:max-line-length
    constructor(
        private _baseService: BaseService,
        private fb: FormBuilder,
        private translate: TranslateService,
        private _modalService: BsModalService
    ) {}

    items: MenuItem[];
    attachmentList = [
        {
            filepath: '',
            fileDescription: '',
            attachementtype: ''
        }
    ];
    // tslint:disable-next-line:no-input-rename
    // @Input('canAdd') canAdd: boolean;
    @Input('readonlyForEdit') readonlyForEdit: boolean;
    @Input() childId: any;
    @Input() removeSelect: boolean;
    @Input() urlpath: any;
    @Input() attachmentFilesList;
    // tslint:disable-next-line:no-output-rename
    @Output('allAttachmentList')
    allAttachmentList: EventEmitter<any> = new EventEmitter<any>();

    // tslint:disable-next-line:no-output-rename
    @Output('newAttachment')
    newAttachment: EventEmitter<any> = new EventEmitter<any>();

    // tslint:disable-next-line:no-output-rename
    @Output('deletAttachment')
    deletAttachment: EventEmitter<any> = new EventEmitter<any>();

    displayedColumns: string[] = ['select', 'filepath', 'fileDescription'];
    dataSource = new MatTableDataSource<any>(this.attachmentList);
    selection = new SelectionModel<any>(true, []);

    @ViewChild(MatSort) sortAttachment: MatSort;
    formAddAttachmentsModalRef: BsModalRef;
    @ViewChild('formAddAttachmentsModal') formAddModal: TemplateRef<any>;

    customClasses = {
        sortAscending: 'datatable-icon-down',
        sortDescending: 'datatable-icon-up'
    };
    attachmentForm: FormGroup;
    @ViewChild('formAddEditModal', { static: true }) formModal: TemplateRef<any>;
    formModalRef: BsModalRef | null;

    openPopup = false;
    changeNewContant = false;
    saveContant = false;
    fileToUpload: File = null;
    fileSize: any;

    attFormDescription = '';
    dangerAlert = false;
    laddaSearchProgress: boolean | number = false;
    alerts: any[] = [
        {
            type: '',
            msg: ``,
            timeout: 1
        }
    ];

    openAddAttachmentsModal() {
        this.attachmentForm.reset();

        const configs = {
            keyboard: false,
            ignoreBackdropClick: true,
            class: 'modal-lg modal-primary ModelLayout commenModel animated fadeInRight',
            animated: false
        };
        this.formAddAttachmentsModalRef = this._modalService.show(this.formAddModal, configs);
    }

    private initForm(): void {
        this.attachmentForm = this.fb.group({
            name: new FormControl(null, [Validators.required]),
            file: new FormControl(null, [Validators.required]),
            attachementtype: new FormControl(null),
            threadid: new FormControl(null)
        });
    }

    closeAddAttachmentsModal() {
        this.laddaSearchProgress = false;
        this.fileToUpload = null;
        this.attFormDescription = '';
        this.formModalRef.hide();
        this.fileSize = null;
    }
    ngOnInit() {
        this.items = [
            {
                label: this.translate.instant('Delete'),
                icon: 'pi pi-times',
                command: () => {
                    this.deleteAttachments();
                }
            }
        ];

        this.alerts = [];
        this.initForm();
        this.attachmentList = [];

        // tslint:disable-next-line:no-debugger

        this.attachmentList = this.attachmentFilesList;
        this.dataSource = new MatTableDataSource<any>(this.attachmentList);
        // tslint:disable-next-line:no-debugger

        this.dataSource.sort = this.sortAttachment;

        // tslint:disable-next-line:no-debugger
    }
    // tslint:disable-next-line:use-lifecycle-interface
    ngOnChanges() {
        this.attachmentList = [];
        this.attachmentList = this.attachmentFilesList;

        // tslint:disable-next-line:no-debugger

        if (this.attachmentFilesList && this.attachmentFilesList.length > 0) {
            debugger;
            this.dataSource = new MatTableDataSource(this.attachmentFilesList);
            this.dataSource.sort = this.sortAttachment;
        }

        if (this.removeSelect) {
            //this.displayedColumns = [ 'filepath', 'fileDscription'];
        }
    }
    openFormModal() {
        this.initForm();
        const configs = {
            keyboard: false,
            ignoreBackdropClick: true,
            class: ' modal-lg modal-primary ModelLayout commenModel  fadeInRight',
            animated: false
        };
        this.formModalRef = this._modalService.show(this.formModal, configs);
        this.openPopup = true;
    }
    closeAddEditModal() {
        if (this.changeNewContant) {
            this.confirmClose();
        } else {
            this.closeAddAttachmentsModal();
            this.openPopup = false;
            this.changeNewContant = false;
            this.saveContant = false;
        }
    }
    confirmClose() {
        this._baseService
            .get('1')
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                // console.log(result.data);

                const data = result.data;
                swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    title: data.messageTitle,
                    text: data.messageText,
                    type: data.icon.toLowerCase(),
                    showCancelButton: data.buttonTwoTitle ? true : false,
                    confirmButtonColor: '#FF8989',
                    cancelButtonColor: '#BCBCCB',
                    // iconColor:  '#FF8989',
                    cancelButtonText: data.buttonTwoTitle,
                    confirmButtonText: data.buttonOneTitle

                    // tslint:disable-next-line:no-shadowed-variable
                }).then((result) => {
                    if (result.value) {
                        // swal(
                        //   couponstatus+'!',
                        //   'The Coupon has been '+couponstatus+'.',
                        //   'success'
                        // )
                    } else {
                        this.closeAddAttachmentsModal();
                        this.openPopup = false;
                        this.changeNewContant = false;
                        this.saveContant = false;
                    }
                });
            });
    }
    addNewItem() {
        this.openFormModal();
    }

    openDialog() {}
    sortData(taps) {}
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.taskName + 1}`;
    }
    handleFileInput(files: FileList) {
        this.attachmentForm.controls['name'].setValue(files.item(0).name.split('.')[0]);
        this.attachmentForm.controls['attachementtype'].setValue(files.item(0).name.split('.')[1]);
        // check size
        // tslint:disable-next-line:no-unused-expression
        files.item(0).size;
        let _size = files[0].size;
        // tslint:disable-next-line:prefer-const
        let fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
            i = 0;
        while (_size > 900) {
            _size /= 1024;
            i++;
        }
        const exactSize = Math.round(_size * 100) / 100 + ' ' + fSExt[i];
        this.fileSize = exactSize;
        this.fileToUpload = files[0];
        this.changeNewContant = true;
    }
    errorMsgLocation(result) {
        if (result.data.location === 'Pop Up') {
            this.errorMsg(result.data);
        } else if (result.data.location === 'Top green') {
            this.addAlert('success', result.data.messageText);
        } else if (result.data.location === 'Top red') {
            this.addAlert('danger', result.data.messageText);
        } else {
            this.addAlert('danger', 'Failed to Save Changes');
        }
    }
    errorMsg(data) {
        swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: data.messageTitle,
            text: data.messageText,
            type: data.icon.toLowerCase(),
            showCancelButton: data.buttonTwoTitle ? true : false,
            confirmButtonColor: '#FF8989',
            cancelButtonColor: '#BCBCCB',
            // iconColor:  '#FF8989',
            cancelButtonText: data.buttonTwoTitle,
            confirmButtonText: data.buttonOneTitle
        }).then((result) => {
            if (result.value) {
            } else {
            }
        });
    }
    FileList = [];
    saveAddEdit() {
        // console.log(this.fileToUpload);
        const Description = this.attFormDescription;
        const file = this.fileToUpload;
        this.saveContant = true;
        if (!file || !file.name) {
            return;
        }
        // this.uploadFileToActivity()
        this.laddaSearchProgress = 1;

        this._baseService
            .uploadAttachment(file, this.urlpath, this.childId, this.attFormDescription)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    // console.log(result);
                    if (this.attachmentList && this.attachmentList != null && this.attachmentList.length > 0)
                        this.attachmentList.push(result.data);
                    if (!this.attachmentList || this.attachmentList == null || this.attachmentList == [])
                        this.attachmentList = this.FileList;

                    this.FileList.push(result.data);
                    this.newAttachment.emit(result.data);
                    var att = result.data;
                    var data = {
                        fileDescription: att.fileDescription,
                        filepath: att.filepath,
                        attachementtype: this.attachmentForm.value.attachementtype
                    };
                    this.dataSource = new MatTableDataSource(this.attachmentList);

                    //   this.allAttachmentList.emit( this.attachmentList);
                    this.attachmentForm.controls['threadid'].setValue(this.childId);

                    //  this.dataSource.sort = this.sortAttachment;
                    this.closeAddAttachmentsModal();
                    this.openPopup = false;
                    this.changeNewContant = false;
                    this.saveContant = false;
                    const allFiles = [];
                    allFiles.push(file);
                },
                (err) => console.log(err)
            );
    }

    onClosedAlert(dismissedAlert): void {
        this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
        this.alerts = [];
        this.dangerAlert = false;
    }
    addAlert(typeData, msgData): void {
        this.dangerAlert = false;

        let time = 3000;
        if (typeData === 'danger') {
            this.dangerAlert = true;
            time = 99999999999;
        }
        if (this.alerts.length === 0) {
            this.alerts.push({
                type: typeData,
                msg: msgData,
                timeout: time
            });
        } else {
            this.alerts.splice(0, 1);
            this.alerts.push({
                type: typeData,
                msg: msgData,
                timeout: time
            });
        }
    }
    downloadFile(event: any) {
        const str = this._baseService.downloadFilebyid(this.urlpath, this.childId, event.filepath);
        const pwa = window.open(str);
        if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
            alert('Please disable your Pop-up blocker and try again.');
        }
    }
    deleteAttachments() {
        if (this.selection.selected.length === 0) {
            return;
        }
        this.deletAttachment.emit(this.selection.selected);
        //// uncomment below and edit to delete attachment
        //  this.cmsService.deleteAttachment(this.selection.selected) .pipe(takeUntil(this._unsubscribeAll$), first()).subscribe(() =>{
        //  })
        const filenames = [];
        //// delete the file from server
        this.selection.selected.forEach((item) => {
            const index: number = this.attachmentList.findIndex((d) => d === item);
            // console.log(this.attachmentList.findIndex((d) => d === item));
            this.attachmentList.splice(index, 1);
            this.dataSource = new MatTableDataSource(this.attachmentList);
            this.dataSource.sort = this.sortAttachment;
            filenames.push(item['file']);
        });
        this._baseService
            .deletebyid(this.urlpath, this.childId, filenames)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(() => {});

        this.selection = new SelectionModel<Element>(true, []);
        //
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
    }
}
