import { Company } from './company';
import { Employee } from './employee';

export class Department {
    departmentId: string;
    companyId: string;
    departmentText: string;
    valid: boolean;
    companyName: string;
    employee: Employee;
    company: Company;
}

