import {
    Component,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    OnDestroy,
    OnInit,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BookingCalendar, BookingCalendarMultiResources } from '@shared/_models/bookingcalendar';
import { BookingService } from '@shared/services/booking.service';
import { first, takeUntil } from 'rxjs/operators';
import { ResourcesService } from '@shared/services/resources.service';
import { onClientMessagesService } from '@shared/services/onClientMessagesService.service';
import { ErrorService, BaseService } from '@shared/services';
import { MessageService } from '@shared/services/messageService';
import { DatePickerHeaderTimeCaptuerComponent } from './date-picker-header-time-captuer/date-picker-header-time-captuer.component';
import * as moment from 'moment';
// import { MatCalendar, MatDatepickerIntl } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFormats, SetFormate } from '@shared/_models/DateForamats';
import { BehaviorSubject, Subject } from 'rxjs';
import { DateRange } from '@angular/material/datepicker';
var date = new SetFormate();
var DateFormate = date.changeFormate();

@Component({
    selector: 'app-time-capture',
    templateUrl: './time-capture.component.html',
    styleUrls: ['./time-capture.component.scss'],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        { provide: MAT_DATE_FORMATS, useValue: DateFormate }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeCaptureComponent implements OnInit, OnDestroy {
    @Input('bookingId')
    bookingId: any;
    @Input('timeCaptureFirstInit')
    timeCaptureFirstInit = true;
    @Input('comingCalendarDate')
    comingCalendarDate: any;
    _resourceId;
    WorkingHoursConfigured: any;
    selectedDate: Date = new Date();
    @Input('resourceId') set resourceId(id) {
        this._resourceId = id;
        this.showTimer.next(false);
        if (!this.timeCaptureFirstInit) {
            this.resetActiveSlots();
        }
        this.reloadbookingList();
    }

    @Output('spinner')
    spinner: EventEmitter<any> = new EventEmitter<any>();

    @Output('newDates')
    newDates: EventEmitter<any> = new EventEmitter<any>();

    @Output('isWorkingHoursConfigured')
    isWorkingHoursConfigured: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('widgetsContent') widgetsContent: ElementRef;

    exampleHeader = DatePickerHeaderTimeCaptuerComponent;

    private unsubscribeAll$: Subject<boolean> = new Subject();

    listOfselectedTimeReverse = {
        startTimeS: '',
        startTimeE: '',
        startDate: '',
        endTimeS: '',
        endTimeE: '',
        endDate: ''
    };
    listOfSelectedDates = [];
    listOfBookedDates = [];
    today = new Date();
    dayView;
    dayIndex = 0;
    disabledHoursDayList = [];
    activeSingleTimeSlot = {
        startDayIndex: null,
        starthourIndex: null,
        starttimeIndex: null,
        endDayIndex: null,
        endhourIndex: null,
        endtimeIndex: null
    };
    activeDateRange = { start: 0, end: 0 };
    constructor(
        public router: Router,
        private _baseService: BaseService,
        private _messageService: MessageService,
        private _resourcesService: ResourcesService,
        private _errorService: ErrorService,
        private _clientMessage: onClientMessagesService,
        private _adapter: DateAdapter<any>
    ) {}

    ngOnInit(): void {
        // this.reloadbookingList();
        var currLang = localStorage.getItem('langCode');

        this._adapter.setLocale(currLang);
        this.curruntDate(this.dayIndex);
        var date = new SetFormate();
        DateFormate.display = date.changeFormate().display;
        try {
            this._adapter.format(moment(), DateFormate);
        } catch {}
    }

    MaxDate: any;
    showTimer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    reloadbookingList() {
        this.listOfBookedDates = [];

        if (!this._resourceId) {
            return;
        }

        this._baseService
            .getrestrictedSlotsInResource(this._resourceId, this.bookingId)
            .pipe(takeUntil(this.unsubscribeAll$), first())
            .subscribe((result) => {
                this.listOfBookedDates = result.restrictedSlotsDto;
                this.WorkingHoursConfigured = result.isWorkingHoursConfigured;
                this.isWorkingHoursConfigured.emit(result.isWorkingHoursConfigured);
                const date =
                    typeof result.maxIndexDay != 'string'
                        ? result.maxIndexDay.toISOString().slice(0, 10)
                        : result.maxIndexDay.slice(0, 10);

                this.MaxDate = date;
                this.showTimer.next(true);
                // debugger;
                // this.activeDateRange.start = null;
                // this.activeDateRange.end = null;
                this.getDisabledDates(this.curruntDate(this.dayIndex));

                if (this.timeCaptureFirstInit) {
                    this.setSelectedDates();
                } else {
                    const hours = new Date().getHours();
                    this.widgetsContent.nativeElement.scrollLeft += +hours * 23 * 4 - 400;
                }
            });
    }
    public resetActiveSlots(): void {
        this.activeDateRange.start = null;
        this.activeDateRange.end = null;
        this.listOfSelectedDates = [];
        this.dayIndex = 0;
        this.newDates.emit(null);
        this.getDisabledDates(this.curruntDate(this.dayIndex));
    }

    private setSelectedDates(): void {
        if (this.comingCalendarDate && this.comingCalendarDate != 'undefined') {
            if (this.comingCalendarDate[0]?.date && this.comingCalendarDate[0]?.time) {
                this.selectFirstClick();
            }
            if (this.comingCalendarDate[1]?.date) {
                this.selectSecondClick();
            }
        }
    }

    private selectFirstClick(): void {
        const fromDate = new Date(this.comingCalendarDate[0]?.date);
        const fromTime = this.comingCalendarDate[0]?.time;

        this.dateChanged(fromDate);
        const oneDay = 1000 * 60 * 60 * 24;
        const start = Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
        const end = Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        this.dayIndex = (start - end) / oneDay;
        let hours = fromTime?.slice(0, 2);
        let mins;
        if (hours >= '00' && hours <= '23') {
            mins = +fromTime?.slice(3, 5);
        } else {
            hours = fromTime?.slice(0, 1);

            mins = +fromTime?.slice(2, 4);
        }

        let timeSlot: any;

        if (mins === 0) timeSlot = { disabeld: false, timeindex: 1 };
        if (mins === 15) timeSlot = { disabeld: false, timeindex: 2 };
        if (mins === 30) timeSlot = { disabeld: false, timeindex: 3 };
        if (mins === 45) timeSlot = { disabeld: false, timeindex: 4 };
        this.selectTimeSlot(timeSlot, { hour: hours, hourIndex: +hours });
        this.widgetsContent.nativeElement.scrollLeft += +hours * 23 * 4 - 400;
    }

    private selectSecondClick(): void {
        const toDate = new Date(this.comingCalendarDate[1]?.date);
        const toTime = this.comingCalendarDate[1]?.time;

        this.dateChanged(toDate);
        const oneDay = 1000 * 60 * 60 * 24;
        const start = Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate());
        const end = Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        this.dayIndex = (start - end) / oneDay;
        let hours = toTime.slice(0, 2);
        let mins;
        if (hours >= '00' && hours <= '23') {
            mins = +toTime.slice(3, 5);
        } else {
            hours = toTime.slice(0, 1);

            mins = +toTime.slice(2, 4);
        }
        let timeSlot: any;

        if (mins === 0) {
            timeSlot = { disabeld: false, timeindex: 4 };
            hours -= 1;
        }
        if (mins === 59) timeSlot = { disabeld: false, timeindex: 4 };
        if (mins === 15) timeSlot = { disabeld: false, timeindex: 1 };
        if (mins === 30) timeSlot = { disabeld: false, timeindex: 2 };
        if (mins === 45) timeSlot = { disabeld: false, timeindex: 3 };
        this.selectTimeSlot(timeSlot, { hour: hours, hourIndex: +hours });
        this.widgetsContent.nativeElement.scrollLeft += +hours * 23 * 4 - 400;
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView();
    }
    hoursDay(dayIndex) {
        let hoursDayList = [];
        const today = new Date();
        const hours = today.getHours();
        let hourName = '';

        if (dayIndex < 0) {
            return hoursDayList;
        }
        if (dayIndex == 0) {
            hoursDayList = [];

            for (let index = 0; index < 24; index++) {
                if (index < hours) {
                    if (index < 10) {
                        hourName = `0${index}:00`;
                    } else {
                        hourName = `${index}:00`;
                    }
                    hoursDayList.push({
                        times: [
                            { disabled: true, timeindex: 1 },
                            { disabled: true, timeindex: 2 },
                            { disabled: true, timeindex: 3 },
                            { disabled: true, timeindex: 4 }
                        ],
                        hourIndex: index,
                        hour: hourName
                    });
                }

                if (index == hours) {
                    const minutes = today.getMinutes() / 15;
                    if (minutes < 1) {
                        if (index < 10) {
                            hourName = `0${index}:00`;
                        } else {
                            hourName = `${index}:00`;
                        }
                        hoursDayList.push({
                            times: [
                                { disabled: true, timeindex: 1 },
                                { disabled: false, timeindex: 2 },
                                { disabled: false, timeindex: 3 },
                                { disabled: false, timeindex: 4 }
                            ],
                            hourIndex: index,
                            hour: hourName
                        });
                    }

                    if (minutes >= 1 && minutes < 2) {
                        if (index < 10) {
                            hourName = `0${index}:00`;
                        } else {
                            hourName = `${index}:00`;
                        }
                        hoursDayList.push({
                            times: [
                                { disabled: true, timeindex: 1 },
                                { disabled: true, timeindex: 2 },
                                { disabled: false, timeindex: 3 },
                                { disabled: false, timeindex: 4 }
                            ],
                            hourIndex: index,
                            hour: hourName
                        });
                    }
                    if (3 > minutes && minutes >= 2) {
                        if (index < 10) {
                            hourName = `0${index}:00`;
                        } else {
                            hourName = `${index}:00`;
                        }
                        hoursDayList.push({
                            times: [
                                { disabled: true, timeindex: 1 },
                                { disabled: true, timeindex: 2 },
                                { disabled: true, timeindex: 3 },
                                { disabled: false, timeindex: 4 }
                            ],
                            hourIndex: index,
                            hour: hourName
                        });
                    }
                    if (4 > minutes && minutes >= 3) {
                        if (index < 10) {
                            hourName = `0${index}:00`;
                        } else {
                            hourName = `${index}:00`;
                        }
                        hoursDayList.push({
                            times: [
                                { disabled: true, timeindex: 1 },
                                { disabled: true, timeindex: 2 },
                                { disabled: true, timeindex: 3 },
                                { disabled: true, timeindex: 4 }
                            ],
                            hourIndex: index,
                            hour: hourName
                        });
                    }
                }
                if (index > hours) {
                    if (index < 10) {
                        hourName = `0${index}:00`;
                    } else {
                        hourName = `${index}:00`;
                    }
                    hoursDayList.push({
                        times: [
                            { disabled: false, timeindex: 1 },
                            { disabled: false, timeindex: 2 },
                            { disabled: false, timeindex: 3 },
                            { disabled: false, timeindex: 4 }
                        ],
                        hourIndex: index,
                        hour: hourName
                    });
                }
            }
            // this.widgetsContent.nativeElement.scrollLeft += (hours * 100)

            return hoursDayList;
        }
        for (let index = 0; index < 24; index++) {
            if (index < 10) {
                hourName = `0${index}:00`;
            } else {
                hourName = `${index}:00`;
            }
            hoursDayList.push({
                times: [
                    { disabled: false, timeindex: 1 },
                    { disabled: false, timeindex: 2 },
                    { disabled: false, timeindex: 3 },
                    { disabled: false, timeindex: 4 }
                ],
                hourIndex: index,
                hour: hourName
            });
        }
        return hoursDayList;
    }

    checkActive(timeSlot, hour) {
        let hourName = '';
        if (hour < 10) {
            hourName = `0`;
        }
        const selectDateIndex = +`${this.dayIndex}${hourName}${hour}${timeSlot.timeindex}`;
        if (selectDateIndex == this.activeDateRange.start || selectDateIndex == this.activeDateRange.end) {
            return true;
        }
        if (selectDateIndex > this.activeDateRange.start && selectDateIndex < this.activeDateRange.end) {
            return true;
        }
        // if(this.activeSingleTimeSlot.startDayIndex == this.activeSingleTimeSlot.endDayIndex ){
        //   if(this.activeSingleTimeSlot.startDayIndex == this.dayIndex && this.activeSingleTimeSlot.starthourIndex == hour && this.activeSingleTimeSlot.starttimeIndex == timeSlot.timeindex){
        //     return true;
        //   }
        //   if(this.activeSingleTimeSlot.endDayIndex == this.dayIndex && this.activeSingleTimeSlot.endhourIndex == hour && this.activeSingleTimeSlot.endtimeIndex == timeSlot.timeindex){
        //     return true;
        //   }
        //   if(this.activeSingleTimeSlot.endDayIndex == this.dayIndex &&
        //     this.activeSingleTimeSlot.endhourIndex >= hour && hour >= this.activeSingleTimeSlot.starthourIndex &&
        //     this.activeSingleTimeSlot.endtimeIndex >= timeSlot.timeindex && timeSlot.timeindex >= this.activeSingleTimeSlot.starttimeIndex ){
        //     return true;
        //   }
        // }

        // if(this.activeSingleTimeSlot.startDayIndex && this.activeSingleTimeSlot.endDayIndex && this.activeSingleTimeSlot.startDayIndex != this.activeSingleTimeSlot.endDayIndex){
        //   if(this.dayIndex<this.activeSingleTimeSlot.endDayIndex && this.dayIndex>this.activeSingleTimeSlot.startDayIndex){
        //     return true;
        //   }
        //   if(this.activeSingleTimeSlot.endDayIndex == this.dayIndex &&
        //     this.activeSingleTimeSlot.endhourIndex > hour && hour >= 0  ){
        //     return true;
        //   }
        //   if(this.activeSingleTimeSlot.startDayIndex == this.dayIndex &&
        //     24 > hour && hour > this.activeSingleTimeSlot.starthourIndex
        //    ){
        //     return true;
        //   }
        //   if(this.activeSingleTimeSlot.startDayIndex == this.dayIndex &&
        //      hour == this.activeSingleTimeSlot.starthourIndex &&
        //      timeSlot.timeindex >= this.activeSingleTimeSlot.starttimeIndex ){
        //     return true;
        //   }
        //   if(this.activeSingleTimeSlot.endDayIndex == this.dayIndex &&
        //     hour == this.activeSingleTimeSlot.endhourIndex &&
        //     timeSlot.timeindex <= this.activeSingleTimeSlot.endtimeIndex ){
        //    return true;
        //  }

        // }
        return false;
    }
    checkDisable(timeSlot, hour, checkPrev) {
        if (this.WorkingHoursConfigured && new Date(this.MaxDate).getTime() <= this.selectedDate.getTime()) return true;
        if (!this.WorkingHoursConfigured) return false;
        // console.log(timeSlot);
        // console.log(hour);
        let hourName = '';
        if (hour < 10) {
            hourName = `0`;
        }
        // const selectDateIndex = +`${this.dayIndex}${hourName}${hour}${timeSlot.timeindex}`;
        if (checkPrev) {
            // if(this.activeDateRange.start &&selectDateIndex < this.activeDateRange.start ){
            //   return true;
            // }
        }

        var date = new Date(this.MaxDate);

        const selectedDate = new Date(date);

        const oneDay = 1000 * 60 * 60 * 24;

        // A day in UTC always lasts 24 hours (unlike in other time formats)
        const start = Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
        const end = Date.UTC(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
        // so it's safe to divide by 24 hours
        const MaxdayIndex = (start - end) / oneDay;
        if (this.dayIndex > MaxdayIndex) {
            return true;
        }
        if (timeSlot?.disabled) {
            return timeSlot?.disabled;
        }
        if (!timeSlot?.disabled) {
            for (let index = 0; index < this.disabledHoursDayList?.length; index++) {
                const element = this.disabledHoursDayList[index];
                if (element?.hourIndex == hour) {
                    if (element?.times.findIndex((x) => x.timeindex === timeSlot?.timeindex) != -1) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
    curruntDate(dayIndex) {
        const today = new Date();
        const curruntDate = today.setDate(today.getDate() + dayIndex);
        return new Date(curruntDate);
    }
    checkDates(dateList) {
        this.spinner.emit(true);
        let endTime = dateList[1].time;
        if (endTime == '24:00') {
            endTime = '23:59';
        }

        this._baseService
            .get(
                `Bbooking/can-book-here/${this.bookingId}/${this._resourceId}/${dateList[0].date}/${dateList[1].date}/${dateList[0].time}/${endTime}`
            )
            .pipe(takeUntil(this.unsubscribeAll$), first())
            .subscribe((result) => {
                this.spinner.emit(false);

                if (result) {
                    // const newDates = {
                    //     startDate: this.listOfSelectedDates[0]?.date,
                    //     startTime: this.listOfSelectedDates[0]?.time,
                    //     endDate: this.listOfSelectedDates[1]?.date,
                    //     endTime: this.listOfSelectedDates[1]?.time
                    // };
                    // this.newDates.emit(newDates);
                    return;
                } else {
                    // debugger;
                    // false message
                    this.activeSingleTimeSlot.startDayIndex = null;
                    this.activeSingleTimeSlot.starthourIndex = null;
                    this.activeSingleTimeSlot.starttimeIndex = null;
                    this.activeSingleTimeSlot.endDayIndex = null;
                    this.activeSingleTimeSlot.endhourIndex = null;
                    this.activeSingleTimeSlot.endtimeIndex = null;
                    this.activeDateRange.start = null;
                    this.activeDateRange.end = null;
                    this.newDates.emit(null);
                    this.newMessage(100, '');
                }
            });
    }
    newMessage(id, action) {
        this._errorService.changeMessage({ id: id, action: action });
    }
    selectTimeSlot(times, hour) {
        if (this.checkDisable(times, hour.hourIndex, false)) {
            return;
        }
        const newDate = this.curruntDate(this.dayIndex);
        const newday = newDate.getDate();
        const newMonth = newDate.getMonth() + 1;
        const newYear = newDate.getFullYear();
        const startDate = newYear + '-' + newMonth + '-' + newday + 'T00:00:00';
        let fromVal = `${hour.hourIndex}:00`;
        let toVal = `${hour.hourIndex}:00`;
        let hourName = '';
        if (hour.hourIndex < 10) {
            hourName = `0`;
        }
        if (times?.timeindex == 1) {
            fromVal = `${hourName}${hour?.hourIndex}:00`;
            toVal = `${hourName}${hour?.hourIndex}:15`;
        }
        if (times?.timeindex == 2) {
            fromVal = `${hourName}${hour?.hourIndex}:15`;
            toVal = `${hourName}${hour?.hourIndex}:30`;
        }
        if (times?.timeindex == 3) {
            fromVal = `${hourName}${hour?.hourIndex}:30`;
            toVal = `${hourName}${hour?.hourIndex}:45`;
        }
        if (times?.timeindex == 4) {
            fromVal = `${hourName}${hour?.hourIndex}:45`;
            toVal = `${hourName}${hour?.hourIndex + 1}:00`;
        }
        const selectDateIndex = +`${this.dayIndex}${hourName}${hour.hourIndex}${times?.timeindex}`;

        if (this.listOfSelectedDates.length == 1) {
            if (this.activeDateRange.start > selectDateIndex) {
                // this.listOfSelectedDates = [];

                // this.activeDateRange.end = this.activeDateRange.start;
                // this.activeDateRange.start = selectDateIndex;
                // this.listOfSelectedDates.push({ date: startDate, time: fromVal });

                // this.listOfSelectedDates.push({
                //     date: this.listOfselectedTimeReverse.startDate,
                //     time: this.listOfselectedTimeReverse.startTimeE
                // });
                // this.activeSingleTimeSlot.endDayIndex = this.dayIndex;
                // this.activeSingleTimeSlot.endhourIndex = hour.hourIndex;
                // this.activeSingleTimeSlot.endtimeIndex = times.timeindex;
                // const newDates = {
                //     startDate: this.listOfSelectedDates[0].date,
                //     startTime: this.listOfSelectedDates[0].time,
                //     endDate: this.listOfSelectedDates[1].date,
                //     endTime: this.listOfSelectedDates[1].time
                // };
                // this.newDates.emit(newDates);
                // this.checkDates(this.listOfSelectedDates);

                return;
            } else {
                this.activeDateRange.end = selectDateIndex;
                // console.log(this.activeDateRange, 'Second');
                this.listOfSelectedDates.push({ date: startDate, time: toVal });
                this.activeSingleTimeSlot.endDayIndex = this.dayIndex;
                this.activeSingleTimeSlot.endhourIndex = hour.hourIndex;
                this.activeSingleTimeSlot.endtimeIndex = times?.timeindex;
                const newDates = {
                    startDate: this.listOfSelectedDates[0]?.date,
                    startTime: this.listOfSelectedDates[0]?.time,
                    endDate: this.listOfSelectedDates[1]?.date,
                    endTime: this.listOfSelectedDates[1]?.time
                };
                this.newDates.emit(newDates);
                this.checkDates(this.listOfSelectedDates);
            }
        } else {
            this.activeDateRange.start = selectDateIndex;
            this.activeDateRange.end = selectDateIndex;
            // console.log(this.activeDateRange, 'first');
            this.listOfSelectedDates = [];
            this.listOfSelectedDates.push({ date: startDate, time: fromVal });
            this.listOfselectedTimeReverse.startTimeE = toVal;
            this.listOfselectedTimeReverse.startTimeS = fromVal;
            this.listOfselectedTimeReverse.startDate = startDate;
            const newDates = {
                startDate: this.listOfSelectedDates[0].date,
                startTime: this.listOfSelectedDates[0].time,
                endDate: this.listOfSelectedDates[0]?.date
            };
            this.activeSingleTimeSlot.startDayIndex = this.dayIndex;
            this.activeSingleTimeSlot.endDayIndex = this.dayIndex;
            this.activeSingleTimeSlot.starthourIndex = hour.hourIndex;
            this.activeSingleTimeSlot.starttimeIndex = times?.timeindex;
            this.activeSingleTimeSlot.endhourIndex = null;
            this.activeSingleTimeSlot.endtimeIndex = null;
            this.newDates.emit(newDates);
        }
    }
    nextDayIndex() {
        this.dayIndex = this.dayIndex + 1;
        this.getDisabledDates(this.curruntDate(this.dayIndex));
    }
    prevDayIndex() {
        if (this.dayIndex == 0) {
            return;
        }
        this.dayIndex = this.dayIndex - 1;
        this.getDisabledDates(this.curruntDate(this.dayIndex));
    }
    getDisabledDates(currentDate) {
        if (!this.WorkingHoursConfigured) return;
        this.disabledHoursDayList = [];
        for (let index = 0; index < this.listOfBookedDates.length; index++) {
            const element = this.listOfBookedDates[index].day.substring(0, 10);
            const listOfDates = this.listOfBookedDates[index].restrictedTimeSlots;
            if (this.sameDay(currentDate, new Date(element))) {
                listOfDates.forEach((dateElement) => {
                    const startHour = +dateElement.substring(0, 2);
                    const startMin = +dateElement.substring(3, 5);
                    let endtHour = +dateElement.substring(dateElement.indexOf('-') + 2, dateElement.indexOf('-') + 4);
                    let endtMin = +dateElement.substring(dateElement.indexOf('-') + 5, dateElement.indexOf('-') + 7);
                    // if(endtHour == 0){
                    //   endtHour = 23;
                    //   endtMin = 60;

                    // }
                    if (endtMin == 59) {
                        endtMin = 60;
                    }
                    this.getdisabledHoursDayList(startHour, startMin, endtHour, endtMin);
                });
            }
        }
    }
    sameDay(selectionDate, disabledDate) {
        return (
            selectionDate.getFullYear() === disabledDate.getFullYear() &&
            selectionDate.getMonth() === disabledDate.getMonth() &&
            selectionDate.getDate() === disabledDate.getDate()
        );
    }
    getdisabledHoursDayList(startH, startMin, endH, endMin) {
        let times = [];
        if (startH != endH) {
            for (let index = startH; index < endH + 1; index++) {
                if (index == startH) {
                    times = this.getDisaledTimeList(startMin, 60);
                }
                if (index == endH) {
                    times = this.getDisaledTimeList(0, endMin);
                }
                if (index > startH && index < endH) {
                    times = this.getDisaledTimeList(0, 60);
                }
                this.disabledHoursDayList.push({ times: times, hourIndex: index });
            }
        }
        if (startH == endH) {
            times = this.getDisaledTimeList(startMin, endMin);
            this.disabledHoursDayList.push({ times: times, hourIndex: startH });
        }
        //  console.log('disabledHoursDayList');
        //  console.log(this.disabledHoursDayList);
    }
    scrollLeft() {
        this.widgetsContent.nativeElement.scrollLeft -= 200;
    }
    getDisaledTimeList(startMin, endMin) {
        const duration = (endMin - startMin) / 15;
        let durationStart = 0;
        let times = [];
        if (startMin == 0) {
            durationStart = 0;
            for (let index = durationStart; index < durationStart + duration; index++) {
                times.push({ disabled: false, timeindex: index + 1 });
            }
        }
        if (startMin == 15) {
            durationStart = 1;
            for (let index = durationStart; index < durationStart + duration; index++) {
                times.push({ disabled: false, timeindex: index + 1 });
            }
        }
        if (startMin == 30) {
            durationStart = 2;
            for (let index = durationStart; index < durationStart + duration; index++) {
                times.push({ disabled: false, timeindex: index + 1 });
            }
        }
        if (startMin == 45) {
            durationStart = 3;
            for (let index = durationStart; index < durationStart + duration; index++) {
                times.push({ disabled: false, timeindex: index + 1 });
            }
        }
        return times;
    }
    scrollRight() {
        this.widgetsContent.nativeElement.scrollLeft += 200;
    }
    dateChanged(selectedDateEvt) {
        this.selectedDate = new Date(selectedDateEvt);
        const selectedDate = new Date(selectedDateEvt);

        const oneDay = 1000 * 60 * 60 * 24;

        // A day in UTC always lasts 24 hours (unlike in other time formats)
        const start = Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
        const end = Date.UTC(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());

        // so it's safe to divide by 24 hours
        this.dayIndex = (start - end) / oneDay;
        this.getDisabledDates(this.curruntDate(this.dayIndex));

        return (start - end) / oneDay;
    }

    ngOnDestroy(): void {
        this.unsubscribeAll$.next(true);
        this.unsubscribeAll$.complete();
    }
}
