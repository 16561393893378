import { Component, OnInit, Output, EventEmitter, Input, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { ResourcesService } from '@shared/services/resources.service';
import { SSL_OP_ALLOW_UNSAFE_LEGACY_RENEGOTIATION } from 'constants';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-upload-img-file',
    templateUrl: './upload-img-file.component.html',
    styleUrls: ['./upload-img-file.component.scss']
})
export class UploadImgFileComponent implements OnInit, OnDestroy {
    private _unsubscribeAll$ = new Subject<boolean>();

    @Output('someEvent') someEvent = new EventEmitter();
    @Input('label') label: string;
    @Input('imgUrl') imgUrl: any;
    name = '';

    constructor(private _resourceService: ResourcesService) {}

    url1 = '';
    imgBase64 = '';
    fileToUpload = [];

    inputLabel = 'Choose file';
    dragout(event) {
        event.target.parentElement.classList.remove('dragover');
    }
    getImageURL(imageName) {
        return this._resourceService.getimageUrl(imageName);
    }
    dragin(event) {
        event.target.parentElement.classList.add('dragover');
    }
    ngOnInit() {}
    handleFileSelect(event) {
        this.fileToUpload = [];
        const files = event.target.files;
        this.name = files[0].name;
        let i;
        for (i = 0; i < files.length; i++) {
            const file = files[i];
            if (files && file) {
                const reader = new FileReader();
                this.fileToUpload.push(event.target.files[i]);
                reader.readAsDataURL(event.target.files[i]); // read file as data url
                this.upload();
                reader.onload = (event: any) => {
                    // called once readAsDataURL is completed
                    this.imgUrl = event.target.result;
                };
            }
        }
    }

    upload() {
        this._resourceService
            .uploadimage(this.fileToUpload[0])
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (result) => {
                    this.someEvent.emit(result.data);
                    this.imgUrl = result.data;
                    // console.log(this.imgUrl);
                },
                (error) => {}
            );
    }
    ngOnChanges(changes) {
        if (this.imgUrl) {
            this.url1 = this.imgUrl;
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
    }
}
