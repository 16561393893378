import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, subscribeOn } from 'rxjs/operators';
import { Result } from '../_models';
import { ADuser } from '@shared/_models/ADuser';
import { environment } from '@environment/environment';
import { observable } from 'rxjs';

@Injectable()
export class UsersService {
    private canonicalName: string;
    APIURL = '';
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;
    sManagerUrl = environment.sManagerUrl;
    constructor(private http: HttpClient) {
        const newurl = window.location.href;

        if (this.isOnPremise) this.APIURL = this.appUrl;
        else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
        }

        if (this.APIURL.search('localhost') > -1) {
            // this.APIURL = 'https://main.api.sharewinds.com/api/v1/';
            this.APIURL = environment.localHostUrl;
        }
        //    this.APIURL = 'http://localhost:56622/api/v1/';
        // this.APIURL = 'https://localhost:44342/api/v1/';
        //this.APIURL = 'https://Long2.api.sharewinds.com/api/v1/';
    }
    getSubsUserCount() {
        return this.http.get<Result>(`${this.APIURL}security/suser/GetSubsCount`);
    }
    getListOfUsers() {
        return this.http.get<Result>(`${this.APIURL}security/suser/get-allUsers`);
    }
    getListOfUsersExcludeSelected(userId) {
        return this.http.get<Result>(`${this.APIURL}security/suser/get-allUsers-exclude-selected/` + userId);
    }
    getContacts() {
        return this.http.get<Result>(`${this.APIURL}security/suser/get-contacts`);
    }
    getcontactById(contactId: string) {
        return this.http.get<Result>(`${this.APIURL}security/suser/getcontact-byid/` + contactId);
    }
    createPassword(user: any) {
        return this.http.post<Result>(`${this.APIURL}security/suser/add-contactUser/`, user);
    }

    addContact(contact: any) {
        return this.http.post<Result>(`${this.APIURL}security/suser/add-Contact`, contact);
    }
    updateContact(contact: any) {
        return this.http.post<Result>(`${this.APIURL}security/suser/edit-Contact`, contact);
        //  return this.http.post<Result>(`https://localhost:44342/api/v1/security/suser/edit-Contact`, contact);
    }
    ActivateContact(contact: any) {
        return this.http.post<Result>(`${this.APIURL}security/suser/edit-Contact-by-email`, contact);
    }
    getAllApplication() {
        return this.http.get<Result>(`${this.APIURL}security/suser/get-allUsers`);
    }
    getAllReports() {
        return this.http.get<Result>(`${this.APIURL}security/suser/get-allUsers`);
    }
    getPermissionTypes() {
        return this.http.get<Result>(`${this.APIURL}security/suser/get-permissiontypes`);
    }
    deleteusers(contact: any) {
        return this.http.post<Result>(`${this.APIURL}security/suser/delete-Users`, contact);
    }
    deleteUsersResourceSupervisor(user: any) {
        return this.http.post<Result>(`${this.APIURL}security/suser/delete-Users-Resource-Supervisor`, user);
    }
    ChangeResourceSupervisor(userId, Res: any) {
        return this.http.post<Result>(`${this.APIURL}Asset/Change-Resource-Supervisor/` + userId, Res);
    }
    deletecontacts(contact: any) {
        return this.http.post<Result>(`${this.APIURL}security/suser/delete-Contacts`, contact);
    }

    GetActiveDirectoryGroups() {
        return this.http.get<Result>(`${this.APIURL}security/SUser/GetAllADGroups`);
        //return this.http.get<Result>(`https://localhost:44342/api/v1/security/SUser/GetAllADGroups`);
    }
    GetActiveDirectoryGroupsNoCache() {
        return this.http.get<Result>(`${this.APIURL}security/SUser/GetAllADGroups?noCache=true`);
        //return this.http.get<Result>(`https://localhost:44342/api/v1/security/SUser/GetAllADGroups?noCache=true`);
    }

    GetActiveDirectoryUsers(groups: any) {
        return this.http.post<Result>(`${this.APIURL}security/SUser/GetAllADGroupsUsers`, groups);
        // return this.http.post<Result>(`https://localhost:44342/api/v1/security/SUser/GetAllADGroupsUsers`, groups);
    }
    GetActiveDirectoryUsersNoCache(groups: any) {
        return this.http.post<Result>(`${this.APIURL}security/SUser/GetAllADGroupsUsers?noCache=true`, groups);
        //return this.http.post<Result>(`https://localhost:44342/api/v1/security/SUser/GetAllADGroupsUsers?noCache=true`, groups);
    }

    synchronous(ADUsersToSync: ADuser[], GrantAdminRoleFlag: boolean) {
        var Qterm = '';
        if (GrantAdminRoleFlag === true) Qterm = '?GrantAdminRoleFlag=true';
        return this.http.post<Result>(`${this.APIURL}security/SUser/SyncADusers` + Qterm, ADUsersToSync);
        //  return this.http.post<Result>(`https://localhost:44342/api/v1/security/SUser/SyncADusers` + Qterm , ADUsersToSync);
    }

    GetRejectionComment(bookingId, contactId) {
        return this.http.get<Result>(`${this.APIURL}BBooking/GetRejectionComment/` + bookingId + '/' + contactId);
        //return this.http.get<Result>(`https://localhost:44342/api/v1/security/SUser/GetAllADGroups?noCache=true`);
    }

    _transformer = (node: any, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level
        };
    };

    // localization

    getListOfLocalizations(masterItemId, locationname) {
        return this.http.get<Result>(
            `${this.APIURL}Localization/getTranslationsforitem/${masterItemId}/${locationname}`
        );
    }
    GetLanguages() {
        return this.http.get<Result>(`${this.APIURL}Localization/GetLanguages`);
    }
    GetCurrencies() {
        return this.http.get<Result>(`${this.APIURL}Localization/GetCurrencies`);
    }
    GetCulturesbyclut(culture) {
        return this.http.get<Result>(`${this.APIURL}Localization/GetCultures/` + culture);
    }

    GetCultures() {
        return this.http.get<Result>(`${this.APIURL}Localization/GetCultures/`);
        //return this.http.get<any>(`${this.APIURL}Localization/GetCultures/`);
    }
    GetTranslations() {
        return this.http.get<Result>(`${this.APIURL}Localization/GetTranslations`);
    }
    addLocalization(Localization) {
        return this.http.post<Result>(`${this.APIURL}Localization/Add-Translation`, Localization);
    }

    addLocalizationLst(Localization) {
        return this.http.post<Result>(`${this.APIURL}Localization/Add-TranslationLst`, Localization);
    }
    // send localizationIds
    deleteLocalizationLst(Localization) {
        return this.http.post<Result>(`${this.APIURL}Localization/Delete-Translations`, Localization);
    }
    getLocationType() {
        return this.http.get<Result>(`${this.APIURL}Location/GetLocationType`);
    }
    getLocationParents() {
        return this.http.get<Result>(`${this.APIURL}Location/GetLocationParents`);
    }
    isMainAccount(userId) {

        if (this.isOnPremise) {
            return this.http.get<Result>(`${this.APIURL}BName/get-all-names`); // fake to pass on onprimes
        } else
            return this.http.get<Result>(`${this.sManagerUrl}/api/Subs/canDelete/${userId}`);
    }

    getprerefrencebyid(id) {
        return this.http.get<Result>(`${this.APIURL}Preferences/` + id);
    }
    getAllPg() {
        return this.http.get<Result>(`${this.APIURL}Preferences/GetAllPg`);
    }

    updateRangePrefById(data: any) {
        return this.http.post<Result>(`${this.APIURL}Preferences/update-prefRange`, data);
    }
}
