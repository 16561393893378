import { SheetFileType, SheetDetail } from './';

export class SheetHeader {
    sheetHeaderId :string;
    presetId :string;
    sheetName :string;
    sheetFileTypeId: number = 1;
    separator :string;
    dbTableName :string;
    isCrosstab:boolean=false;
    isActive:boolean=true;
    sheetFileType: SheetFileType;
    isSaved: boolean = false;
    foreignSheetNeeded: string = '';
    sheetDetail : SheetDetail[] = [];        
    importType: string;  
}
