import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import swal from 'sweetalert2';
import { MatSort } from '@angular/material/sort';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { UsersService, ErrorService } from '@shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ResourcesService } from '@shared/services/resources.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-contact-mf',
    templateUrl: './contact-mf.component.html',
    styleUrls: ['./contact-mf.component.scss']
})
export class ContactMFComponent implements OnInit, OnDestroy {
    items: MenuItem[];
    private _messageService: any;
    constructor(
        private translate: TranslateService,
        public router: Router,
        public route: ActivatedRoute,
        private modalService: BsModalService,
        private _resourceService: ResourcesService,
        private fb: FormBuilder,
        private _errorService: ErrorService,
        private _usersService: UsersService
    ) { }
    private _unsubscribeAll$ = new Subject<boolean>();
    loading = true;
    searchValue = '';
    fruits: any[] = [];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    formType = 'add';
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    changeNewContant = false;
    laddaSearchProgress: boolean | number = false;
    @Input('masterFile')
    masterFile = true;

    @Input('newContact')
    newContact = false;
    @Input('multiselect')
    multiselect = true;
    @Input('contactsHaveUser')
    contactsHaveUser = false;

    @Input('disabledEdit')
    disabledEdit = false;

    @Output('selectedContacts')
    selectedContacts: EventEmitter<any> = new EventEmitter<any>();

    UserList = [];

    @ViewChild('formAddEditModal', { static: true }) formModal: TemplateRef<any>;
    modalRef: BsModalRef | null;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    displayedColumns: string[] = ['select', 'fullName', 'email', 'isvalid'];
    dataSource = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);
    currentContact;
    numberOnly(event): boolean {
        //if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57))
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && charCode != 43 && (charCode < 48 || charCode > 57)) {
            return false;
        }

        return true;
    }
    contactForm: FormGroup;
    saveContant = false;
    contactId = '1';
    ImgLabel = 'TumbnailPhoto';
    selectedcontact: any;
    activeStyleUserTable: '';
    selectedStatus = 'Active';
    statusList = [{ name: 'All' }, { name: 'Active' }, { name: 'Inactive' }];
    actionStatus = null;
    actionsList = [{ name: 'Action' }, { name: 'Activate' }, { name: 'Delete' }, { name: 'Inactivate' }];
    selectAction() {
        if (this.actionStatus === 'Activate') {
            this.changeStatus('A');
        }
        if (this.actionStatus === 'Delete') {
            this.delete();
        }
        if (this.actionStatus === 'Inactivate') {
            this.changeStatus('NA');
        }
        this.actionStatus = null;
    }
    selectitem(row) {
        this.ShowValidation = false;
        this.activeStyleUserTable = row.contactId;
        this.selection = row;
        this.selectedcontact = row;
    }
    applyfilterStatus() {
        if (this.selectedStatus === 'Active') {
            this.filterStatus(true);
        }
        if (this.selectedStatus === 'Inactive') {
            this.filterStatus(false);
        }
        if (this.selectedStatus === 'All') {
            this.filterStatus('');
        }
    }
    // subscription: Subscription = new Subscription();

    ngOnDestroy() {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
        // this.subscription.unsubscribe();
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }
    EditContact(row) {
        if (this.disabledEdit == true)
            return;

        this.formType = 'edit';
        this.initForm();
        this.getContactById(row.contactId);
        this.openModal();
    }
    AddNewItem() {
        this.formType = 'add';
        this.initForm();
        this.openModal();
    }
    actionMsg(data) {
        if (data !== '') {
            this[data]();
            this._errorService.action('');
        }
    }
    newMessage(id, action) {
        this._errorService.changeMessage({ id: id, action: action });
    }
    remove(fruit): void {
        const index = this.fruits.indexOf(fruit);

        if (index >= 0) {
            this.fruits.splice(index, 1);
            this.filterdatasource(this.fruits);
        }
    }
    addfilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        // Add our fruit
        if ((value || '').trim()) {
            this.fruits.push({ name: value.trim() });
            this.filterdatasource(this.fruits);
        }

        if (input) {
            input.value = '';
        }
    }
    //   filterdatasource(array) {
    //     this.dataSource = new MatTableDataSource(this.UserList);
    //       this.dataSource.sort = this.sort;
    //     array.forEach(element => {
    //       this.dataSource.filter = element.name.trim().toLowerCase();
    // });
    //   }
    RemoveSelected() {
        if (this.selection.selected.length == 0) {
            this.newMessage(60, 'error');
        } else {
            // this.newMessage(9, 'delete');
            this.newMessage(155, 'delete');
        }
    }
    arrayDiffLists(a, b, id) {
        const bIds = {};
        b.forEach(function (obj) {
            bIds[obj[id]] = obj;
        });
        // Return all elements in A, unless in B
        return a.filter(function (obj) {
            return !(obj[id] in bIds);
        });
    }
    filterdatasource(array) {
        this.dataSource = new MatTableDataSource(this.UserList);
        if (array.length > 0) {
            let filteredLst = [];
            const resetsearch = '';
            array.forEach((element) => {
                this.dataSource.filter = element.name.trim().toLowerCase();
                const newlst = this.arrayDiffLists(this.dataSource.filteredData, filteredLst, 'contactId');
                filteredLst = filteredLst.concat(newlst);
                this.dataSource.filter = resetsearch.trim().toLowerCase();
            });
            this.dataSource = new MatTableDataSource(filteredLst);
        }
        this.dataSource.sort = this.sort;
    }
    openModal() {
        const configs = {
            keyboard: false,
            ignoreBackdropClick: true,
            class: ' modal-lg modal-primary ModelLayout commenModel animated fadeInRight',
            animated: false
        };
        this.modalRef = this.modalService.show(this.formModal, configs);
    }
    closeAddEditModal() {
        if (!this.changeNewContant) {
            this.newContact = false;
            this.modalRef.hide();
        } else this.newMessage(144, 'ClosePopup');
    }
    ClosePopup() {
        this.newContact = false;
        this.modalRef.hide();
    }

    filterStatus(status) {
        const newList = [];
        this.dataSource = new MatTableDataSource(this.UserList);

        if (status === '') {
        } else {
            this.dataSource.filteredData.forEach((element) => {
                if (element.isvalid === status) {
                    newList.push(element);
                }
            });
            this.dataSource = new MatTableDataSource(newList);
        }
        this.dataSource.sort = this.sort;

        this.applyFilter(this.searchValue);
    }
    getContactById(contactId) {
        this._usersService
            .getcontactById(contactId)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    this.currentContact = result.data;
                    const controls = this.contactForm.controls;
                    Object.keys(controls).forEach((key) => {
                        if (this.currentContact[key] != null) {
                            this.contactForm.get(key).setValue(this.currentContact[key]);
                        }
                        this.changeNewContant = false;
                    });
                },
                (error) => { }
            );
    }

    ngOnInit() {
        this.items = [
            // {label: 'Update', icon: 'pi pi-refresh', command: () => {
            //     this.update();
            // }},
            {
                label: this.translate.instant('Delete'),
                icon: 'pi pi-times',
                command: () => {
                    this.RemoveSelected();
                }
            }
            // {label: 'Angular.io', icon: 'pi pi-info', url: 'http://angular.io'},
            // {separator:true},
            // {label: 'Setup', icon: 'pi pi-cog', routerLink: ['/setup']}
        ];

        this.getContactList();
        this.initForm();

        this.contactForm.valueChanges.pipe(takeUntil(this._unsubscribeAll$)).subscribe((changes) => {
            this.changeNewContant = true;
        });
        this._errorService.currentAction
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((action) => this.actionMsg(action));
    }

    getContactList() {
        //   this.searchValue = '';
        this._usersService
            .getContacts()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                if (this.contactsHaveUser) {
                    this.UserList = result.data.filter((contact) => !!contact.userName);
                } else this.UserList = result.data;
                if (!this.masterFile) {
                    this.UserList = this.UserList.filter((x) => (x.isvalid = true));
                }
                this.dataSource = new MatTableDataSource(this.UserList);
                this.dataSource.sort = this.sort;
                this.loading = false;
                //  this.applyfilterStatus();
            });
    }

    ngOnChanges() {
        if (!this.multiselect) {
            this.displayedColumns.splice(0, 1);
        }
    }
    ShowValidation = false;

    changeContant() {
        this.ShowValidation = true;
        if (this.multiselect) {
            this.selectedContacts.emit(this.selection.selected);
        } else {
            this.selectedContacts.emit(this.selectedcontact);
        }
    }
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
        this.selectedContacts.emit(this.selection.selected);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    private initForm(): void {
        this.contactForm = this.fb.group({
            contactId: new FormControl(null),
            firstName: new FormControl(null, [Validators.required]),
            lastName: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required]),
            phone: new FormControl(null),
            company: new FormControl(null),
            title: new FormControl(null, [Validators.required]),
            mobile: new FormControl(null, [Validators.required]),
            isUser: new FormControl(false),
            photo: new FormControl(null),
            isvalid: new FormControl(true)
        });
    }
    setTumbnail(data) {
        this.contactForm.get('photo').setValue(data);
    }
    setFileName(data) {
        this.contactForm.get('photo').setValue(data);
    }

    setSelecttesContacts(newContacts) {
        newContacts.forEach((item) => {
            if (item != null) {
                this.UserList.push(item);
            }
            this.dataSource = new MatTableDataSource(this.UserList);
            this.selectedContacts.emit(newContacts);
        });
    }
    saveAddEditContact() {
        this.laddaSearchProgress = 1;
        const controls = this.contactForm.controls;
        Object.keys(controls).forEach((key) => {
            controls[key].markAsTouched();
        });

        if (this.contactForm.status === 'VALID') {
            this.saveContant = false;
            if (this.contactForm.value.contactId == null) {
                this._usersService
                    .addContact(this.contactForm.value)
                    .pipe(
                        takeUntil(this._unsubscribeAll$),
                        first(),
                        finalize(() => (this.laddaSearchProgress = false))
                    )
                    .subscribe(
                        (result) => {
                            if (result.isSuccess) {
                                // const dgwData = result.data;
                                this.newMessage(93, '');
                                this.changeNewContant = false;
                                this.closeAddEditModal();
                            } else if (result.errorCode == 113) {
                                this.newMessage(113, 'ActivateContact');
                            } else {
                                this.newMessage(result.errorCode, '');
                            }
                            this.getContactList();
                        },
                        (error) => {
                            this.laddaSearchProgress = false;
                        }
                    );
            } else {
                this._usersService
                    .updateContact(this.contactForm.value)
                    .pipe(
                        takeUntil(this._unsubscribeAll$),
                        first(),
                        finalize(() => (this.laddaSearchProgress = false))
                    )
                    .subscribe(
                        (result) => {
                            if (result.isSuccess) {
                                // const dgwData = result.data;
                                this.newMessage(4, '');
                                this.changeNewContant = false;
                                this.closeAddEditModal();
                            } else if (result.errorCode == 113) {
                                this.newMessage(113, 'ActivateContact');
                            } else {
                                this.newMessage(result.errorCode, '');
                            }
                            this.getContactList();
                            this.laddaSearchProgress = false;
                            this.changeNewContant = false;
                            this.saveContant = false;
                        },
                        (error) => {
                            this.laddaSearchProgress = false;
                        }
                    );
                this.selectedContacts.emit(this.UserList);
            }
        } else {
            this.saveContant = true;
            window.scrollTo(0, 0);
            this.laddaSearchProgress = false;
            this.newMessage(3, '');
        }
        // this.modalRef.hide();
    }
    ActivateContact() {
        this._usersService
            .ActivateContact(this.contactForm.value)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    if (result.isSuccess) {
                        // const dgwData = result.data;
                        this.newMessage(4, '');
                        this.changeNewContant = false;
                        this.closeAddEditModal();
                    } else {
                        this.newMessage(result.errorCode, '');
                    }
                    this.getContactList();
                    this.laddaSearchProgress = false;
                    this.changeNewContant = false;
                    this.saveContant = false;
                },
                (error) => {
                    this.laddaSearchProgress = false;
                }
            );
    }
    delete() {
        this._usersService
            .deletecontacts(this.selection.selected)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    if (result.isSuccess) {
                        // this.newMessage(103, '');
                        this.newMessage(154, '');
                    } else {
                        this.newMessage(55, '');
                    }
                    this.selection = new SelectionModel(true, []);
                    this.getContactList();
                },
                (error) => { }
            );
    }

    changeStatus(data) { }
    removeAll() {
        this.fruits = [];
        this.dataSource = new MatTableDataSource(this.UserList);
        this.dataSource.sort = this.sort;
    }
}
