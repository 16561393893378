import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, finalize, first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseService, SecurityService } from '../services';
import * as moment from 'moment';
// import { MatSnackBar } from '@angular/material';
// import { SnackbarComponent } from '../containers/default-layout/snackbar/snackbar/snackbar.component';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    alerts: any[] = [
        {
            type: '',
            msg: ``,
            timeout: 1
        }
    ];
    dangerAlert = false;
    count = 0;
    visitortimezone;
    constructor(private spinner: NgxSpinnerService, private _SecSer: SecurityService) {}
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<
        HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any
    > {
        // console.log(request.url);
        // if (request.url.indexOf('get-availableResources') != -1 )
        //            this.spinner.show('hem');

        this.count++;
        var visitortime = new Date();

        var offset = -visitortime.getTimezoneOffset() / 60.0;
        offset;
        if (offset > 0) this.visitortimezone = 'UTC +' + offset;
        else {
            this.visitortimezone = offset;
        }
        var from = new Date();
        var Zone = moment(from).format('Z');
        // add authorization header with jwt token if available
        const securityObject = JSON.parse(localStorage.getItem('securityObject'));
        if (securityObject && securityObject.bearerToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${securityObject.bearerToken}`,
                    timezone: 'UTC ' + Zone,
                    'x-sId': localStorage.getItem('x-sId') ? localStorage.getItem('x-sId') : ''
                }
            });
        }

        return next
            .handle(request)
            .pipe(
                catchError((err) => {
                    this.count = 0;
                    if (err instanceof HttpErrorResponse) {
                        if (request.url.indexOf('get-availableResources') != -1) this.spinner.hide('hem');

                        if (err.status === 401) {
                            window.location.href = '#/login';
                            this.count = 0;
                            this.spinner.hide('hem');
                        } else {
                            // this.spinner.hide('hem')
                        }
                        return throwError(() => err);
                    }
                })
            )
            .pipe(
                tap(
                    (event) => {
                        //console.log("From intercept"); console.log(event);
                        // this.spinner.hide('hem')
                    },
                    (error) => {
                        // console.log("From intercept ERR"); console.log(error);
                        //this.spinner.hide('hem')
                        if (request.url.indexOf('get-availableResources') != -1) this.spinner.hide('hem');
                    }
                ),
                finalize(() => {
                    if (this.count > 0) {
                        this.count--;
                    }
                    if (this.count == 0) {
                    }
                    if (request.url.indexOf('get-availableResources') != -1) this.spinner.hide('hem');
                })
            );
    }

    // openSnackBar(message: string, action: string) {
    //   action='Close'
    //  let snackBar= this._snackBar.openFromComponent(SnackbarComponent,  {
    //   data:message,
    //   duration: 7000,
    //   verticalPosition: 'top',
    //   panelClass: 'pizza-party'
    //   });
    //   snackBar.afterDismissed().subscribe(() => {
    //     //console.log('The snack-bar was dismissed');
    //   });

    //   snackBar.onAction().subscribe(() => {
    //     //console.log('The snack-bar action was triggered!');
    //     this._snackBar.dismiss()
    //   });
    // }
}
