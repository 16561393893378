import { ComboData } from ".";
import { Validators } from '@angular/forms';
import { MappingData } from './Mapping-data';
export class SheetDetail {
    sheetDetailId: number;
    sheetHeaderId: string;
    dbColumnName: string;
    userInfo: ComboData;
    displayColumnName: string;
    sheetFieldName: string;
    isSelected: boolean=true;
    isSequence: boolean;
    isPrimaryKey: boolean;
    isMemberUniqueDataGroup: boolean;
    uniqueDataGroupName: string;
    isUniqueIdentifier: boolean;
    foreignKey: string;
    foreignKeyParentTable: string;
    allowNull: boolean;
    isActive: boolean;
    dataType: string;  
    customValue: string;
    dataLength: number; 
    MappingData : MappingData;
}