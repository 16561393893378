export class Service {
    boughtBy: string;
    city: string;
    country: string;
    email: string;
    fax: string;
    homePhone: string;
    inDate: Date;
    line: string;
    make: string;
    milage: number;
    mobile: string;
    model: string;
    officeTelephone: string;
    outDate: Date;
    ownerFullName: string;
    ownerPrefix: string;
    ownerRelation: string;
    poBox: string;
    postalCode: string;
    problem: string;
    registrationNumber: string;
    serviceAdvisor: string;
    serviceCenter: string;
    vin: string;
   
}