import { TranslateLoader } from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { Injector, inject } from '@angular/core';
import { BaseService } from './shared';

export class CustomLoader implements TranslateLoader {
    constructor(private http: HttpClient) { }
    baseService = inject(BaseService);
    getTranslation(langCountry: string): Observable<any> {
        const UserLangId = localStorage.getItem('userLanguage') || '4c7d3ec0-e0e8-467d-b1ab-777c5c153644';
        return this.baseService.get(`Localization/GetCultTransObjectDirect/${UserLangId}`).pipe(
            switchMap((result) => {
                if (result.isSuccess) {
                    return of(result.data);
                } else return this.http.get('/assets/i18n/' + langCountry + '.json');
            }),
            finalize(() => {
                setTimeout(() => {
                    this.baseService.finishedLoadingTranslation.next(true);
                }, 100);
            })
        );
    }
}
