import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Result } from '../_models';
import { environment } from '@environment/environment';

@Injectable()
export class TreeService {
    private canonicalName: string;
    APIURL = '';
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;
    constructor(private http: HttpClient) {
        const newurl = window.location.href;

        if (this.isOnPremise) this.APIURL = this.appUrl;
        else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
        }

        if (this.APIURL.search('localhost') > -1) {
            // this.APIURL = 'https://main.api.sharewinds.com/api/v1/';
            this.APIURL = environment.localHostUrl;
        }
        //  this.APIURL = 'http://localhost:56622/api/v1/';
    }

    _transformer = (node: any, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level
        };
    };
    getTreeCategory() {
        return this.http.get<Result>(`${this.APIURL}Asset/GetResourceCategoriesTree`);
    }

    getAllCategory(resourceCategoryId, timeSlotsType = 'Open'  ) {
        var param = '?timeSlotType=' + timeSlotsType;
        return this.http.get<Result>(`${this.APIURL}Asset/GetResources-byCategory/` + resourceCategoryId + param);
    }

    addLocation(locationform) {
        return this.http.post<Result>(`${this.APIURL}Location/add-location`, locationform);
    }

    editLocation(locationform) {
        return this.http.post<Result>(`${this.APIURL}Location/edit-location`, locationform);
    }
    deletelocations(ids) {
        return this.http.post<Result>(`${this.APIURL}Location/delete-locations`, ids);
    }
    addCategory(categoryform) {
        return this.http.post<Result>(`${this.APIURL}Asset/add-resourceCategory`, categoryform);
    }

    editCategory(categoryform) {
        return this.http.post<Result>(`${this.APIURL}Asset/update-resourceCategory`, categoryform);
    }

    deleteresourcecategories(ids) {
        return this.http.post<Result>(`${this.APIURL}Asset/delete-resourceCategories`, ids);
    }
    deleteresourcecategoriesAndMove(id1: any, id2) {
        return this.http.post<Result>(`${this.APIURL}Asset/delete-AndMoveToresourceCategories/` + id2, id1);
    }
    getTreeLocation() {
        return this.http.get<Result>(`${this.APIURL}Location/GetLocationTree`);
    }
    getlocationdetails(locationId) {
        return this.http.get<Result>(`${this.APIURL}Location/Get-LocationDetails/` + locationId);
    }

    Getlocationbyid(locationId) {
        return this.http.get<Result>(`${this.APIURL}Location/Getbyid/` + locationId);
    }

    GetResourcecatbyId(catid) {
        return this.http.get<Result>(`${this.APIURL}Asset/GetResourceCatbyId/` + catid);
    }
}
