import { RowDto } from './';
import { ValidationDto } from './';

export class ColumnDto {

    columnId: number;
    rowId: number;
    columnName: string;
    columnValue: string;
    row: RowDto;
    validationsDto: ValidationDto[]=[];
    
}