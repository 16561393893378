import { City } from './city';
import { Country } from './country';

export class Branch {
    branchId: string;
    branchName: string;
    cityText: string;
    countryId: string;
    countryName: string;
    companyName: string;
    cityName: string;    
    cityId: string;  
    valid: boolean;
    city: City;
    country: Country;
}