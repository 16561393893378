import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Result } from '../_models';
import { environment } from '../../../environments/environment';
@Injectable()
export class AuthenticationService {
    private canonicalName: string;
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;
    APIURL = '';
    constructor(private http: HttpClient) {
        const newurl = window.location.href;

        if (this.isOnPremise) this.APIURL = this.appUrl;
        else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
        }

        if (this.APIURL.search('localhost') > -1) {
            //this.APIURL = 'https://main.api.sharewinds.com/api/v1/';
            this.APIURL = environment.localHostUrl;
        }
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('canonicalName');
    }

    getLookUp(key?: string) {
        return this.http.get<any>(`${this.APIURL}lookup/get-menuItems`);
    }
}
