import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Result } from '../_models';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RoleService {
    APIURL = '';
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;
    private canonicalName: string;
    constructor(private http: HttpClient) {
        const newurl = window.location.href;

        if (this.isOnPremise) this.APIURL = this.appUrl;
        else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
        }

        if (this.APIURL.search('localhost') > -1) {
            // this.APIURL = 'https://main.api.sharewinds.com/api/v1/';
            this.APIURL = environment.localHostUrl;
        }

        // this.APIURL = 'http://localhost:56622/api/v1/';
    }

    getCapacityById(id) {
        return this.http.get<Result>(`${this.APIURL}BSign/GetCapacity/` + id);
    }
    addRole(form) {
        return this.http.post<Result>(`${this.APIURL}Role/add`, form);
    }

    editRole(form) {
        return this.http.put<Result>(`${this.APIURL}Role`, form);
    }

    getAll() {
        return this.http.get<Result>(`${this.APIURL}Role`);
    }
    getAllActive() {
        return this.http.get<Result>(`${this.APIURL}Role/getactive`);
    }
    getById(id) {
        return this.http.get<Result>(`${this.APIURL}Role/get/` + id);
    }
    deleteList(data) {
        return this.http.put<Result>(`${this.APIURL}Role/delete-by-transaction`, data);
    }
    ChangeStatus(status, data) {
        return this.http.put<Result>(`${this.APIURL}Role/change-status/` + status, data);
    }
}
