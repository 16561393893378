export class BookingCalendar {
    FromTime: Date;
    ToTime: Date;
    ResourceId: string;
}

export class BookingCalendarMultiResources {
    FromTime: Date;
    ToTime: Date;
    ResIds: any[];
}
