import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, ViewChild, Output, EventEmitter, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BaseService, LanguageService, TreeService } from '@shared/services';
import { MatSort } from '@angular/material/sort';
import { first, takeUntil } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { ResourcesService } from '@shared/services/resources.service';
import { Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

export class TodoItemNode {
    childs: TodoItemNode[];
    locationName: string;
    locationId: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
    item: string;
    id: string;
    level: number;
    expandable: boolean;
    childs: TodoItemNode[];
}
/**
 * Food data with nested structure.
 * Each node has a name and an optional list of childs.
 */

/** Flat node with expandable and level information */

/**
 * @title Tree with flat nodes
 */
@Component({
    selector: 'app-search-location-checkbox-tree',
    templateUrl: './search-location-checkbox-tree.component.html',
    styleUrls: ['./search-location-checkbox-tree.component.scss']
})
export class SearchLocationCheckboxTreeComponent implements OnInit, OnDestroy {
    private _unsubscribeAll$ = new Subject<boolean>();

    activeStyleUserTable: any;
    allData: any[] = [];
    tableLoaded = true;
    allLocations = [];
    relatedResourcesFromCurrentSelected = [];
    constructor(
        public router: Router,
        private langservice: LanguageService,
        private _treeService: TreeService,
        private _resourceservice: ResourcesService,
        private baseService: BaseService
    ) {
        if (this.IsRole == true || this.isForReports == true) {
            this.currSelectedRess = [];
            this.selection = new SelectionModel(true);
            this.selectedResource = new EventEmitter<any>();
            this.selectedResources = new EventEmitter<any>();
        }
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    }

    dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

    flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
    nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
    selectedParent: TodoItemFlatNode | null = null;
    newItemName = '';
    treeControl: FlatTreeControl<TodoItemFlatNode>;

    treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
    loaded = false;
    resourcesLoaded = false;
    checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
    getLevel = (node: TodoItemFlatNode) => node.level;

    isExpandable = (node: TodoItemFlatNode) => node.expandable;

    getChildren = (node: TodoItemNode): TodoItemNode[] => node.childs;

    hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

    hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: TodoItemNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode =
            existingNode && existingNode.item === node.locationName ? existingNode : new TodoItemFlatNode();
        flatNode.item = node.locationName;
        flatNode.id = node.locationId;
        flatNode.level = level;
        flatNode.expandable = !!node.childs?.length;
        flatNode.childs = node.childs;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    };

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every((child) => {
                return this.checklistSelection.isSelected(child);
            });
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some((child) => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    descendantsPartiallySelectedAndNotChecked(node: TodoItemFlatNode) {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some((child) => this.checklistSelection.isSelected(child));
        const checkedResult = this.checkIsSelected(node);
        return result && !checkedResult;
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggle(node: TodoItemFlatNode): void {
        this.selection.clear();
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.forEach((child) => this.checklistSelection.isSelected(child));
        // this.checkAllParentsSelection(node);
        this.tableData.data = [];
        this.allData = [];
        if (this.checklistSelection?.selected?.length > 0) {
            this.getResourcesByLocations(this.checklistSelection?.selected);
        }
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
        this.selection.clear();

        this.checklistSelection.toggle(node);
        // this.checkAllParentsSelection(node);
        // console.log(this.checklistSelection.selected);
        this.tableData.data = [];
        this.allData = [];
        if (this.checklistSelection?.selected?.length > 0) {
            this.getResourcesByLocations(this.checklistSelection?.selected);
        }
    }

    private getResourcesByLocations(array) {
        array?.forEach((location) => {
            const categoryResources = this.allLocations.filter(
                (locationItem) => locationItem.defaultLocationId === location.id
            );
            this.allData.push(...categoryResources);
            this.tableData = new MatTableDataSource(this.allData);
            this.selectedLocations.emit(this.checklistSelection.selected);

            this.tableData.data.forEach((row) => {
                if (this.isForReports) this.selection.select(row);
            });

            if (
                this.rememberSelection &&
                this.tableData.data &&
                this.tableData.data.length > 0 &&
                this.currSelectedRess &&
                this.currSelectedRess.length > 0
            ) {
                this.currSelectedRess.forEach((r) => {
                    this.tableData.data.forEach((row) => {
                        if (r.resourceId == row.resourceId) this.selection.select(row);
                    });
                });
            }
        });
    }

    private getResourcesByLocationsAndSelectCurrent(array) {
        array?.forEach((location) => {
            const categoryResources = this.allLocations.filter(
                (locationItem) => locationItem.defaultLocationId === location.id
            );
            this.allData.push(...categoryResources);
            this.tableData = new MatTableDataSource(this.allData);
        });
        this.relatedResourcesFromCurrentSelected.forEach((r) => {
            this.tableData.data.forEach((row) => {
                if (r.resourceId == row.resourceId) this.selection.select(row);
            });
        });
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: TodoItemFlatNode): void {
        let parent: TodoItemFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: TodoItemFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every((child) => {
                return this.checklistSelection.isSelected(child);
            });
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    checkIsSelected(node: TodoItemFlatNode) {
        return this.checklistSelection.selected.findIndex((x) => x.id === node.id) > -1 ? true : false;
    }

    checkItemInList(list, item, attr) {
        if (list.find((x) => x[attr] === item)) {
            return list.find((x) => x[attr] === item);
        } else {
            return false;
        }
    }

    activeStyleNode = '';
    TREE_DATA: any;

    @Output('selectedLocations')
    selectedLocations: EventEmitter<any> = new EventEmitter<any>();

    @Input('savedLocations')
    savedLocations;

    @Input('IsRole')
    IsRole = false;

    @Input('isForReports')
    isForReports = false;

    @Input('EnableDoubleClickSelection')
    EnableDoubleClickSelection: false;
    @Input('FixedTimeList')
    FixedTimeList = null;
    @Input('IsFixedTime')
    IsFixedTime = false;

    @Input('currSelectedRess')
    currSelectedRess = [];

    @Input('canEdit')
    canEdit = true;

    @Input('rememberSelection')
    rememberSelection = false;

    @Input('RoleResourceList')
    RoleResourceList = [];

    @Input('multiselect')
    multiselect = true;

    @Output('selectedResource')
    selectedResource: EventEmitter<any> = new EventEmitter<any>();
    @Output('IsDbClickAction')
    IsDbClickAction: EventEmitter<any> = new EventEmitter<any>();
    @Output('selectedResources')
    selectedResources: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    displayedColumns: string[] = ['select', 'addToFav', 'resourceName', 'categoryName'];
    tableData = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);
    selectedLoc = '';

    dbselectresource(row) {
        this.selectedResource.emit(row);
        this.activeStyleUserTable = row.resourceId;
        this.IsDbClickAction.emit(true);

        // this.selection = row;
    }
    IsRoleResource(tableElment) {
        if (this.IsRole && this.RoleResourceList.length > 0) {
            this.RoleResourceList.forEach((r) => {
                this.tableData.data.forEach((row) => {
                    if (r.resourceid == row.resourceId) {
                        row.accessType = r.accessType;
                        this.selection.select(row);
                    }
                });
            });
            var check = this.RoleResourceList.find((e) => e.resourceid == tableElment.resourceId);

            if (check) {
                return true;
            }
            return false;
        }
        return false;
    }
    setStyle(addTofav) {
        return { color: 'black' };
    }
    AddToFav(row) {
        var favRes = { resourceId: row.resourceId };
        var fav = [];
        if (row.addToFav != true) {
            this._resourceservice
                .AddToFav(favRes)
                .pipe(takeUntil(this._unsubscribeAll$), first())
                .subscribe((result) => {
                    if (result.data != null) {
                        row.addToFav = true;
                        this.getFavList();
                    }
                });
        } else {
            fav.push(favRes);
            this._resourceservice
                .RemoveFromFav(fav)
                .pipe(takeUntil(this._unsubscribeAll$), first())
                .subscribe((result) => {
                    if (result) {
                        row.addToFav = false;
                        this.getFavList();
                    }
                });
        }
    }
    getFavList() {
        this._resourceservice
            .getFavList()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                if (result.data != null) {
                    if (result.data.length != 0)
                        localStorage.setItem('lastSelectedResources', JSON.stringify(result.data));
                }
            });
    }
    accessType = [
        { value: '2', accessType: 'Add' },
        { value: '3', accessType: 'Edit' },
        { value: '5', accessType: 'Delete' }
    ];

    ngOnInit() {
        this.baseService
            .get('Asset/GetResources-byLocation?isAll=true&timeSlotType=Open')
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                if (result.isSuccess) this.allLocations = result.data;
                this.resourcesLoaded = true;
                if (this.loaded) this.preCheckNode();
                // if (this.savedLocations?.length > 0) {
                //     console.log(this.checklistSelection?.selected);
                //     this.getResourcesByLocations(this.savedLocations);
                // }
            });

        if (this.IsRole) {
            this.displayedColumns = ['select', 'resourceName', 'categoryName', 'accesstype'];
        }

        if (this.isForReports) {
            this.displayedColumns = ['select', 'resourceName', 'categoryName'];
        }

        this.getTreeLocation();
        this.selection.changed.next = (val) => {
            if (this.IsRole == true) {
                this.selection.selected.forEach((element) => {
                    var check = this.RoleResourceList.find((e) => e.resourceid == element.resourceId);
                    if (check) {
                        if (check.accessType == '8') element.accessTypeList = ['2', '3', '5'];
                        else if (check.accessType == '7') {
                            element.accessTypeList = ['3', '5'];
                        } else if (check.accessType == '6') {
                            element.accessTypeList = ['2', '5'];
                        } else if (check.accessType == '4') {
                            element.accessTypeList = ['2', '3'];
                        } else {
                            if (check.accessType != '1') element.accessTypeList = [check.accessType];
                        }
                    } else {
                        if (element.accessType == null) {
                            element.accessType = '1';
                        }
                    }
                });
            }

            this.selectedResources.emit(val);
        };
    }

    applyAccessType(element, event) {
        if (event.length == 1) element.accessType = event[0];
        if (event.length == 3) element.accessType = '8';

        if (event.length == 2) {
            if (event.find((e) => e == '2') && event.find((e) => e == '3')) {
                element.accessType = '4';
            }
            if (event.find((e) => e == '2') && event.find((e) => e == '5')) {
                element.accessType = '6';
            }
            if (event.find((e) => e == '3') && event.find((e) => e == '5')) {
                element.accessType = '7';
            }
        }
    }
    getAccessTypes() {
        this.langservice
            .GetLookupbyKey('ResourceAccessType')
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.accessType = result.data;
            });
    }
    ngOnChanges(changes: SimpleChanges) {
        if (this.isForReports) {
            if (changes['currSelectedRess']) {
                if (changes.currSelectedRess.currentValue.length < 1) {
                    this.selection.clear();
                    this.checklistSelection.clear();
                }
                this.relatedResourcesFromCurrentSelected = changes.currSelectedRess.currentValue.filter(
                    (resource) => resource?.defaultLocationId !== '00000000-0000-0000-0000-000000000000'
                );
            }
        } else {
            if (!this.multiselect) {
                this.displayedColumns.splice(0, 1);
            }
        }
    }
    getTreeLocation() {
        this._treeService
            .getTreeLocation()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.dataSource.data = result.data;
                this.loaded = true;
                if (this.resourcesLoaded) this.preCheckNode();
            });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.tableData.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.tableData.data.forEach((row) => this.selection.select(row));
        // this.selectedResource.emit(this.selection.selected);
    }
    checkboxLabel(row): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }
    getselectedResources() {
        this.selectedResource.emit(this.selection.selected);
    }
    reloadafterdeletion() {
        this.selection = new SelectionModel(true, []);
        this.getTreeLocation();
        if (this.selectedLoc !== '') {
            this.getresbyloc(this.selectedLoc);
        } else {
            this.tableData = new MatTableDataSource();
        }
    }
    selectresource(row) {
        this.activeStyleUserTable = row.resourceId;
        //this.selection = row;

        this.selectedResource.emit(row);
    }

    // nodeClick(node) {
    //     this.activeStyleNode = node.id;
    //     this.selectedLoc = node.id;
    //     this.getresbyloc(node.id);
    //     //console.log(node);
    // }

    editResource(row) {
        if (this.canEdit) this.router.navigate(['resource', row.resourceId]);
    }
    getresbyloc(id) {
        this.tableLoaded = false;

        this._resourceservice
            .getresourcesbylocid(id)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.allData.push(...result.data);
                this.tableData.data = this.allData;

                if (
                    this.rememberSelection &&
                    this.tableData.data &&
                    this.tableData.data.length > 0 &&
                    this.currSelectedRess &&
                    this.currSelectedRess.length > 0
                ) {
                    this.currSelectedRess.forEach((r) => {
                        this.tableData.data.forEach((row) => {
                            if (r.resourceId == row.resourceId) this.selection.select(row);

                            if (this.isForReports) this.selection.select(row);
                        });
                    });
                }
                if (result.isSuccess) this.tableLoaded = true;
            });
    }

    CheckExist(Id) {
        if (!this.FixedTimeList) {
            return false;
        }
        const findBooking = (element) => element.resourceId == Id;
        const Res = this.FixedTimeList.findIndex(findBooking);
        if (Res > -1) {
            return true;
        }
        return false;
    }

    preCheckNode() {
        // console.log(this.treeControl.dataNodes);
        // console.log(this.currSelectedRess);

        const repeatedLocations = this.currSelectedRess.map((resource) => {
            return this.treeControl.dataNodes.find((location) => location.id === resource?.defaultLocationId);
        });
        // console.log(repeatedLocations);

        const uniqueChars = [...new Set(repeatedLocations)].filter((resource) => !!resource?.id);
        // console.log(uniqueChars);

        uniqueChars.forEach((location) => {
            this.checklistSelection.select(location);
        });
        this.getResourcesByLocationsAndSelectCurrent(uniqueChars);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
    }
}
