import { Component, OnInit, ViewChild, TemplateRef, Output, OnDestroy, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ResourcesService } from '@shared/services/resources.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorService, UsersService } from '@shared/services';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { first, takeUntil } from 'rxjs/operators';
import { SignsService } from '@shared/services/signs.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-sign',
    templateUrl: './sign.component.html',
    styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit, OnDestroy {
    // subscription: Subscription = new Subscription();
    private _unsubscribeAll$ = new Subject<boolean>();

    items: MenuItem[];
    loading = true;

    ngOnDestroy() {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
        // this.subscription.unsubscribe();
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }
    constructor(
        private translate: TranslateService,
        public router: Router,
        public route: ActivatedRoute,
        private modalService: BsModalService,
        private _signservice: SignsService,
        private fb: FormBuilder,
        private _errorService: ErrorService
    ) {}

    searchValue = '';
    changeNewContant = false;
    laddaSearchProgress: boolean | number = false;

    @Input('masterFile')
    masterFile = true;
    SignList = [];
    @Input('icon')
    icon = 'search';
    @ViewChild('formAddEditModal', { static: true }) formModal: TemplateRef<any>;
    modalRef: BsModalRef | null;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    displayedColumns: string[] = ['select', 'signCode', 'singeName', 'ipAddress', 'isValid'];
    dataSource = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);
    currentSigns = [];
    currentSign;

    @Output('selectedsign')
    selectedsign: EventEmitter<any> = new EventEmitter<any>();
    SignForm: FormGroup;
    saveContant = false;
    SignId = '1';
    formType = 'add';
    selectedStatus = 'Active';
    statusList = [{ name: 'All' }, { name: 'Active' }, { name: 'Inactive' }];
    actionStatus = null;
    actionsList = [{ name: 'Action' }, { name: 'Activate' }, { name: 'Delete' }, { name: 'Inactivate' }];
    newSign = false;

    changeContant() {
        this.selectedsign.emit(this.selection.selected);
    }

    deleteSelected() {
        if (this.selection.selected.length == 0) {
            this.newMessage(60, 'error');
        } else {
            this.currentSigns = this.selection.selected;
            this.newMessage(9, 'delete');
        }
    }
    selectAction() {
        if (this.actionStatus === 'Activate') {
            this.changeStatus('A');
        }
        if (this.actionStatus === 'Delete') {
            this.delete();
        }
        if (this.actionStatus === 'Inactivate') {
            this.changeStatus('NA');
        }
        this.actionStatus = null;
    }
    setCheckboxValue(event, atrr) {
        this.changeNewContant = true;
        if (event) {
            this.SignForm.get(atrr).setValue(true);
        }
    }
    applyfilterStatus() {
        if (this.selectedStatus === 'Active') {
            this.filterStatus(true);
        }
        if (this.selectedStatus === 'Inactive') {
            this.filterStatus(false);
        }
        if (this.selectedStatus === 'All') {
            this.filterStatus('');
        }
    }
    EditSign(row) {
        this.formType = 'edit';
        this.initForm();
        this.getAllTypes();
        this.getById(row.signId);
        this.openModal();
    }
    AddNewItem() {
        this.formType = 'add';
        this.initForm();
        this.openModal();
    }

    openModal() {
        const configs = {
            keyboard: false,
            ignoreBackdropClick: true,
            class: ' modal-lg modal-primary ModelLayout commenModel animated fadeInRight',
            animated: false
        };
        this.modalRef = this.modalService.show(this.formModal, configs);
    }
    closeAddEditModal() {
        if (!this.changeNewContant) {
            this.newSign = false;
            this.modalRef.hide();
        } else this.newMessage(144, 'ClosePopup');
    }
    ClosePopup() {
        this.newSign = false;
        this.CheckResource = false;
        this.modalRef.hide();
        this.changeNewContant = false;
    }
    filterStatus(status) {
        const newList = [];
        this.dataSource = new MatTableDataSource(this.SignList);

        if (status === '') {
        } else {
            this.dataSource.filteredData.forEach((element) => {
                if (element.isvalid === status) {
                    newList.push(element);
                }
            });
            this.dataSource = new MatTableDataSource(newList);
        }
        this.dataSource.sort = this.sort;

        this.applyFilter(this.searchValue);
    }

    SignTypesList=[];
    Selectedsigntype;
    getAllTypes(){
        this._signservice
            .getsigntypes()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    this.SignTypesList=result.data;
                },
                (error) => {}
            );
    }


    getById(SignId) {
        this._signservice
            .getSignById(SignId)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    this.currentSign = result.data;
                    debugger
                    if(result.data.signType == null)
                    this.Selectedsigntype = this.SignTypesList[0];
                    else
                    this.Selectedsigntype = result.data.signType;
                    const controls = this.SignForm.controls;
                    Object.keys(controls).forEach((key) => {
                        if (this.currentSign[key] != null) {
                            this.SignForm.get(key).setValue(this.currentSign[key]);
                        }
                        this.changeNewContant = false;
                    });
                },
                (error) => {}
            );
    }

    ngOnInit() {
        this.items = [
            // {label: 'Update', icon: 'pi pi-refresh', command: () => {
            //     this.update();
            // }},
            // {label: this.translate.instant('Delete'), icon: 'pi pi-times', command: () => {
            //     this.RemoveSelected();
            // }},
            // {label: 'Angular.io', icon: 'pi pi-info', url: 'http://angular.io'},
            // {separator:true},
            // {label: 'Setup', icon: 'pi pi-cog', routerLink: ['/setup']}
        ];

        this.getList();
        this.initForm();
        this.SignForm.valueChanges.pipe(takeUntil(this._unsubscribeAll$)).subscribe((changes) => {
            this.changeNewContant = true;
        });
        this._errorService.currentAction
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((action) => this.actionMsg(action));
    }
    actionMsg(data) {
        if (data !== '') {
            this[data]();
            this._errorService.action('');
        }
    }
    newMessage(id, action) {
        this._errorService.changeMessage({ id: id, action: action });
    }
    getList() {
        this.searchValue = '';
        this._signservice
            .getAllSigns()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.SignList = result.data;
                this.dataSource = new MatTableDataSource(this.SignList);
                this.dataSource.sort = this.sort;
                this.loading = false;
                //  this.applyfilterStatus();
            });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    checkboxLabel(row): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    private initForm(): void {
        this.SignForm = this.fb.group({
            signId: new FormControl(null),
            singeName: new FormControl(null, [Validators.required]),
            signCode: new FormControl(null, [Validators.required]),
            ipAddress: new FormControl(null),
            resourceName: new FormControl(null),
            SignType: new FormControl(null),
            parentResource: new FormControl(null),
            signStatus: new FormControl(null),
            isValid: new FormControl(true)
        });
    }
    setTumbnail(data) {
        this.SignForm.get('photo').setValue(data);
    }
    setFileName(data) {
        this.SignForm.get('photo').setValue(data);
    }

    saveAddEditSign() {
        this.laddaSearchProgress = 1;
        const controls = this.SignForm.controls;
        Object.keys(controls).forEach((key) => {
            controls[key].markAsTouched();
        });

        if (this.SignForm.status === 'VALID') {
            this.saveContant = false;
            if (this.SignForm.value.signId == null) {
                this._signservice
                    .addSign(this.SignForm.value)
                    .pipe(takeUntil(this._unsubscribeAll$), first())
                    .subscribe(
                        (result) => {
                            if (result.isSuccess) {
                                // const dgwData = result.data;
                                this.newMessage(93, '');
                            } else {
                                this.newMessage(77, '');
                            }
                            this.getList();
                        },
                        (error) => {
                            this.laddaSearchProgress = false;
                        }
                    );
            } else {
                if (!this.CheckResource) {
                    this._signservice
                        .editSign(this.SignForm.value)
                        .pipe(takeUntil(this._unsubscribeAll$), first())
                        .subscribe(
                            (result) => {
                                if (result.isSuccess) {
                                    // const dgwData = result.data;
                                    this.newMessage(4, '');
                                    this.changeNewContant = false;
                                    this.closeAddEditModal();
                                } else if (result.errorCode == 134) {
                                    this.newMessage(134, '');
                                } else {
                                    this.newMessage(77, '');
                                    this.closeAddEditModal();
                                }
                                this.getList();
                                this.laddaSearchProgress = false;
                                this.changeNewContant = false;
                                this.saveContant = false;
                            },
                            (error) => {
                                this.laddaSearchProgress = false;
                            }
                        );
                } else {
                    this.newMessage(151, '');
                }
            }
        } else {
            this.saveContant = true;
            window.scrollTo(0, 0);
            this.laddaSearchProgress = false;
            this.newMessage(3, '');
        }
        // this.modalRef.hide();
    }
    CheckResource = false;
    CheckSignResource() {
        this._signservice
            .CheckSignResource(this.SignForm.value)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    if (result) {
                        this.CheckResource = false;
                        //this.SignForm.get('resourceName').invalid
                    } else {
                        this.CheckResource = true;
                        // this.SignForm.get('resourceName').valid
                    }
                },
                (error) => {
                    this.laddaSearchProgress = false;
                }
            );
    }

    delete() {
        this._signservice
            .deleteSigns(this.currentSigns.map((obj) => obj.signId))
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    if (result.isSuccess) {
                        this.newMessage(54, '');
                    } else {
                        this.newMessage(55, '');
                    }
                    this.selection = new SelectionModel(true, []);
                    this.getList();
                    this.laddaSearchProgress = false;
                    this.changeNewContant = false;
                    this.saveContant = false;
                },
                (error) => {
                    this.laddaSearchProgress = false;
                }
            );
    }
    changeStatus(data) {}

    getSelectedResources(resources) {
        this.changeNewContant = true;
        this.SignForm.get('parentResource').setValue(resources.resourceId);
        this.SignForm.get('resourceName').setValue(resources.resourceName);
        this.CheckSignResource();
    }
}
