import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Result, TaskType } from '../_models';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, from } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { dateRange, timeRange } from '@shared/_models/swcInput';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    private canonicalName: string;
    private messageSource = new BehaviorSubject({});
    currentMessage = this.messageSource.asObservable();
    private actionFn = new BehaviorSubject('');
    currentAction = this.actionFn.asObservable();
    /* add below any Shared Api Urls That are Reapeated too much
     but please consider CamelCase Typing style */
    public readonly sharedApiUrls = {
        getAllUsers: 'user'
    };
    finishedLoadingTranslation = new BehaviorSubject<boolean>(false);
    APIURL = '';
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;

    constructor(private http: HttpClient, private router: Router) {
        if (localStorage.getItem('canonicalName') !== null) {
            this.canonicalName = localStorage.getItem('canonicalName') + '/';
        } else {
            this.canonicalName = '';
        }

        const newurl = window.location.href;
        if (this.isOnPremise) this.APIURL = this.appUrl;
        else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
        }

        if (this.APIURL.search('localhost') > -1) {
            // this.APIURL = 'https://main.api.sharewinds.com/api/v1/';
            this.APIURL = environment.localHostUrl;
        }

        //this.APIURL = 'http://localhost:8988/api/v1/';
    }
    client_id = '54836345725-njionuiopqcgej6a9nj000r86u7fisp6.apps.googleusercontent.com';

    getCode(url?) {
        localStorage.removeItem('gmail_source');
        let fullurl = window.location.href;
        let baseUrl = fullurl.substr(0, fullurl.indexOf(fullurl.split('/')[2])) + fullurl.split('/')[2];
        if (!baseUrl.endsWith('/')) baseUrl += '/';
        let navUrl = this.router.url;
        localStorage.setItem('gmail_source', navUrl);
        ///baseUrl='urn:ietf:wg:oauth:2.0:oob'//
        let endPoint = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${this.client_id}&response_type=code&scope=https://mail.google.com/%20email&access_type=offline&prompt=consent&redirect_uri=${baseUrl}`;

        window.location.href = endPoint;
    }
    //   Get
    get(url) {
        //return this.http.get<any>(`${environment.appUrl}${this.canonicalName}${url}`);
        return this.http.get<any>(`${this.APIURL}${url}`);
    }

    //   Get a response of type any
    getAny(url) {
        //return this.http.get<any>(`${environment.appUrl}${this.canonicalName}${url}`);
        return this.http.get<any>(`${this.APIURL}${this.canonicalName}${url}`);
    }

    //   Get
    getIntegration(url) {
        //return this.http.get<any>(`${environment.appUrl}${this.canonicalName}${url}`);
        return this.http.get<any>(`${this.APIURL}${url}`);
    }

    //   Post

    post(url, body) {
        //return this.http.post<any>(`${environment.appUrl}${this.canonicalName}${url}`, body);
        return this.http.post<any>(`${this.APIURL}${url}`, body);
    }

    postSvg(body) {
        //return this.http.post<any>(`${environment.appUrl}${this.canonicalName}${url}`, body);
        //return this.http.post<any>(`http://mzl.api.sharewinds.com/api/v1/layout/SaveLayoutData`, body);
        return this.http.post<any>(`${this.APIURL}layout/SaveLayoutData`, body);
    }
    //   Put
    put(url, body) {
        //return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}${url}`, body);
        return this.http.put<Result>(`${this.APIURL}${url}`, body);
    }

    //   Download
    downloadFile(file) {
        //const str = `${environment.appUrl}${this.canonicalName}${file}`;
        const str = `${this.APIURL}${file}`;

        // console.log(str);
        return str;
    }

    downloadFilebyid(url: any, id: string, file: any) {
        if (id == undefined) id = null;
        //let str=`${environment.appUrl}${this.canonicalName}Attachment/download/${url}/${id}/${file}`
        let str = `${this.APIURL}Attachment/download/${url}/${id}/${file}`;

        return str;
    }
    //   Delete

    delete(url) {
        //return this.http.delete<Result>(`${environment.appUrl}${this.canonicalName}${url}`);
        return this.http.delete<Result>(`${this.APIURL}${url}`);
    }

    deletebyid(url: any, id: string, filenames: any) {
        if (id == undefined) id = null;
        // console.log(id);
        //return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}Attachment/delete/${url}/${id}/` ,
        return this.http.post<Result>(`${this.APIURL}Attachment/delete/${url}/${id}/`, filenames);
    }

    changeStatus(url, status, body) {
        //return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}${url}` + status, body);
        return this.http.post<Result>(`${this.APIURL}${url}` + status, body);
    }

    uploadAttachment(fileToUpload: File, url: string, id?: string, description?: string) {
        if (id === undefined) {
            id = null;
        }
        const formData = new FormData();
        const headers = new HttpHeaders({ name: 'Accept', value: 'application/json' });
        formData.append('file', fileToUpload, fileToUpload.name); // formData.append('caseId', id);
        //return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}Attachment/UploadAttachment/${url}/${id}` ,
        return this.http.post<Result>(
            `${this.APIURL}Attachment/UploadAttachment/${url}/${id}?Remarks=` + description,
            formData,
            { headers: headers }
        );
    }

    downloadFiles(url: any, id: string, file: any) {
        if (id === undefined) {
            id = null;
        }
        //const str = `${environment.appUrl}${this.canonicalName}Attachment/download/${url}/${id}/${file}`;
        const str = `${this.APIURL}Attachment/download/${url}/${id}/${file}`;
        return str;
    }

    uploadimage(fileToUpload: File) {
        var formData = new FormData();
        const headers = new HttpHeaders({ name: 'Accept', value: 'application/json' });
        formData.append('file', fileToUpload, fileToUpload.name); //formData.append('caseId', id);
        // let options = [{ 'Content-Type': 'multipart/form-data' }];
        //return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}permission/Upload`,formData,{headers:headers} );
        return this.http.post<Result>(`${this.APIURL}permission/Upload`, formData, { headers: headers });
    }

    getDealers() {
        //return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}pricelist/get-dealers-combo`);
        return this.http.get<Result>(`${this.APIURL}pricelist/get-dealers-combo`);
    }

    changeMessage(mssgData) {
        this.messageSource.next(mssgData);
    }

    action(actionFn) {
        this.actionFn.next(actionFn);
    }

    getrestrictedSlotsInResource(resourceId, bookingId) {
        return this.http.get<any>(
            `${this.APIURL}Bbooking/get-restrictedSlotsInResourceWithEditMood/` + resourceId + '/' + bookingId
        );
    }

    applyManualSort(id: string, direction: string, arrayTobeSorted: any) {
        if (!arrayTobeSorted) return [];

        var newSortedlist = arrayTobeSorted.sort((a: any, b: any) => {
            if (direction === 'dsc') {
                if (a[id] < b[id]) {
                    return -1;
                } else if (a[id] > a[id]) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                if (a[id] > b[id]) {
                    return -1;
                } else if (a[id] < a[id]) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });

        // this.lastDir = direction;
        return newSortedlist;
    }

    getAllAvaiableReports() {
        var withOutV1 = this.appUrl.replace('/v1', '');
        return this.http.get<any>(`${withOutV1}Report/Get-Available-Reports`);
    }

    getResourcGroups() {
        return this.http.get<any>(`${this.APIURL}Asset/GetAllResourceGroupWithChilds`);
    }

    generateReport(reportId, reportFilter, format) {
        var withOutV1 = this.appUrl.replace('/v1', '');
        return this.http.post(`${withOutV1}Report/Generate/` + reportId + '/' + format, reportFilter, {
            responseType: 'text'
        });
    }

    generateNonHtmlReport(reportId, reportFilter, format) {
        var withOutV1 = this.appUrl.replace('/v1', '');
        return this.http.post(`${withOutV1}Report/Generate/` + reportId + '/' + format, reportFilter, {
            responseType: 'arraybuffer'
        });
    }

    SaveVsTimesConfig(serviceId, vsResourceId, configLst) {
        return this.http.post<any>(
            `${this.APIURL}layout/SaveVsTimesConfig/` + serviceId + '/' + vsResourceId,
            configLst
        );
    }
    SaveNrTimesConfig(NrResourceId, configLst) {
        return this.http.post<any>(`${this.APIURL}Asset/SaveNormalResourceTimesConfig/` + NrResourceId, configLst);
        // return this.http.post<any>(`http://localhost:8988/api/v1/Asset/SaveNormalResourceTimesConfig/` + NrResourceId, configLst);
    }

    getAllSharedCalendars(countryTimeZoneId = null) {
        if (!countryTimeZoneId) return this.http.get<any>(`${this.APIURL}SWC/GetAllActiveSharedCalendars`);
        else return this.http.get<any>(`${this.APIURL}SWC/GetAllActiveSharedCalendars?cTzId=` + countryTimeZoneId);
    }

    AddSharedCalendar(newCal) {
        return this.http.post<any>(`${this.APIURL}SWC/AddSharedCalendar`, newCal);
    }

    editSWCHeader(Cal) {
        return this.http.post<any>(`${this.APIURL}SWC/EditSWCHeader`, Cal);
        //return this.http.post<any>(`http://localhost:8988/api/v1/SWC/EditSWCHeader`, Cal);
    }

    InActivateCalendars(lst) {
        return this.http.post<any>(`${this.APIURL}SWC/InActivateCalendars`, lst);
    }

    // AddSWCDetails(swcHeaderId, inputObj) {
    //     //return this.http.post<any>(`${this.APIURL}SWC/AddSharedCalendar`, lst);
    //     return this.http.post<any>(`http://localhost:8988/api/v1/SWC/AddSWCDetails/` + swcHeaderId, inputObj);
    // }

    AddSWCDetails(swcHeaderId, inputObjs) {
        return this.http.post<any>(`${this.APIURL}SWC/AddSWCDetails/` + swcHeaderId, inputObjs);
    }

    getSWCDetails(swcHeaderId) {
        return this.http.get<any>(`${this.APIURL}SWC/GetSWCDetails/` + swcHeaderId);
    }

    getSWCDetailsSplitted(swcHeaderId, shouldGroupDays = true) {
        return this.http.get<any>(
            `${this.APIURL}SWC/GetSplitted-SWC-Details-Days/` + swcHeaderId + '/' + shouldGroupDays
        );
    }

    configResourceWorkingHours(resId, swcHeaderId, inputObjs) {
        if (swcHeaderId)
            return this.http.post<any>(
                `${this.APIURL}SWC/ConfigResourceWorkingHours/` + resId + '/' + swcHeaderId,
                inputObjs
            );
        else return this.http.post<any>(`${this.APIURL}SWC/ConfigResourceWorkingHours/` + resId, inputObjs);
    }
    configUserWorkingHours(userid, swcHeaderId, inputObjs) {
        if (swcHeaderId)
            return this.http.post<any>(
                `${this.APIURL}SWC/ConfigUserWorkingHours/` + userid + '/' + swcHeaderId,
                inputObjs
            );
        else return this.http.post<any>(`${this.APIURL}SWC/ConfigUserWorkingHours/` + userid, inputObjs);
    }

    CanRemoveResourceWorkingHours(resId, inputObjs) {
        return this.http.post<any>(`${this.APIURL}SWC/CanRemoveResourceWorkingHours/` + resId, inputObjs);
    }
    CanEditResourceWorkingHours(resId, inputObjs) {
        return this.http.post<any>(`${this.APIURL}SWC/CanEditResourceWorkingHours/` + resId, inputObjs);
    }
    CanChangePublicWorkCalendar(resId) {
        return this.http.get<any>(`${this.APIURL}SWC/CanChangePublicWorkCalendar/` + resId);
    }

    CheckConflictResourceWorkingHours(resId, inputObjs) {
        return this.http.post<any>(`${this.APIURL}SWC/CheckConflictResourceWorkingHours/` + resId, inputObjs);
    }

    GetResourceWorkingHoursAsRawData(resId) {
        return this.http.get<any>(`${this.APIURL}SWC/GetResourceWorkingHoursAsRawDataOutput/` + resId);
        //return this.http.get<any>(`http://localhost:8988/api/v1/SWC/GetResourceWorkingHoursAsRawDataOutput/` + resId);
    }
    GetUserWorkingHoursAsRawData(userid) {
        return this.http.get<any>(`${this.APIURL}SWC/GetUserWorkingHoursAsRawDataOutput/` + userid);
        //return this.http.get<any>(`http://localhost:8988/api/v1/SWC/GetResourceWorkingHoursAsRawDataOutput/` + resId);
    }

    // Shared offline Helper Functions

    // Times
    checkTimesOverlapping(listTocheck: timeRange[], timeRangeToAdd: timeRange): Result {
        var retResult = new Result();
        retResult.isSuccess = false;

        if (timeRangeToAdd.startTimeRange == timeRangeToAdd.endTimeRange) {
            retResult.message = 'Empty range';
            retResult.data = timeRangeToAdd.startTimeRange + ' - ' + timeRangeToAdd.endTimeRange;
            retResult.errorCode = 400;
            return retResult;
        }

        if (
            listTocheck.filter(
                (s) =>
                    s.startTimeRange == timeRangeToAdd.startTimeRange && s.endTimeRange == timeRangeToAdd.endTimeRange
            ).length > 0
        ) {
            retResult.message = 'Overlapping detected!';
            retResult.data = timeRangeToAdd.startTimeRange + ' - ' + timeRangeToAdd.endTimeRange;
            retResult.errorCode = 410; // Same start And end
            return retResult;
        }

        var fakeDateString = '01/01/2000 ';
        var needToAddfromAsDate = new Date(fakeDateString + timeRangeToAdd.startTimeRange);
        var needToAddtoAsDate = new Date(fakeDateString + timeRangeToAdd.endTimeRange);

        for (let i = 0; i < listTocheck.length; i++) {
            const ele = listTocheck[i];
            var fAsDate = new Date(fakeDateString + ele.startTimeRange);
            var tAsDate = new Date(fakeDateString + ele.endTimeRange);

            if (needToAddfromAsDate >= fAsDate && needToAddfromAsDate < tAsDate) {
                retResult.data = ele.startTimeRange + ' - ' + ele.endTimeRange;
                retResult.message = 'Overlapping detected!';
                retResult.errorCode = 410; // CompleteOverLappling Overlapping
                return retResult;
            }

            if (needToAddtoAsDate > fAsDate && needToAddtoAsDate <= tAsDate) {
                retResult.data = ele.startTimeRange + ' - ' + ele.endTimeRange;
                retResult.message = 'Overlapping detected!';
                retResult.errorCode = 410; // Overlapping
                return retResult;
            }

            if (needToAddfromAsDate <= fAsDate && needToAddtoAsDate > fAsDate) {
                retResult.data = ele.startTimeRange + ' - ' + ele.endTimeRange;
                retResult.message = 'Overlapping detected!';
                retResult.errorCode = 410; // Overlapping
                return retResult;
            }
        }

        retResult.isSuccess = true;
        return retResult;
    }

    checkIfRangeInSideAnotherRange(
        timeRangeExist: timeRange,
        timeRangeToCheck: timeRange,
        failOnExactSame = false
    ): Result {
        var retResult = new Result();

        var fakeDateString = '01/01/2000 ';
        var fromAsDate = new Date(fakeDateString + timeRangeToCheck.startTimeRange);
        var toAsDate = new Date(fakeDateString + timeRangeToCheck.endTimeRange);
        var fAsDate = new Date(fakeDateString + timeRangeExist.startTimeRange);
        var tAsDate = new Date(fakeDateString + timeRangeExist.endTimeRange);

        if (failOnExactSame) {
            if (fromAsDate.getTime() == fAsDate.getTime() && toAsDate.getTime() == tAsDate.getTime()) {
                retResult.isSuccess = false;
                retResult.data = timeRangeExist.startTimeRange + ' - ' + timeRangeExist.endTimeRange;
                retResult.message = 'same range not allowed';
                retResult.errorCode = 300;
                return retResult; // short curcuite
            }
        }

        if (fromAsDate >= fAsDate && toAsDate <= tAsDate) {
            retResult.isSuccess = true;
        } else {
            retResult.isSuccess = false;
            retResult.data = timeRangeExist.startTimeRange + ' - ' + timeRangeExist.endTimeRange;
            retResult.message = 'Out of each other range';
            retResult.errorCode = 301;
        }

        return retResult;
    }

    validateTimes(timeRangeToAdd: timeRange): Result {
        var retResult = new Result();
        retResult.isSuccess = false;

        var fakeDateString = '01/01/2000 ';
        var fromAsDate = new Date(fakeDateString + timeRangeToAdd.startTimeRange);
        var toAsDate = new Date(fakeDateString + timeRangeToAdd.endTimeRange);
        if (fromAsDate > toAsDate) {
            retResult.message = 'Invaild range';
            retResult.data = timeRangeToAdd.startTimeRange + ' - ' + timeRangeToAdd.endTimeRange;
            retResult.errorCode = 500; // from greater to
            return retResult;
        }

        retResult.isSuccess = true;
        return retResult;
    }

    //Dates

    checkIfRangeInSideAnotherRangeOfDate(
        dateRangeExist: dateRange,
        dateRangeToCheck: dateRange,
        failOnExactSame = false
    ): Result {
        var retResult = new Result();

        //var fakeDateString = '01/01/2000 ';
        var fromAsDate = new Date(dateRangeToCheck.startDateRange);
        var toAsDate = new Date(dateRangeToCheck.endDateRange);
        var fAsDate = new Date(dateRangeExist.startDateRange);
        var tAsDate = new Date(dateRangeExist.endDateRange);

        if (failOnExactSame) {
            if (fromAsDate == fAsDate && toAsDate == tAsDate) {
                retResult.isSuccess = false;
                retResult.data = dateRangeExist.startDateRange + ' - ' + dateRangeExist.endDateRange;
                retResult.message = 'same range not allowed';
                retResult.errorCode = 300;
                return retResult; // short curcuite
            }
        }

        if (fromAsDate >= fAsDate && toAsDate <= tAsDate) {
            retResult.isSuccess = true;
        } else {
            retResult.isSuccess = false;
            retResult.data = dateRangeExist.startDateRange + ' - ' + dateRangeExist.endDateRange;
            retResult.message = 'Out of each other range';
            retResult.errorCode = 301;
        }

        return retResult;
    }

    checkDatesOverlapping(listTocheck: dateRange[], dateRangeToAdd: dateRange): Result {
        var retResult = new Result();
        retResult.isSuccess = false;

        // if (dateRangeToAdd.startDateRange == dateRangeToAdd.endDateRange) {
        //     retResult.message = 'Empty range';
        //     retResult.data = dateRangeToAdd.startDateRange + ' - ' + dateRangeToAdd.endDateRange;
        //     retResult.errorCode = 400;
        //     return retResult;
        // }

        if (
            listTocheck.filter(
                (s) =>
                    s.startDateRange == dateRangeToAdd.startDateRange && s.endDateRange == dateRangeToAdd.endDateRange
            ).length > 0
        ) {
            retResult.message = 'Overlapping detected!';
            retResult.data = dateRangeToAdd.startDateRange + ' - ' + dateRangeToAdd.endDateRange;
            retResult.lookupData = dateRangeToAdd;
            retResult.errorCode = 410; // Same start And end
            return retResult;
        }

        //var fakeDateString = '01/01/2000 ';
        var needToAddfromAsDate = new Date(dateRangeToAdd.startDateRange);
        var needToAddtoAsDate = new Date(dateRangeToAdd.endDateRange);

        for (let i = 0; i < listTocheck.length; i++) {
            const ele = listTocheck[i];
            var fAsDate = new Date(ele.startDateRange);
            var tAsDate = new Date(ele.endDateRange);

            if (needToAddfromAsDate >= fAsDate && needToAddfromAsDate < tAsDate) {
                retResult.data = ele.startDateRange + ' - ' + ele.endDateRange;
                retResult.lookupData = ele;
                retResult.message = 'Overlapping detected!';
                retResult.errorCode = 410; // CompleteOverLappling Overlapping
                return retResult;
            }

            if (needToAddtoAsDate > fAsDate && needToAddtoAsDate <= tAsDate) {
                retResult.data = ele.startDateRange + ' - ' + ele.endDateRange;
                retResult.lookupData = ele;
                retResult.message = 'Overlapping detected!';
                retResult.errorCode = 410; // Overlapping
                return retResult;
            }

            if (needToAddfromAsDate <= fAsDate && needToAddtoAsDate > fAsDate) {
                retResult.data = ele.startDateRange + ' - ' + ele.endDateRange;
                retResult.lookupData = ele;
                retResult.message = 'Overlapping detected!';
                retResult.errorCode = 410; // Overlapping
                return retResult;
            }
        }

        retResult.isSuccess = true;
        return retResult;
    }

    checkDatesOverlappingTypeIncluded(listTocheck: dateRange[], dateRangeToAdd: dateRange): Result {
        var retResult = new Result();
        retResult.isSuccess = false;

        // if (dateRangeToAdd.startDateRange == dateRangeToAdd.endDateRange) {
        //     retResult.message = 'Empty range';
        //     retResult.data = dateRangeToAdd.startDateRange + ' - ' + dateRangeToAdd.endDateRange;
        //     retResult.errorCode = 400;
        //     return retResult;
        // }

        if (
            listTocheck.filter(
                (s) =>
                    s.startDateRange == dateRangeToAdd.startDateRange && s.endDateRange == dateRangeToAdd.endDateRange
            ).length > 0
        ) {
            retResult.message = 'Overlapping detected!';
            retResult.data = dateRangeToAdd.startDateRange + ' - ' + dateRangeToAdd.endDateRange;
            retResult.lookupData = dateRangeToAdd;
            retResult.errorCode = 410; // Same start And end
            return retResult;
        }

        //var fakeDateString = '01/01/2000 ';
        var needToAddfromAsDate = new Date(dateRangeToAdd.startDateRange);
        var needToAddtoAsDate = new Date(dateRangeToAdd.endDateRange);

        for (let i = 0; i < listTocheck.length; i++) {
            const ele = listTocheck[i];
            var fAsDate = new Date(ele.startDateRange);
            var tAsDate = new Date(ele.endDateRange);

            if (ele.isCloseType != dateRangeToAdd.isCloseType) continue;

            if (needToAddfromAsDate >= fAsDate && needToAddfromAsDate <= tAsDate) {
                retResult.data = ele.startDateRange + ' - ' + ele.endDateRange;
                retResult.lookupData = ele;
                retResult.message = 'Overlapping detected!';
                retResult.errorCode = 410; // CompleteOverLappling Overlapping
                return retResult;
            }

            if (needToAddtoAsDate >= fAsDate && needToAddtoAsDate <= tAsDate) {
                retResult.data = ele.startDateRange + ' - ' + ele.endDateRange;
                retResult.lookupData = ele;
                retResult.message = 'Overlapping detected!';
                retResult.errorCode = 410; // Overlapping
                return retResult;
            }

            if (needToAddfromAsDate <= fAsDate && needToAddtoAsDate > fAsDate) {
                retResult.data = ele.startDateRange + ' - ' + ele.endDateRange;
                retResult.lookupData = ele;
                retResult.message = 'Overlapping detected!';
                retResult.errorCode = 410; // Overlapping
                return retResult;
            }
        }

        retResult.isSuccess = true;
        return retResult;
    }
}
