import {
    Component,
    OnInit,
    ViewChild,
    TemplateRef,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorService, LanguageService } from '@shared/services';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { first, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ServicesService } from '@shared/services/Service.service';

@Component({
    selector: 'app-search-r-services',
    templateUrl: './search-r-services.component.html',
    styleUrls: ['./search-r-services.component.scss']
})
export class SearchRServicesComponent implements OnInit, OnChanges, OnDestroy {
    private _unsubscribeAll$ = new Subject<boolean>();

    constructor(
        private translate: TranslateService,
        public router: Router,
        public route: ActivatedRoute,
        private _service: ServicesService,
        private _errorService: ErrorService,
        private _langService: LanguageService
    ) {}

    searchValue = '';
    changeNewContant = false;
    laddaSearchProgress: boolean | number = false;

    @ViewChild(MatSort, { static: true }) sort: MatSort;

    displayedColumns: string[] = ['select', 'servicename'];
    dataSource = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);

    allResourcesShownResourecsBasedOnServiceSelection = [];
    resourcesTblDataSource = new MatTableDataSource([]);
    resourcesSelection = new SelectionModel(true, []);
    resourcesTblDisplayedColumns: string[] = ['select', 'resourceName'];

    ImgLabel: 'Photo';
    isBusy = false;

    @Input('openMode')
    openMode = 'reports'; // or app

    @Output('selectedResources')
    selectedResources: EventEmitter<any> = new EventEmitter<any>();

    @Output('selectedServices')
    selectedServices: EventEmitter<any> = new EventEmitter<any>();

    @Input('currSelectedRess')
    currSelectedRess = [];

    GetAllActive() {
        this.searchValue = '';
        this.isBusy = true;
        this._service
            .GetAllActive()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.dataSource = new MatTableDataSource(result.data);

                if (
                    result.data &&
                    result.data.length > 0 &&
                    this.currSelectedRess &&
                    this.currSelectedRess.length > 0
                ) {
                    this.currSelectedRess.forEach((r) => {
                        result.data.forEach((serviceEntry) => {
                            serviceEntry.resourceServices.forEach((resServiceEntry) => {
                                if (r.resourceId == resServiceEntry.resourceId) {
                                    this.selection.select(serviceEntry);
                                    this.resourcesSelection.select(resServiceEntry);
                                }
                            });
                        });
                    });
                }

                this.dataSource.sort = this.sort;
                this.isBusy = false;
            });
    }
    firstInit = true;

    ngOnInit() {
        this.selection.changed.next = (selectedService) => {
            this.selectedServices.emit(selectedService);
            var relatedResources = [];
            if (selectedService.added.length > 0) {
                selectedService.added.forEach((element) => {
                    relatedResources = relatedResources.concat(element.resourceServices);
                });

                relatedResources.forEach((enrtyAdded) => {
                    this.allResourcesShownResourecsBasedOnServiceSelection.push(enrtyAdded);
                    this.resourcesTblDataSource = new MatTableDataSource(
                        this.allResourcesShownResourecsBasedOnServiceSelection
                    );
                    if (!this.firstInit) this.resourcesSelection.select(enrtyAdded);
                    else {
                        this.currSelectedRess.forEach((r) => {
                            if (enrtyAdded.resourceId === r.resourceId) this.resourcesSelection.select(enrtyAdded);
                        });
                        this.firstInit = false;
                    }
                });
            } else if (selectedService.removed.length > 0) {
                selectedService.removed.forEach((element) => {
                    relatedResources = relatedResources.concat(element.resourceServices);
                });

                relatedResources.forEach((enrtyRemoved) => {
                    this.allResourcesShownResourecsBasedOnServiceSelection.splice(
                        this.allResourcesShownResourecsBasedOnServiceSelection.indexOf(enrtyRemoved),
                        1
                    );
                    this.resourcesTblDataSource = new MatTableDataSource(
                        this.allResourcesShownResourecsBasedOnServiceSelection
                    );
                    this.resourcesSelection.deselect(enrtyRemoved);
                });
            }
        };

        this.resourcesSelection.changed.next = (selectedResource) => {
            this.selectedResources.emit(selectedResource);
        };

        this.GetAllActive();
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    checkboxLabel(row): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    isAllResourcesSelected() {
        const numSelected = this.resourcesSelection.selected.length;
        const numRows = this.resourcesTblDataSource.data.length;
        return numSelected === numRows;
    }

    masterToggleForResourceTbl() {
        this.isAllResourcesSelected()
            ? this.resourcesSelection.clear()
            : this.resourcesTblDataSource.data.forEach((row) => this.resourcesSelection.select(row));
    }

    checkboxLabelForResourceTbl(row): string {
        if (!row) {
            return `${this.isAllResourcesSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.resourcesSelection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }

    setCheckboxValue(event, atrr) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['currSelectedRess']) {
            if (changes.currSelectedRess.currentValue.length < 1) {
                this.resourcesSelection.clear();
                this.selection.clear();
            }
        }
    }

    ngOnDestroy() {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
    }
}
