import { Company } from ".";
import { SheetHeader } from './';




export class MarketingCampaignType{
        marketingCampaignTypeId: number;
        name: string;
        value: string;
        isSystem: boolean;
        isActive: boolean;
}

export class MarketingCampaignPurpose{
  marketingCampaignPurposeId: number;
  name: string;
  isSystem: boolean;
  isActive: boolean;
}

export class Make{
        makeId: string;
        makeText: string;
        valid?: boolean;
}

export class CModel{
        modelId: string;
        makeId: string;
        modelText: string;
        valid: boolean;

        Make: Make;       
}

export class MCampaignaAttachFile{
        constructor(
                filePath: string, 
                fileName: string, 
                description: string, 
                campaignId: string, 
                companyId: string,
                campaignaAttachType: string){
                this.campaignaAttachType = campaignaAttachType;
                this.filePath = filePath;
                this.fileName = fileName;
                this.description = description;                
                this.campaignId = campaignId;
                this.companyId = companyId;
        }
        campaignaAttachFileId: string;
        filePath: string;
        fileName: string;
        description: string;
        campaignId: string;
        campaignaAttachType: string;
        companyId: string;
}

export class MCampaignMakeModel{
        constructor(
                campaignId: string, 
                modelId: string, 
                modelText: string, 
                makeId: string, 
                makeText: string){
                this.campaignId = campaignId;
                this.modelId = modelId;
                this.modelText = modelText;
                this.makeId = makeId;                
                this.makeText = makeText;
        }
        campaignId: string;
        modelId: string;
        modelText: string;
        makeId: string;
        makeText: string;
        valid: boolean;
}

export class MCampaignDealerBranch{
        constructor(
                campaignId: string, 
                companyId: string, 
                companyName: string, 
                branchId: string, 
                branchName: string){
                this.campaignId = campaignId;
                this.companyId = companyId;
                this.companyName = companyName;
                this.branchId = branchId;                
                this.branchName = branchName;
        }
        campaignId: string;
        companyId: string;
        companyName: string;
        branchId: string;
        branchName: string;
}
export class PartMaster{
        partNumber: string;
        companyId: string;
        partDescription: string;
        previousPartNumber: string;
        nextPartNumber: string;
        familyCode: string;
        serviceRuling: string;
        dispCode: string;
        dateOfRuling?: Date;
        exhaustionDate?: Date;
        abcmovementClass: string;
        modelYearIn?: number;
        modelYearOut?: number;
        productLineDescription: string;
        intnlGrossPriceListDollar?: number;
        logisticMatrixCode: string;
        msq?: number;
        ioret: string;
        retainYrs?: number;
        partSourceInd?: number;

        company: Company;
}

export class DealerPartFeedback{
        campaignId: string;
        partNumber: string;
        companyId: string;
}
export class MCampaignPart{
        constructor(
                partNumber: string, 
                partDescription: string, 
                productLineDescription: string, 
                familyCode: string,
                intnlGrossPriceListDollar: number){
                this.partNumber = partNumber;
                this.partDescription = partDescription;
                this.productLineDescription = productLineDescription;
                this.familyCode = familyCode;
                this.intnlGrossPriceListDollar = intnlGrossPriceListDollar;
        }
        campaignId: string;
        partNumber: string;
        companyId: string;
        partDescription: string;
        productLineDescription: string;
        familyCode: string;
        originalPrice?: number;
        intnlGrossPriceListDollar?: number;
        discountedPrice?: number;
        remarks: string;
        valid: boolean;

        partNumberNavigation: PartMaster;
        dealerPartFeedback: DealerPartFeedback[];
}
export class MService{
        serviceId: string;
        serviceName: string;
        serviceGroup: number;
        valid: boolean;
}
export class MCampaignPartResult{

  constructor(
          campaignId: string,
          partNumber: string, 
          companyId: string, 
          resultQty: number,
          resultAmount: number,
          ){
          this.campaignId = campaignId;
          this.partNumber = partNumber;
          this.companyId = companyId;
          this.resultQty = resultQty;                
          this.resultAmount = resultAmount;

  }
  campaignId: string;
  partNumber: string;
  companyId: string;
  resultQty: number;
  resultAmount: number;
}
export class MCampaignServiceResult{

  constructor(
          campaignId: string, 
          serviceId: string, 
          serviceName: string, 
          companyId: string, 
          resultQty: number,
          resultAmount: number,
          ){
          this.campaignId = campaignId;
          this.serviceId = serviceId;
          this.companyId = companyId;
          this.serviceName = serviceName;
          this.resultQty = resultQty;                
          this.resultAmount = resultAmount;

  }
  campaignId: string;
  serviceId: string;
  companyId: string;
  serviceName: string;
  resultQty: number;
  resultAmount: number;
}

export class MCampaignService{

        constructor(
                campaignId: string, 
                serviceId: string, 
                serviceName: string, 
                originalPrice: number,
                dicscountedPrice: number,
                remarks: string,
                valid: boolean
                ){
                this.campaignId = campaignId;
                this.serviceId = serviceId;
                this.serviceName = serviceName;
                this.originalPrice = originalPrice;                
                this.dicscountedPrice = dicscountedPrice;
                this.remarks = remarks;
                this.valid = valid;

        }
        campaignId: string;
        serviceId: string;
        serviceName: string;
        originalPrice: number;
        dicscountedPrice: number;
        remarks: string;
        valid: boolean;
}
export class Vin{
        vin: string;
        makeId: string;
        modelId: string;
        modelYear: number;
        modelClass: string;
        engineSize: string;
        saleDate: Date;
        warrantyEndDate: Date;
        valid: boolean;
        country: string;
        dealerName: string;
        saleType: string;
}
export class MCampaignVin{
        constructor(vin: string, companyId: string){
                this.vin = vin;
                this.companyId = companyId;
        }
        campaignId: string;
        companyId: string;
        vin: string;
        resultAmount: number;
        valid: boolean;
}

export class MarketingCampaignForExcel {
        constructor(){
              
        }
        campaignId: string;
        campaignOwnership: string;
        campaignName: string;
        startDate?:Date;
        endDate?:Date;
        status: string;
        campaignType: string;
        allocatedBudget?: number;
        targetSalesAmount?: number;
        marketingSupportRequired?: number;
        marketingSupportApproved?: number;
        approvalStatus: string;
        validation: boolean;

       
    }
    
export class MarketingCampaign {

        campaignId: string;
        campaignOwnership: string;
        campaignClass: string;
        campaignName: string;
        campaignDescription: string;
        startDate?:Date;
        endDate?:Date;
        status: string;
        campaignType: string;
        marketingCampaignPurposeId?: number;
        otherPurpose: string;
        productType: string;
        campaignClassification: string;
        campaignScope: string;
        allocatedBudget?: number;
        marketingSupportRequired?: number;
        marketingSupportApproved?: number;
        targetSalesAmount?: number;
        targetAidAmount?: number;
        achievedSalesAmount?: number;
        mvpsales?: number;
        retentionImprovementCustomersRecovered: string;
        npsimprovement: string;
        approvalStatus: string;

        validation: boolean=false;
        isFinished: boolean=false;

        userId: string;
        userName: string;
        versionDate: Date;
        versionStatus: string;
        originalCompaignId: string;
        
        marketingCampaignMakeModel?: MCampaignMakeModel[]=[];
        marketingCampaignPart?:MCampaignPart[]=[];
        marketingCampaignService?: MCampaignService[]=[];
        marketingCampaignVin?: MCampaignVin[]=[];
        marketingCampaignDealerBranch?: MCampaignDealerBranch[]=[];
        marketingCampaignAttachFile?: MCampaignaAttachFile[]=[];
        marketingCampaignPartResult?: MCampaignPartResult[]=[];
        marketingCampaignServiceResult?: MCampaignServiceResult[]=[];
      
}

export class CampaignFilter{
       
        campaignOwnership?: string;
        campaignName?: string;
        campaignType?: string;
        dealer?: string;
        campaignStatus?: string;
        approvalStatus?: string;
        startDate?: Date;
        endDate?: Date;
}

export class PartFilter{
        partNumber?: string;
        partDescription?: string;
        productLineDescription?: string;
        familyCode?: string;
}

export class ServiceFilter{
        serviceGroup?: string;
        serviceName?: string;
}