import { Permission } from "./permission";

export class Application {
  applicationId?: string;
  name: string;
  url: string;
  applicationOrder?: number;
  applicationType: number;
  permissionId?: number;
  isCategory: boolean;
  parentApplicationId?: string;
  categoryName: string;
  menuName: string;
  menuControl: string;
  menuId: string;
  permission: Permission;
  isCollapsed: boolean;
  isFavorate: boolean;
  isReaded: boolean;

  childs: Application[]=[];
}

export class ApplicationCategory {
    parentApplicationId?: string;
    applicationCategoryName: string;
}

export class ReportFilter {
    name: string;
    value?: string;
  }
  enum ReportCategoryX{
    DashPanel,
    VehicleSalesAndCarParcPanel,
    ServicePanel,
    RetentionPanel,
    LostCustomerPanel,
    PartsSalesPanel,
    CommoditiesPanel,
    LogisticsAndSupplyChainPanel,
    PartsAnalysisPanel,
    RevenueOpportunityPanel,  
  }
  export class ReportX {
    public constructor(workbookLink:string, reportName: string){
      this.workbookLink = workbookLink;
      this.reportLink = reportName;    
    }
    reportCategory: ReportCategoryX;
    reportName: string;
    reportLink: string;
    category?: string;
    reportId?: string;
    claim: string;
    url?: string;
    favorite?: boolean;
    workbookLink: string;
    reportFilters: ReportFilter[];
  }
  
  export class ReportCategoryD {
    public constructor(name:string, isCollapsed: boolean){
      this.name = name;
      this.isCollapsed = isCollapsed;    
    }
    name: string;
    isCollapsed: boolean;
  }
