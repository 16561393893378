import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-master-page-layout',
    templateUrl: './master-page-layout.component.html',
    styleUrls: ['./master-page-layout.component.scss']
})
export class MasterPageLayoutComponent implements OnInit {
    constructor() {}
    @Input() expansionPanelSearchTitle: string = '';
    @Input() actionItems: MenuItem[];
    @Input() advancedFilterTitle: string = '';
    @Input() showSearchBar: boolean = false;
    @Output() onApplyAdvancedFilter: EventEmitter<void> = new EventEmitter();
    @Output() onResetAllFilters: EventEmitter<void> = new EventEmitter();
    @Output() onAddNewItem: EventEmitter<void> = new EventEmitter();
    @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
    showDialog = false;
    // Need to make logic of filter object here if we can
    ngOnInit(): void {}
    applyAdvancedFilter() {
        this.onApplyAdvancedFilter.emit();
    }
    AddNewItem() {
        this.onAddNewItem.emit();
    }
    searchValueChange(searchValue: string) {
        this.onSearchValueChange.emit(searchValue);
    }
    resetAllFilters() {
        this.onResetAllFilters.emit();
    }
}
