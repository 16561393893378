import { Permission } from "./permission";

export class MenuSetting {
    menuId?: string;
    name: string;
    menuControl: string;
    url: string;
    description: string;
    menuOrder?: number;
    permissionId?: number;
    isCategory: boolean;
    parentMenuId?: string;
    categoryName: string;
    permission: Permission;
}


export class MenuSettingCategory {
    parentMenuId?: string;
    parentMenuName: string;
}
