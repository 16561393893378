import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeTo12'
})
export class TimeTo12Pipe implements PipeTransform {
    transform(time: string): string {
        const hours = +time.slice(0, 2);
        const minutes = time.slice(3, 5);
        if (hours == 0) {
            const newHours = 12;
            const newTime = `${newHours}:${minutes} AM`;
            return newTime;
        } else if (hours <= 11) {
            const newTime = `${hours}:${minutes} AM`;
            return newTime;
        } else if (hours == 12) {
            const newTime = `${hours}:${minutes} PM`;
            return newTime;
        } else if (hours > 11 && hours <= 23) {
            const newHours = hours - 12;
            const newTime = `${newHours}:${minutes} PM`;
            return newTime;
        } else return time;
    }
}
