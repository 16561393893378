import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import swal from 'sweetalert2';
import { MatSort } from '@angular/material/sort';
import { first, takeUntil } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { UsersService, ErrorService } from '@shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
    FormBuilder,
    FormGroup,
    Validators,
    ValidatorFn,
    FormControl,
    FormGroupDirective,
    NgForm,
    ValidationErrors
} from '@angular/forms';
import { BookingCalendar } from '@shared/_models/bookingcalendar';
import { BookingService } from '@shared/services/booking.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-calender',
    templateUrl: './calender.component.html',
    styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit, OnDestroy {
    private _unsubscribeAll$ = new Subject<boolean>();

    constructor(
        private fb: FormBuilder,
        private _userService: UsersService,
        private modalService: BsModalService,
        public router: Router,
        public route: ActivatedRoute,
        private _errorService: ErrorService,
        private _bookingService: BookingService
    ) { }

    @ViewChild('formAddEditModal', { static: true }) formModal: TemplateRef<any>;

    locationname = 'locationname';
    masterItemId = 'ceeffbcd-d914-444a-99bc-47a63aed6883';
    // start alert
    alerts: any[] = [
        {
            type: '',
            msg: ``,
            timeout: 1
        }
    ];
    searchValue = '';
    modalRef: BsModalRef | null;

    @Input('icon')
    icon = 'search';

    @Input('Dates')
    Dates: string;
    @Input('FixedTimeList')
    FixedTimeList;

    @Input('IsFixedTime')
    IsFixedTime = false;

    @Input('timeSlotsType')
    timeSlotsType = 'Open';

    @Input('multiselect')
    multiselect = false;
    //timeSlotsType = null;
    @Input('ExcludeFixedBookingResources')
    ExcludeFixedBookingResources = false;
    @Input('ExcludeInventoryAndCapcityResource')
    ExcludeInventoryAndCapcityResource = false;

    dangerAlert = false;
    laddaSearchProgress: boolean | number = false;
    LocalizationsList = [];
    selectedStatus = 'Active';
    userList = [];
    activeStyleUserTable = '';
    statusList = [{ name: 'All' }, { name: 'Active' }, { name: 'Inactive' }];
    actionStatus = null;
    actionsList = [{ name: 'Activate' }, { name: 'Delete' }, { name: 'Inactivate' }];
    localizationFrom: FormGroup;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    displayedColumns: string[] = ['cultureId', 'masterText'];
    dataSource = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);

    Cultures = [];
    Currencies = [];
    shoeForm = false;
    saveContant = false;

    Translations = [];

    Languages = [];
    @Output('selectedResource')
    selectedResource: EventEmitter<any> = new EventEmitter<any>();

    @Output('selectedResourceList')
    selectedResourceList: EventEmitter<any> = new EventEmitter<any>();

    @Output('closeSearchBox')
    closeSearchBox: EventEmitter<boolean> = new EventEmitter<boolean>();

    tabTitel = 'All';
    calendarbooking = new BookingCalendar();
    currentResource: string;
    saveSelectUserButton = false;
    currentResourceData: any;
    changetap(tap) {
        this.tabTitel = tap;
    }
    selectUser(data) { }
    AddNewItem() {
        const newItem = {
            masterItemId: '',
            cultureId: '',
            masterText: 0,

            createUser: '',
            modifyUser: '',
            createStamp: 0,
            modifyStamp: '',
            masterColumnName: '',
            cultureName: 0,
            languageId: 0,
            languageName: 0,
            direction: 0
        };
        this.LocalizationsList.unshift(newItem);
        this.dataSource = new MatTableDataSource(this.LocalizationsList);
        this.dataSource.sort = this.sort;
    }
    openModal() {
        const configs = {
            keyboard: false,
            ignoreBackdropClick: true,
            class: ' modal-lg modal-primary commenModel ModelLayoutWithTabs animated fadeInRight',
            animated: false
        };

        this.modalRef = this.modalService.show(this.formModal, configs);
        // this.modalRef = this.modalService.show(template);
    }

    editUser(data) {
        this.router.navigate(['users/CEUsers', data.contactId]);
    }
    addAlert(typeData, msgData): void {
        this.dangerAlert = false;

        let time = 3000;
        if (typeData === 'danger') {
            this.dangerAlert = true;
            time = 99999999999;
        }
        if (this.alerts.length === 0) {
            this.alerts.push({
                type: typeData,
                msg: msgData,
                timeout: time
            });
        } else {
            this.alerts.splice(0, 1);
            this.alerts.push({
                type: typeData,
                msg: msgData,
                timeout: time
            });
        }
    }

    onSelect(event) { }

    onClosedAlert(dismissedAlert): void {
        this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
        this.alerts = [];
        this.dangerAlert = false;
    }

    sortData(datat) { }
    getUserList(id, name) {
        this.searchValue = '';
        this._userService
            .getListOfLocalizations(id, name)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.LocalizationsList = result.data;
                this.dataSource = new MatTableDataSource(this.LocalizationsList);
                this.dataSource.sort = this.sort;
            });
    }
    IsDbClickEvent(event) {
        if (event == true) {
            this.saveAddEdit();
        }
    }
    filterStatus(status) {
        const newList = [];
        this.dataSource = new MatTableDataSource(this.LocalizationsList);

        if (status === '') {
        } else {
            this.dataSource.filteredData.forEach((element) => {
                if (element.isvalid === status) {
                    newList.push(element);
                }
            });
            this.dataSource = new MatTableDataSource(newList);
        }
        this.dataSource.sort = this.sort;

        this.applyFilter(this.searchValue);
    }

    loadLists() {
        this._userService
            .GetCultures()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.Cultures = result.data;
            });
        this._userService
            .GetCurrencies()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.Currencies = result.data;
            });
        this._userService
            .GetTranslations()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.Translations = result.data;
            });
        this._userService
            .GetLanguages()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.Languages = result.data;
            });
    }
    ngOnInit() {
        this.alerts = [];
        //  this.getUserList(this.masterItemId, this.locationname);
        // this.loadLists();
    }
    ngOnChanges(): void {
        //this.getUserList(this.masterItemId, this.locationname);
    }
    showLoader= false;
    saveAddEdit() {
        this.showLoader=true;
        setTimeout(() => {
            this.selectedResource.emit(this.currentResource);
            if (this.currentSelectedResourceList?.length > 0) {
                this.selectedResourceList.emit(this.currentSelectedResourceList);
                this.currentSelectedResourceList = []; // restitAgain
            }
    
            this.closeAddEditModal();
            this.showLoader=false;
            
        }, 0);
       
    }
    selectresource(row) {
        this.selectedResource.emit(row);
        this.saveSelectUserButton = true;
    }
    closeAddEditModal() {
        this.modalRef.hide();
        this.closeSearchBox.emit(true);
    }
    ngOnDestroy(): void {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }
    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    @Input('EnableDoubleClickSelection')
    EnableDoubleClickSelection: false;


    currentSelectedResourceList = [];
    GetUpdatedResource(resource) {
        this.currentResource = resource;
        if (this.multiselect && resource?.added) {
            if (!this.currentSelectedResourceList?.find(r => r.resourceId == resource?.added[0].resourceId))
                this.currentSelectedResourceList.push(resource?.added[0]);
        }

    }
    ActionMassage(data) {
        swal.fire({
            allowOutsideClick: false,
            type: data.type,
            title: data.title,
            text: data.text,
            allowEscapeKey: false
        });
    }

    errorMsg(data) {
        swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: data.messageTitle,
            text: data.messageText,
            type: data.icon.toLowerCase(),
            showCancelButton: data.buttonTwoTitle ? true : false,
            confirmButtonColor: '#FF8989',
            cancelButtonColor: '#BCBCCB',
            // iconColor:  '#FF8989',
            cancelButtonText: data.buttonTwoTitle,
            confirmButtonText: data.buttonOneTitle
        }).then((result) => {
            if (result.value) {
            } else {
            }
        });
    }

    errorMsgLocation(result) {
        if (result.data.location === 'Pop Up') {
            this.errorMsg(result.data);
        } else if (result.data.location === 'Top green') {
            this.addAlert('success', result.data.messageText);
        } else if (result.data.location === 'Top red') {
            this.addAlert('danger', result.data.messageText);
        } else {
            this.addAlert('danger', 'Failed to Save Changes');
        }
    }
}
