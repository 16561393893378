export class ModelSeriesOption
{
    optionname:string
    optioncode:string
    status:boolean
    defaultprice:number
    remarks:string

}

export class ModelSeries
{
    seriescpos:string
    model_id:string
    make_id:string
    seriesname:number
    defaultprice:string
    remarks:string
    valid:boolean
    options:ModelSeriesOption[]
}

export class MakeOfSeries
{
    make_id:string
    make_text:string
    valid:boolean

}

export class modelOfMake
{
    model_id:string
    make_id:string
    model_text:string
    valid:boolean

}
export class ModelSeriesOptionSelected
{
    optionname:string

}