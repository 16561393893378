import { Branch } from './branch';
import { Country } from './country';
import { Company } from './company';
import { Department } from './department';
import { User } from '.';
import { EmployeePosition } from './employee-position';

export class Employee {
    employeeId: string;
    companyId: string;
    departmentId: string;
    branchId: string;
    braCompanyId: string;
    workshopId: string;
    email: string;
    positionId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    phone: string;
    ext: string;
    mobile: string;
    address: string;
    gender: string;
    pobox: string;
    valid: boolean;
    userStatus: string;
    companyName: string;
    departmentText: string;
    branchName: string;
    positionName: string;
    user: User[];
    branch: Branch;
    company: Company;
    department: Department;
    employeePosition: EmployeePosition;
}