import { Employee } from "./employee";
import { Permission } from "./permission";
import { Role } from "./role";

export class User {
    userId: string;
    companyId: string;
    email: string;
    password: string;
    employeeFlag: boolean;
    employeeId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    phone: string;
    ext: string;
    mobile: string;
    address: string;
    gender: string;
    pobox: string;
    valid: boolean;
    userStatus: string;
    admin: boolean;
    employeeName: string;
    parentUserId: string;
    employee: Employee;
    userPermission: Permission[];
    userRole: Role[];
}

export class UserFilter {

    firstName?: string;
    lastName?: string;
    companyId?: string;
    email?: Date;
}