import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidation } from '@shared/_directives/password-validation';
import { UsersService, ErrorService } from '@shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize, first, takeUntil } from 'rxjs/operators';
import swal from 'sweetalert2';
import { Location } from '@angular/common';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { isThursday } from 'date-fns';

@Component({
    selector: 'app-credentials',
    templateUrl: './credentials.component.html',
    styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll$ = new Subject<boolean>();
    constructor(
        private location: Location,
        private _errorService: ErrorService,
        private fb: FormBuilder,
        private _userService: UsersService,
        private route: ActivatedRoute,
        public router: Router
    ) {
        this.frmSignup = this.createSignupForm();
        this.changeNewContant = false;

        this.sub = this.route.params.pipe(takeUntil(this._unsubscribeAll$)).subscribe((params) => {
            this.contactId = params['contactId'];
            this.userId = params['userId'];

            if (this.userId == '1') {
                this.changeNewContant = false;
            }
        });
    }

    changenewcontent(isConfirmPassFiled: boolean) {
        if (isConfirmPassFiled === false) {
            this.showPasswordError = this.checkPasswordValidity(this.frmSignup.get('newPassword').value);
            if (this.frmSignup.get('confirmPassword').value) {
                this.showConfPasswordError = this.checkPasswordMatching(
                    this.frmSignup.get('newPassword').value,
                    this.frmSignup.get('confirmPassword').value
                );
            }
        } else if (isConfirmPassFiled === true)
            this.showConfPasswordError = this.checkPasswordMatching(
                this.frmSignup.get('newPassword').value,
                this.frmSignup.get('confirmPassword').value
            );

        // if (this.frmSignup.get('newPassword').value||this.frmSignup.get('confirmPassword').value ||this.frmSignup.get('userName').value)
        // {
        //   this.changeNewContant=true;
        this.changeNewContantCre.emit(this.changeNewContant);
    }
    checkPasswordValidity(PassValue: string) {
        if (!PassValue) {
            return false;
        }
        //var regPass = new RegExp("/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/","gm");
        var regPass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,12}$/;
        var matchingArray = PassValue.match(regPass);
        //console.log(matchingArray);

        if (matchingArray) {
            return false;
        } else return true;
    }
    checkPasswordMatching(passValue: string, ConfimPassValue: string) {
        if (!ConfimPassValue) {
            return false;
        }

        if (passValue !== ConfimPassValue) return true;
        else return false;
    }

    getUserById() {
        this._userService
            .getcontactById(this.contactId)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    const userData = result.data;
                    this.userName = userData.userName;
                    this.frmSignup.get('userName').setValue(this.userName);
                    this.changeNewContant = false;
                },
                (error) => {}
            );
    }
    @Input('userName')
    userName: string;
    @Input('contactId')
    contactId: string;
    @Output('changeNewContantCre')
    changeNewContantCre: EventEmitter<any> = new EventEmitter<any>();

    sub: any;

    showPasswordError = false;
    showConfPasswordError = false;
    passFocus = false;
    conPassFocus = false;
    changeNewContant = false;
    saveContant = false;
    hideconfirm = true;
    hidenew = true;
    hidecurrent = true;
    userId = '1';
    public frmSignup: FormGroup;
    laddaSearchProgress: boolean | number = false;
    alerts: any[];

    dangerAlert = false;

    ngOnChanges(changes) {
        this.frmSignup.get('userName').setValue(this.userName);
    }
    ngOnInit() {
        // this.frmSignup=this.createSignupForm();
        this._errorService.currentAction
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((action) => this.actionMsg(action));

        this.changeNewContant = false;
    }

    // subscription: Subscription = new Subscription();

    ngOnDestroy() {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
        // this.subscription.unsubscribe();
    }
    actionMsg(data) {
        if (data !== '') {
            this[data]();
            this._errorService.action('');
        }
    }

    createSignupForm(): FormGroup {
        return this.fb.group(
            {
                userName: [null, Validators.compose([Validators.required])],
                newPassword: [
                    null,
                    Validators.compose([
                        Validators.required,
                        // check whether the entered password has a number
                        PasswordValidation.patternValidator(/\d/, {
                            hasNumber: true
                        }),
                        // check whether the entered password has upper case letter
                        // PasswordValidation.patternValidator(/[A-Z]/, {
                        //   hasCapitalCase: true
                        // }),
                        // check whether the entered password has a lower case letter
                        // PasswordValidation.patternValidator(/[a-z]/, {
                        //   hasSmallCase: true
                        // }),
                        // check whether the entered password has a special character
                        PasswordValidation.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
                            hasSpecialCharacters: true
                        }),
                        Validators.minLength(8)
                    ])
                ],
                confirmPassword: [null, Validators.compose([Validators.required])]
            },
            {
                // check whether our password and confirm password match
                validator: PasswordValidation.passwordMatchValidator
            }
        );
    }

    newMessage(messageid, action) {
        this._errorService.changeMessage({ id: messageid, action: action });
    }

    @Input('dontNavigate')
    dontNavigate = false;
    saveAddEdit() {
        this.saveContant = true;
        this.laddaSearchProgress = 1;
        if (this.frmSignup.status === 'VALID') {
            const passwordData = {
                contactId: this.contactId,
                name: this.frmSignup.value.userName,
                password: this.frmSignup.value.newPassword
            };
            this._userService
                .createPassword(passwordData)
                .pipe(
                    takeUntil(this._unsubscribeAll$),
                    first(),
                    finalize(() => (this.laddaSearchProgress = false))
                )
                .subscribe((result) => {
                    debugger;
                    if (result.isSuccess) {
                        if (result.errorCode == 180) this.newMessage(180, '');
                        else this.newMessage(4, '');
                        this.changeNewContant = false;
                        this.changeNewContantCre.emit(false);
                        const link: any[] = ['users/CEUsers', this.contactId, result.data['userId'], 'Security'];
                        //if (result.data['userName'] && result.data['userName'] != '')
                        this.userName = result.data['name'];

                        this.frmSignup.get('userName').setValue(this.userName);

                        if (!this.dontNavigate) {
                            this.router.navigate(link);
                        }

                        this.saveContant = false;
                    } else if (result.errorCode == 94) {
                        this.newMessage(115, '');
                    } else {
                        this.newMessage(result.lookupData, '');
                        // this._errorService.getDBMassage(result.lookupData).pipe(takeUntil(this._unsubscribeAll$), first()).subscribe(result => {
                        //   this.errorMsgLocation(result);
                        //     });
                    }
                });
        } else {
            this.newMessage(3, '');
            // this._errorService.getAPMassage('3').pipe(takeUntil(this._unsubscribeAll$), first()).subscribe(result => {
            //  this.errorMsgLocation(result);
            //     });
            this.laddaSearchProgress = false;
            this.saveContant = true;
        }
    }
    errorMsgLocation(result) {
        if (result.data.location === 'Pop Up') {
            this.errorMsg(result.data);
        } else if (result.data.location === 'Top green') {
            this.addAlert('success', result.data.messageText);
        } else if (result.data.location === 'Top red') {
            this.addAlert('danger', result.data.messageText);
        } else {
            this.addAlert('danger', 'Failed to Save Changes');
        }
    }
    errorMsg(data) {
        swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: data.messageTitle,
            text: data.messageText,
            type: data.icon.toLowerCase(),
            showCancelButton: data.buttonTwoTitle ? true : false,
            confirmButtonColor: '#FF8989',
            cancelButtonColor: '#BCBCCB',
            // iconColor:  '#FF8989',
            cancelButtonText: data.buttonTwoTitle,
            confirmButtonText: data.buttonOneTitle
        }).then((result) => {
            if (result.value) {
            } else {
            }
        });
    }
    addAlert(typeData, msgData): void {
        this.dangerAlert = false;

        let time = 3000;
        if (typeData === 'danger') {
            this.dangerAlert = true;
            time = 99999999999;
        }
        this.alerts = [];
        this.alerts.push({
            type: typeData,
            msg: msgData,
            timeout: time
        });
    }
    goBack() {
        this.location.back();
    }
    onClosedAlert(dismissedAlert): void {
        this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
        this.alerts = [];
        this.dangerAlert = false;
    }
    closeLocalizationPopup(localizationComponant) {
        localizationComponant.closeAddEditModal();
    }
}
