import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Result } from '../_models';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SignsService {
    APIURL = '';
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;
    private canonicalName: string;
    constructor(private http: HttpClient) {
        const newurl = window.location.href;

        if (this.isOnPremise) this.APIURL = this.appUrl;
        else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
        }

        if (this.APIURL.search('localhost') > -1) {
            //  this.APIURL = 'https://main.api.sharewinds.com/api/v1/';
            this.APIURL = environment.localHostUrl;
        }

        // this.APIURL = 'http://localhost:56622/api/v1/';
    }
    getAllCapacities() {
        return this.http.get<Result>(`${this.APIURL}BSign/GetCapacities`);
    }
    getCapacityById(id) {
        return this.http.get<Result>(`${this.APIURL}BSign/GetCapacity/` + id);
    }
    addCapacity(form) {
        return this.http.post<Result>(`${this.APIURL}BSign/add-capacity`, form);
    }

    editCapacity(form) {
        return this.http.post<Result>(`${this.APIURL}BSign/update-capacity`, form);
    }
    deleteCapacity(id) {
        return this.http.get<Result>(`${this.APIURL}BSign/delete-capacity/` + id);
    }

    deleteCapacities(ids) {
        return this.http.post<Result>(`${this.APIURL}BSign/delete-capacities`, ids);
    }
    getAllSigns() {
        return this.http.get<Result>(`${this.APIURL}BSign`);
    }
    getSignById(id) {
        return this.http.get<Result>(`${this.APIURL}BSign/` + id);
    }
    addSign(form) {
        return this.http.post<Result>(`${this.APIURL}BSign/add-sign`, form);
    }

    editSign(form) {
        return this.http.post<Result>(`${this.APIURL}BSign/update-sign`, form);
    }
    CheckSignResource(form) {
        return this.http.post<Result>(`${this.APIURL}BSign/Check-sign-Resource`, form);
    }
    deleteSign(id) {
        return this.http.get<Result>(`${this.APIURL}BSign/delete-sign/` + id);
    }

    deleteSigns(ids) {
        return this.http.post<Result>(`${this.APIURL}BSign/delete-signs`, ids);
    }
    getresourcebyid(resourceid) {
        return this.http.get<Result>(`${this.APIURL}Asset/getResource-byId/` + resourceid);
    }
    getsigntypes(){
        return this.http.get<Result>(`${this.APIURL}LookUp/Get_SignTypelookUpKey/`);
    }
}
