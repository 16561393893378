import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


@Pipe({
    name: 'customeDatePipe'
})


export class CustomeDatePipe implements PipeTransform {
constructor(public datepipe: DatePipe) {}
 transform(value: any, ...args: any[]) {
        if (!value ||  value.length < 10) {
            return value;
        }   

          if (isNaN( Date.parse(new Date(value).toString()))  ) {
            return value;
          }


        return this.datepipe.transform(value, 'dd/MM/yyyy');
    }


}
