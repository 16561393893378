import { Component, OnInit, Input, TemplateRef, OnDestroy, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-select-user',
    templateUrl: './select-user.component.html',
    styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent implements OnInit, OnDestroy {
    constructor(private modalService: BsModalService) {}
    saveSelectUserButton = false;
    modalRef: BsModalRef | null;
    @Input('open')
    openPop: boolean;
    @ViewChild('formAddEditModal') formModal: TemplateRef<any>;

    ngOnInit() {}

    openModal() {
        const configs = {
            keyboard: false,
            ignoreBackdropClick: true,
            class: ' modal-lg modal-primary test animated fadeInRight',
            animated: false
        };
        this.modalRef = this.modalService.show(this.formModal, configs);
        // this.modalRef = this.modalService.show(template);
    }
    ngOnDestroy() {
        if (this.modalRef !== null) {
            this.modalRef.hide();
        }
    }

    ngOnChanges(changes) {
        if (this.openPop) {
            this.openModal();
        }
    }
}
