import { City } from './city';
import { Country } from './country';

export class Company {
    companyId: string;
    companyName: string;
    isDealer: string;
    parentCompanyName: string;
    address: string;
    phone: string;    
    fax: string;  
    email: string;
    mobile: string;
    valid: boolean;
    parentCompanyNavigation: Company;
}


