import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SignsService } from '@shared/services/signs.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorService } from '@shared/services';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { first, takeUntil } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';

import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-capacity',
    templateUrl: './capacity.component.html',
    styleUrls: ['./capacity.component.scss']
})
export class CapacityComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    private _unsubscribeAll$ = new Subject<boolean>();

    items: MenuItem[];
    loading = true;
    constructor(
        private translate: TranslateService,
        public router: Router,
        public route: ActivatedRoute,
        private modalService: BsModalService,
        private _signservice: SignsService,
        private fb: FormBuilder,
        private _errorService: ErrorService
    ) {}

    searchValue = '';
    changeNewContant = false;
    laddaSearchProgress: boolean | number = false;

    capacitiesList = [];

    @ViewChild('formAddEditModal', { static: true }) formModal: TemplateRef<any>;
    modalRef: BsModalRef | null;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    displayedColumns: string[] = ['select', 'unitname'];
    dataSource = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);
    currentSign;
    formType = 'add';
    capacityForm: FormGroup;
    saveContant = false;
    capacityId = '1';
    selectedcapacity = [];
    selectedStatus = 'Active';
    statusList = [{ name: 'All' }, { name: 'Active' }, { name: 'Inactive' }];
    actionStatus = null;
    actionsList = [{ name: 'Action' }, { name: 'Activate' }, { name: 'Delete' }, { name: 'Inactivate' }];
    newSign = false;
    selectAction() {
        if (this.actionStatus === 'Activate') {
            this.changeStatus('A');
        }
        if (this.actionStatus === 'Delete') {
            this.delete();
        }
        if (this.actionStatus === 'Inactivate') {
            this.changeStatus('NA');
        }
        this.actionStatus = null;
    }
    ngOnDestroy() {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
        // this.subscription.unsubscribe();
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }
    applyfilterStatus() {
        if (this.selectedStatus === 'Active') {
            this.filterStatus(true);
        }
        if (this.selectedStatus === 'Inactive') {
            this.filterStatus(false);
        }
        if (this.selectedStatus === 'All') {
            this.filterStatus('');
        }
    }
    Editcapacity(row) {
        this.formType = 'edit';
        this.initForm();
        this.getById(row.unitid);
        this.openModal();
    }
    AddNewItem() {
        this.formType = 'add';
        this.initForm();
        this.openModal();
    }

    deleteSelected() {
        if (this.selection.selected.length == 0) {
            this.newMessage(60, 'error');
        } else {
            this.selectedcapacity = this.selection.selected;
            this.newMessage(9, 'delete');
        }
    }
    openModal() {
        const configs = {
            keyboard: false,
            ignoreBackdropClick: true,
            class: ' modal-lg modal-primary ModelLayout commenModel animated fadeInRight',
            animated: false
        };
        this.modalRef = this.modalService.show(this.formModal, configs);
    }
    closeAddEditModal() {
        if (!this.changeNewContant) {
            this.newSign = false;
            this.modalRef.hide();
        } else this.newMessage(144, 'ClosePopup');
    }
    ClosePopup() {
        this.newSign = false;
        this.modalRef.hide();
        this.changeNewContant = false;
    }

    filterStatus(status) {
        const newList = [];
        this.dataSource = new MatTableDataSource(this.capacitiesList);

        if (status === '') {
        } else {
            this.dataSource.filteredData.forEach((element) => {
                if (element.isvalid === status) {
                    newList.push(element);
                }
            });
            this.dataSource = new MatTableDataSource(newList);
        }
        this.dataSource.sort = this.sort;

        this.applyFilter(this.searchValue);
    }
    getById(capacityId) {
        this._signservice
            .getCapacityById(capacityId)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    this.currentSign = result.data;
                    const controls = this.capacityForm.controls;
                    Object.keys(controls).forEach((key) => {
                        if (this.currentSign[key] != null) {
                            this.capacityForm.get(key).setValue(this.currentSign[key]);
                        }
                        this.changeNewContant = false;
                    });
                },
                (error) => {}
            );
    }

    ngOnInit() {
        this.items = [
            // {label: 'Update', icon: 'pi pi-refresh', command: () => {
            //     this.update();
            // }},
            {
                label: this.translate.instant('Delete'),
                icon: 'pi pi-times',
                command: () => {
                    this.deleteSelected();
                }
            }
            // {label: 'Angular.io', icon: 'pi pi-info', url: 'http://angular.io'},
            // {separator:true},
            // {label: 'Setup', icon: 'pi pi-cog', routerLink: ['/setup']}
        ];

        this.getList();
        this.initForm();
        this.capacityForm.valueChanges.pipe(takeUntil(this._unsubscribeAll$)).subscribe((changes) => {
            this.changeNewContant = true;
        });

        this.subscription.add(
            this._errorService.currentAction
                .pipe(takeUntil(this._unsubscribeAll$))
                .subscribe((action) => this.actionMsg(action))
        );
    }

    getList() {
        this.searchValue = '';
        this._signservice
            .getAllCapacities()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.capacitiesList = result.data;
                this.dataSource = new MatTableDataSource(this.capacitiesList);
                this.dataSource.sort = this.sort;
                this.loading = false;
                //  this.applyfilterStatus();
            });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    checkboxLabel(row): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    private initForm(): void {
        this.capacityForm = this.fb.group({
            unitid: new FormControl(null),
            unitname: new FormControl(null, [Validators.required])
        });
    }
    setTumbnail(data) {
        this.capacityForm.get('photo').setValue(data);
    }
    setFileName(data) {
        this.capacityForm.get('photo').setValue(data);
    }

    saveAddEditcapacity() {
        this.laddaSearchProgress = 1;
        const controls = this.capacityForm.controls;
        Object.keys(controls).forEach((key) => {
            controls[key].markAsTouched();
        });

        if (this.capacityForm.status === 'VALID') {
            this.saveContant = false;
            if (this.capacityForm.value.unitid == null) {
                this._signservice
                    .addCapacity(this.capacityForm.value)
                    .pipe(takeUntil(this._unsubscribeAll$), first())
                    .subscribe(
                        (result) => {
                            if (result.isSuccess) {
                                // const dgwData = result.data;
                                this.newMessage(93, '');
                                this.changeNewContant = false;
                                this.closeAddEditModal();
                            } else {
                                this.newMessage(77, '');
                            }
                            this.getList();
                            this.laddaSearchProgress = false;
                        },
                        (error) => {
                            this.laddaSearchProgress = false;
                        }
                    );
            } else {
                this._signservice
                    .editCapacity(this.capacityForm.value)
                    .pipe(takeUntil(this._unsubscribeAll$), first())
                    .subscribe(
                        (result) => {
                            if (result.isSuccess) {
                                // const dgwData = result.data;
                                this.newMessage(4, '');
                                this.changeNewContant = false;
                                this.closeAddEditModal();
                            } else {
                                this.newMessage(77, '');
                            }
                            this.getList();
                            this.laddaSearchProgress = false;
                            this.changeNewContant = false;
                            this.saveContant = false;
                        },
                        (error) => {
                            this.laddaSearchProgress = false;
                        }
                    );
            }
        } else {
            this.saveContant = true;
            window.scrollTo(0, 0);
            this.laddaSearchProgress = false;
            this.newMessage(3, '');
        }
        // this.modalRef.hide();
    }

    setCurrentcapacity() {
        this.delete();
    }
    delete() {
        // console.log(this.selectedcapacity.map((obj) => obj.unitid));
        this._signservice
            .deleteCapacities(this.selectedcapacity.map((obj) => obj.unitid))
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    if (result.isSuccess) {
                        this.newMessage(54, '');
                    } else {
                        this.newMessage(55, '');
                    }
                    this.selection = new SelectionModel(true, []);
                    this.getList();
                    this.laddaSearchProgress = false;
                    this.changeNewContant = false;
                    this.saveContant = false;
                },
                (error) => {
                    this.laddaSearchProgress = false;
                }
            );
    }
    changeStatus(data) {}

    actionMsg(data) {
        if (data !== '') {
            this[data]();
            this._errorService.action('');
        }
    }
    newMessage(id, action) {
        this._errorService.changeMessage({ id: id, action: action });
    }
    closeLocalizationPopup(localizationComponant) {
        localizationComponant.closeAddEditModal();
    }
}
