import { PermissionType } from "./permission-type";
import { PermissionGroup } from "./permission-group";

export class Permission {
    permissionId?: number;
    permissionName: string;
    permissionControl: string;
    permissionTypeId?: number;
    permissionTypeName: string;
    url: string;
    permissionGroupId: string;
    permissionGroupName: string;
    permissionValue: boolean;
    isEnabled: boolean;
    isVisible: boolean;
    order: number;
    isExpress: boolean;
    isSideMenu: boolean;
    isMainMenu: boolean;
    isUserRoot: boolean;
    isCategory: boolean;
    parentId: number;
    parent: Permission;
    permissionType: PermissionType;
    permissionGroup: PermissionGroup;
    validation: boolean=false;
    isAllowCategoryInside: boolean;
    isAllowMenuInside: boolean;
    canDelete: boolean;
    icon: string;
}
