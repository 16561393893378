import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SignsService } from '@shared/services/signs.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorService, LanguageService } from '@shared/services';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { first, takeUntil } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';

import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SwPoiService } from '@shared/services/SwPoi.service';
import { SupplementService } from '@shared/services/Supplement.service';
import { Guid } from 'guid-typescript';
import { ServicesService } from '@shared/services/Service.service';
//#region Master Page action "Omar Design"
interface FilterObject {
    servicename?: string | null;
    servicedesription?: string | null;
    valid?: boolean | null;
    searchValue?: string | null;
}
//#endregion



@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, OnDestroy {

    //#region Master Page action "Omar Design"
    filterObj: FilterObject = {
        servicename: null,
        servicedesription: null,
        valid: null,
        searchValue: ''
    };
    applyAdvancedFilter() {
        debugger
        this.dataSource.filter = JSON.stringify(this.filterObj);
    }
    searchValueChange(searchValue) {
        debugger
        this.filterObj.searchValue = searchValue;
        this.applyAdvancedFilter();
    }
    resetAllFilters(){
        this.filterObj = {
            servicename: null,
            servicedesription: null,
            valid: null,
            searchValue: ''
        };
        this.applyAdvancedFilter();
    }
    statusList = [
        {
            label: 'Active',
            value: true
        },
        {
            label: 'InActive',
            value: false
        }
    ];
    //#endregion

    // subscription: Subscription = new Subscription();
    items: MenuItem[];
    private _unsubscribeAll$ = new Subject<boolean>();

    constructor(
        private translate: TranslateService,
        public router: Router,
        public route: ActivatedRoute,
        private modalService: BsModalService,
        private _service: ServicesService,
        private fb: FormBuilder,
        private _errorService: ErrorService,
        private _langService: LanguageService
    ) {}

    searchValue = '';
    changeNewContant = false;
    laddaSearchProgress: boolean | number = false;

    SupplementCategoriesList = [];

    @ViewChild('formAddEditModal', { static: true }) formModal: TemplateRef<any>;
    modalRef: BsModalRef | null;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    displayedColumns: string[] = ['select', 'servicename', 'description', 'valid'];
    dataSource = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);

    formType = 'add';
    EntityForm: FormGroup;
    saveContant = false;
    isBusy = false;

    @Input('openMode')
    openMode = 'masterFile'; // or popUp

    @Output('selectedResources')
    selectedResources: EventEmitter<any> = new EventEmitter<any>();

    ngOnDestroy() {
        // this.subscription.unsubscribe();
        if (this.modalRef) {
            this.modalRef.hide();
        }
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
    }

    Edit(row) {
        // this.formType = 'edit';
        // this.initForm();
        // const controls = this.EntityForm.controls;

        // Object.keys(controls).forEach(key => {

        //   if (row[key] != null) {
        //     this.EntityForm.get(key).setValue(row[key]);

        //   }

        // })
        // this.openModal();

        this.router.navigate(['service', row.serviceid]);
    }

    AddNewItem() {
        // this.formType = 'add';
        // this.initForm();
        // this.openModal();
        this.router.navigate(['service/1']);
    }

    deleteSelected() {
        if (this.selection.selected.length == 0) {
            this.newMessage(60, 'error');
        } else {
            this.newMessage(9, 'delete');
        }
    }
    ImgLabel: 'Photo';
    openModal() {
        const configs = {
            keyboard: false,
            ignoreBackdropClick: true,
            class: ' modal-lg modal-primary ModelLayout commenModel animated fadeInRight',
            animated: false
        };
        this.modalRef = this.modalService.show(this.formModal, configs);
    }
    closeAddEditModal() {
        if (!this.changeNewContant) {
            this.modalRef.hide();
        } else this.newMessage(144, 'ClosePopup');
    }
    ClosePopup() {
        this.modalRef.hide();
        this.changeNewContant = false;
    }

    ngOnInit() {
        this.items = [
            {
                label: this.translate.instant('Delete'),
                icon: 'pi pi-times',
                command: () => {
                    this.deleteSelected();
                }
            }
        ];

        this.GetAllActive();
        this.initForm();

        this.EntityForm.valueChanges.pipe(takeUntil(this._unsubscribeAll$)).subscribe((changes) => {
            this.changeNewContant = true;
        });

        this._errorService.currentAction
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((action) => this.actionMsg(action));

        this.selection.changed.next = (selectedResource) => {
            this.selectedResources.emit(selectedResource);
            // console.log(selectedResource);
        };
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    checkboxLabel(row): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    setTumbnail(data) {
        this.EntityForm.get('img').setValue(data);
    }
    setFileName(data) {
        this.EntityForm.get('img').setValue(data);
        this.changeNewContant = true;
    }
    private initForm(): void {
        var id = Guid.create();
        this.EntityForm = this.fb.group({
            serviceid: new FormControl(id.toString()),

            servicename: new FormControl(null, [Validators.required]),
            servicedesription: new FormControl(null),
            img: new FormControl(null),

            valid: new FormControl(true, [Validators.required])
        });
    }

    saveAddEdit() {
        this.laddaSearchProgress = 1;
        const controls = this.EntityForm.controls;
        Object.keys(controls).forEach((key) => {
            controls[key].markAsTouched();
        });

        if (this.EntityForm.status === 'VALID') {
            this.saveContant = false;

            if (this.formType == 'add') {
                this._service
                    .Add(this.EntityForm.value)
                    .pipe(takeUntil(this._unsubscribeAll$), first())
                    .subscribe(
                        (result) => {
                            if (result.isSuccess) {
                                // const dgwData = result.data;
                                this.newMessage(93, '');
                                this.changeNewContant = false;
                                this.closeAddEditModal();
                            } else {
                                this.newMessage(77, '');
                            }
                            this.GetAllActive();
                        },
                        (error) => {
                            this.laddaSearchProgress = false;
                        }
                    );
            } else {
                this._service
                    .Edit(this.EntityForm.value)
                    .pipe(takeUntil(this._unsubscribeAll$), first())
                    .subscribe(
                        (result) => {
                            if (result.isSuccess) {
                                // const dgwData = result.data;
                                this.newMessage(4, '');
                                this.changeNewContant = false;
                                this.closeAddEditModal();
                            } else {
                                this.newMessage(77, '');
                            }
                            this.GetAllActive();
                            this.laddaSearchProgress = false;
                            this.changeNewContant = false;
                            this.saveContant = false;
                        },
                        (error) => {
                            this.laddaSearchProgress = false;
                        }
                    );
            }
        } else {
            this.saveContant = true;
            window.scrollTo(0, 0);
            this.laddaSearchProgress = false;
            this.newMessage(3, '');
        }
        // this.modalRef.hide();
    }

    delete() {
        // console.log(this.selectedcapacity.map(obj => obj.unitid));
        this._service
            .DeleteList(this.selection.selected)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    if (result) {
                        this.newMessage(54, '');
                    } else {
                        this.newMessage(55, '');
                    }
                    this.selection = new SelectionModel(true, []);
                    this.GetAllActive();
                    this.laddaSearchProgress = false;
                    this.changeNewContant = false;
                    this.saveContant = false;
                },
                (error) => {
                    this.laddaSearchProgress = false;
                }
            );
    }

    changeStatus(data) {}

    actionMsg(data) {
        if (data !== '') {
            this[data]();
            this._errorService.action('');
        }
    }
    newMessage(id, action) {
        this._errorService.changeMessage({ id: id, action: action });
    }
    closeLocalizationPopup(localizationComponant) {
        localizationComponant.closeAddEditModal();
    }

    setCheckboxValue(event, atrr) {
        this.changeNewContant = true;
        // console.log(event.checked);
        if (event.checked) {
            this.EntityForm.get(atrr).setValue(true);
        } else {
            this.EntityForm.get(atrr).setValue(false);
        }
    }

    GetAllActive() {
        this.searchValue = '';
        this.isBusy = true;
        this._service
            .GetAllActive()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.dataSource = new MatTableDataSource(result.data);
                this.dataSource.sort = this.sort;
                this.isBusy = false;
                //#region Master Page action "Omar Design"
                this.dataSource.filterPredicate = function (data, filterObjectString: string): boolean {
                    debugger;
                    const filterObj: FilterObject = JSON.parse(filterObjectString);
                    const servicename = filterObj.servicename? filterObj.servicename.toLocaleLowerCase() : null;
                    const servicedesription = filterObj.servicedesription? filterObj.servicedesription.toLocaleLowerCase() : null;
                    const status = filterObj.valid;
                    const searchValue = filterObj.searchValue.trim().toLocaleLowerCase();
                    const dataStr = Object.keys(data)
                        .reduce((currentTerm: string, key: string) => {
                            return currentTerm + data[key] + '◬';
                        }, '')
                        ?.toLowerCase();

                    return (
                        (data.servicename?.toLowerCase().indexOf(servicename) !== -1 || servicename == null) &&
                        (data.servicedesription?.toLowerCase().indexOf(servicedesription) !== -1 || servicedesription == null) &&
                        (data.valid === status || status === null) &&
                        (dataStr.trim()?.toLowerCase().indexOf(searchValue) !== -1 || searchValue == '')
                    );
                };
                 //make default filter valid value only
                 this.filterObj.valid = true;
                 this.applyAdvancedFilter();
                 //#endregion
            });
    }


    
}
