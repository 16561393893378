import {
    Component,
    ViewChild,
    TemplateRef,
    OnDestroy,
    OnInit,
    AfterViewInit,
    Input,
    Output,
    EventEmitter,
    OnChanges
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BookingCalendar, BookingCalendarMultiResources } from '@shared/_models/bookingcalendar';
import { BookingService } from '@shared/services/booking.service';
import { first, takeUntil } from 'rxjs/operators';
import { jqxSchedulerComponent } from '../../../../assets/jqWidgetFiles/jqwidgets-ts/angular_jqxscheduler';
import { AddBookingDto } from '@shared/_models/AddBookingDto';
import { ResourcesService } from '@shared/services/resources.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TooltipPosition } from '@angular/material/tooltip';
import { ErrorService, BaseService } from '@shared/services';
import { MessageService } from '@shared/services/messageService';
import Swal from 'sweetalert2';
import { de } from 'date-fns/locale';
import { SwGenericAlertComponent } from '@shared/component/swAlerts/sw-generic-alert.component';
import * as moment from 'moment';
import { onClientMessagesService } from '@shared/services/onClientMessagesService.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-jqw-time-capture',
    templateUrl: './jqw-time-capture.component.html',
    styleUrls: ['./jqw-time-capture.component.scss']
})
export class JqwTimeCaptureComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    private _unsubscribeAll$ = new Subject<boolean>();

    @Input('resourceId')
    resourceId;
    @Input('bookingId')
    bookingId: any;

    @Output('spinner')
    spinner: EventEmitter<any> = new EventEmitter<any>();

    @Output('newDates')
    newDates: EventEmitter<any> = new EventEmitter<any>();

    todayDate = new jqx.date();
    selectedBooking: any;
    listOfSelectedDates = [];
    listOfBookedDates = [];
    showsearchbutton = true;
    showSideMenu = false;
    showDeleteButton = false;
    btnConfirmTextStart = 'Confrim Time Selection';
    btnConfirmTextEnd = 'Confrim End Date-Time Selection';
    btnConfirmResetText = 'Reset Selection';

    constructor(
        public router: Router,
        private _baseService: BaseService,
        private _messageService: MessageService,
        private _resourcesService: ResourcesService,
        private _errorService: ErrorService,
        private modalService: BsModalService,
        private _clientMessage: onClientMessagesService
    ) {
        if (localStorage.getItem('userLanguage') == '8eb12a2b-4ff7-464b-b02f-2df8b2fce8c0') {
            // this.router.navigate([ 'calender-ar' ]);     we solve the issue and ar comp not used now
            this.localization = {
                // separator of parts of a date (e.g. '/' in 11/05/1955)
                '/': '/',
                // separator of parts of a time (e.g. ':' in 05:44 PM)
                ':': ':',
                // the first day of the week (0 = Sunday, 1 = Monday, etc)
                firstDay: 0,
                days: {
                    // full day names
                    names: ['الأحد', 'الأثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
                    // abbreviated day names
                    namesAbbr: ['الأحد', 'الأثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
                    // shortest day names
                    namesShort: ['الأحد', 'الأثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت']
                },
                months: {
                    // full month names (13 months for lunar calendards -- 13th month should be '' if not lunar)
                    names: [
                        'يناير',
                        'فبراير',
                        'مارس',
                        'أبريل',
                        'مايو',
                        'يونيو',
                        'يوليو',
                        'أغسطس',
                        'سبتمبر',
                        'أكتوبر',
                        'نوفمبر',
                        'ديسمبر',
                        ''
                    ],
                    // abbreviated month names
                    namesAbbr: [
                        'يناير',
                        'فبراير',
                        'مارس',
                        'أبريل',
                        'مايو',
                        'يونيو',
                        'يوليو',
                        'أغسطس',
                        'سبتمبر',
                        'أكتوبر',
                        'نوفمبر',
                        'ديسمبر',
                        ''
                    ]
                },
                // AM and PM designators in one of these forms:
                // The usual view, and the upper and lower case versions
                //      [standard,lowercase,uppercase]
                // The culture does not use AM or PM (likely all standard date formats use 24 hour time)
                //      null
                AM: ['AM', 'am', 'AM'],
                PM: ['PM', 'pm', 'PM'],
                eras: [
                    // eras in reverse chronological order.
                    // name: the name of the era in this culture (e.g. A.D., C.E.)
                    // start: when the era starts in ticks (gregorian, gmt), null if it is the earliest supported era.
                    // offset: offset in years from gregorian calendar
                    { name: 'A.D.', start: null, offset: 0 }
                ],
                twoDigitYearMax: 2029,
                patterns: {
                    // short date pattern
                    d: 'M/d/yyyy',
                    // long date pattern
                    D: 'dddd, MMMM dd, yyyy',
                    // short time pattern
                    t: 'h:mm tt',
                    // long time pattern
                    T: 'h:mm:ss tt',
                    // long date, short time pattern
                    f: 'dddd, MMMM dd, yyyy h:mm tt',
                    // long date, long time pattern
                    F: 'dddd, MMMM dd, yyyy h:mm:ss tt',
                    // month/day pattern
                    M: 'MMMM dd',
                    // month/year pattern
                    Y: 'yyyy MMMM',
                    // S is a sortable format that does not lety by culture
                    S: 'yyyy\u0027-\u0027MM\u0027-\u0027dd\u0027T\u0027HH\u0027:\u0027mm\u0027:\u0027ss',
                    // formatting of dates in MySQL DataBases
                    ISO: 'yyyy-MM-dd hh:mm:ss',
                    ISO2: 'yyyy-MM-dd HH:mm:ss',
                    d1: 'dd.MM.yyyy',
                    d2: 'dd-MM-yyyy',
                    d3: 'dd-MMMM-yyyy',
                    d4: 'dd-MM-yy',
                    d5: 'H:mm',
                    d6: 'HH:mm',
                    d7: 'HH:mm tt',
                    d8: 'dd/MMMM/yyyy',
                    d9: 'MMMM-dd',
                    d10: 'MM-dd',
                    d11: 'MM-dd-yyyy'
                },
                backString: 'السابق',
                forwardString: 'القادم',
                toolBarPreviousButtonString: 'السابق',
                toolBarNextButtonString: 'القادم',
                emptyDataString: 'Nokeine Daten angezeigt',
                loadString: 'Loading...',
                clearString: 'حذف',
                todayString: 'اليوم',
                dayViewString: 'يومية',
                weekViewString: 'أسبوعية',
                monthViewString: 'شهرية',
                timelineDayViewString: 'Zeitleiste Day',
                timelineWeekViewString: 'Zeitleiste Woche',
                timelineMonthViewString: 'Zeitleiste Monat',
                loadingErrorMessage:
                    "Die Daten werden noch geladen und Sie können eine Eigenschaft nicht festgelegt oder eine Methode aufrufen . Sie können tun, dass, sobald die Datenbindung abgeschlossen ist. jqxScheduler wirft die ' bindingComplete ' Ereignis, wenn die Bindung abgeschlossen ist.",
                editRecurringAppointmentDialogTitleString: 'Bearbeiten Sie wiederkehrenden Termin',
                editRecurringAppointmentDialogContentString:
                    'Wollen Sie nur dieses eine Vorkommen oder die Serie zu bearbeiten ?',
                editRecurringAppointmentDialogOccurrenceString: 'Vorkommen bearbeiten',
                editRecurringAppointmentDialogSeriesString: 'Bearbeiten Die Serie',
                editDialogTitleString: 'Termin bearbeiten',
                editDialogCreateTitleString: 'Erstellen Sie Neuer Termin',
                contextMenuEditAppointmentString: 'Termin bearbeiten',
                contextMenuCreateAppointmentString: 'Erstellen Sie Neuer Termin',
                editDialogSubjectString: 'Subjekt',
                editDialogLocationString: 'Ort',
                editDialogFromString: 'Von',
                editDialogToString: 'Bis',
                editDialogAllDayString: 'Den ganzen Tag',
                editDialogExceptionsString: 'Ausnahmen',
                editDialogResetExceptionsString: 'Zurücksetzen auf Speichern',
                editDialogDescriptionString: 'Bezeichnung',
                editDialogResourceIdString: 'Kalender',
                editDialogStatusString: 'Status',
                editDialogColorString: 'Farbe',
                editDialogColorPlaceHolderString: 'Farbe wählen',
                editDialogTimeZoneString: 'Zeitzone',
                editDialogSelectTimeZoneString: 'Wählen Sie Zeitzone',
                editDialogSaveString: 'Sparen',
                editDialogDeleteString: 'Löschen',
                editDialogCancelString: 'Abbrechen',
                editDialogRepeatString: 'Wiederholen',
                editDialogRepeatEveryString: 'Wiederholen alle',
                editDialogRepeatEveryWeekString: 'woche(n)',
                editDialogRepeatEveryYearString: 'Jahr (en)',
                editDialogRepeatEveryDayString: 'Tag (e)',
                editDialogRepeatNeverString: 'Nie',
                editDialogRepeatDailyString: 'Täglich',
                editDialogRepeatWeeklyString: 'Wöchentlich',
                editDialogRepeatMonthlyString: 'Monatlich',
                editDialogRepeatYearlyString: 'Jährlich',
                editDialogRepeatEveryMonthString: 'Monate (n)',
                editDialogRepeatEveryMonthDayString: 'Day',
                editDialogRepeatFirstString: 'erste',
                editDialogRepeatSecondString: 'zweite',
                editDialogRepeatThirdString: 'dritte',
                editDialogRepeatFourthString: 'vierte',
                editDialogRepeatLastString: 'letzte',
                editDialogRepeatEndString: 'Ende',
                editDialogRepeatAfterString: 'Nach',
                editDialogRepeatOnString: 'Am',
                editDialogRepeatOfString: 'von',
                editDialogRepeatOccurrencesString: 'Eintritt (e)',
                editDialogRepeatSaveString: 'Vorkommen Speichern',
                editDialogRepeatSaveSeriesString: 'Save Series',
                editDialogRepeatDeleteString: 'Vorkommen löschen',
                editDialogRepeatDeleteSeriesString: 'Series löschen',
                editDialogStatuses: {
                    free: 'Frei',
                    tentative: 'Versuchsweise',
                    busy: 'Beschäftigt',
                    outOfOffice: 'Ausserhaus'
                }
            };
        }
    }
    ngOnDestroy(): void {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
    }
    ngAfterViewInit(): void {
        this.subscribeToJqxEvents();
        // this.generateAppointments();
    }
    ngOnChanges() {
        if (this.resourceId) {
            this.spinner.emit(true);
            this._baseService
                .getrestrictedSlotsInResource(this.resourceId, this.bookingId)
                .pipe(takeUntil(this._unsubscribeAll$), first())
                .subscribe((result) => {
                    this.listOfBookedDates = [];
                    const today = new Date();
                    const hours = today.getHours();
                    const day = today.getDate();
                    const month = today.getMonth() + 1;
                    const year = today.getFullYear();
                    const minutes = today.getMinutes() / 15;
                    let endTime = '00:00';
                    if (minutes < 1) {
                        endTime = hours + ':00';
                    }

                    if (minutes >= 1 && minutes < 2) {
                        endTime = hours + ':15';
                    }
                    if (3 > minutes && minutes >= 2) {
                        endTime = hours + ':30';
                    }
                    if (4 > minutes && minutes >= 3) {
                        endTime = hours + ':45';
                    }

                    let todyClose = {
                        id: this.resourceId,
                        description: '',
                        location: '',
                        subject: 'Unavailable Time',
                        calendar: 'Unavailable Time',
                        start: new Date(year + '-' + month + '-' + day + 'T' + '00:00'),
                        end: new Date(year + '-' + month + '-' + day + 'T' + endTime)

                        //start: new Date(dateList[0].date + dateList[0].time),
                        //end: new Date(dateList[1].date + dateList[1].time)
                    };
                    result.forEach((element) => {
                        element.restrictedTimeSlots.forEach((timeSlotElement) => {
                            // console.log(element.day.substring(0, 11) + timeSlotElement.substring(0, 5)),
                            //     console.log(
                            //         element.day.substring(0, 11) +
                            //             timeSlotElement.substring(
                            //                 timeSlotElement.indexOf('-') + 1,
                            //                 timeSlotElement.length
                            //             )
                            //     );

                            let appointment = {
                                id: this.resourceId,
                                description: '',
                                location: '',
                                bookingStatus: 'wp',
                                subject: 'Unavailable Time',
                                calendar: 'Unavailable Time',
                                start: new Date(element.day.substring(0, 11) + timeSlotElement.substring(0, 5)),
                                end: new Date(
                                    (
                                        element.day.substring(0, 11) +
                                        timeSlotElement.substring(
                                            timeSlotElement.indexOf('-') + 1,
                                            timeSlotElement.length
                                        )
                                    ).replace(/\s/g, '')
                                )
                            };
                            this.listOfBookedDates.push(appointment);
                        });
                    });
                    this.listOfBookedDates.push(todyClose);

                    // console.log(this.listOfBookedDates);
                    this.source = {
                        dataType: 'array',
                        dataFields: [
                            { name: 'id', type: 'string' },
                            { name: 'description', type: 'string' },
                            { name: 'location', type: 'string' },
                            { name: 'subject', type: 'string' },
                            { name: 'calendar', type: 'string' },
                            { name: 'start', type: 'date' },
                            { name: 'bookingStatus', type: 'string' },
                            { name: 'end', type: 'date' }
                        ],
                        id: 'id',
                        localData: this.listOfBookedDates
                        //localData: this.getcalendarbookingslist()
                    };

                    this.dataAdapter = new jqx.dataAdapter(this.source);
                    this.spinner.emit(false);
                });
        }
    }
    ngOnInit(): void {
        // if(this.resourceId){
        //   this.spinner.emit(true);
        //   this._baseService.get(`Bbooking/get-restrictedSlotsInResource/${this.resourceId}`).pipe(takeUntil(this._unsubscribeAll$), first()).subscribe(result => {
        //     this.listOfBookedDates = [];
        //     result.forEach(element => {
        //       element.restrictedTimeSlots.forEach(timeSlotElement => {
        //         console.log(element.day.substring(0,11 ) + timeSlotElement.substring(0,5)),
        //         console.log(element.day.substring(0,11 ) + timeSlotElement.substring(timeSlotElement.indexOf('-')+1,timeSlotElement.length))
        //         let  appointment = {
        //           id: this.resourceId,
        //           description: '',
        //           location: '',
        //           bookingStatus:'wp',
        //           subject: 'Unavailable Time',
        //           calendar: 'Unavailable Time',
        //           start: new Date(element.day.substring(0,11 ) + timeSlotElement.substring(0,5)),
        //           end: new Date((element.day.substring(0,11 ) + timeSlotElement.substring(timeSlotElement.indexOf('-')+1,timeSlotElement.length)).replace(/\s/g, ''))
        //         };
        //        this.listOfBookedDates.push(appointment)
        //       });
        //     });
        //     console.log(this.listOfBookedDates);
        //     this.source =
        //     {
        //       dataType: 'array',
        //       dataFields: [
        //         { name: 'id', type: 'string' },
        //         { name: 'description', type: 'string' },
        //         { name: 'location', type: 'string' },
        //         { name: 'subject', type: 'string' },
        //         { name: 'calendar', type: 'string' },
        //         { name: 'start', type: 'date' },
        //         { name: 'bookingStatus', type: 'string' },
        //         { name: 'end', type: 'date' }
        //       ],
        //       id: 'id',
        //       localData: this.listOfBookedDates
        //       //localData: this.getcalendarbookingslist()
        //     };
        //   this.dataAdapter = new jqx.dataAdapter(this.source);
        //   this.spinner.emit(false);
        //   });
        // }
        // this.events = this.generateAppointments();
        // this.source.localData = this.generateAppointments();
        // this.dataAdapter = new jqx.dataAdapter(this.source);
    }

    modalRef: BsModalRef | null;
    defultSelectVal;
    //showCalender: boolean = false;
    showCalender: boolean = true;
    ngSelectList: any[] = [];
    shouldEmpty: boolean = true;
    bookingsData: any[] = [];

    @ViewChild('schedulerReference', { static: true }) scheduler: jqxSchedulerComponent;
    today = new Date();
    source: any = {
        dataType: 'array',
        dataFields: [
            { name: 'id', type: 'string' },
            { name: 'description', type: 'string' },
            { name: 'location', type: 'string' },
            { name: 'subject', type: 'string' },
            { name: 'calendar', type: 'string' },
            { name: 'start', type: 'date' },
            { name: 'end', type: 'date' }
        ],
        id: 'id',
        localData: this.generateAppointments()
        //localData: this.getcalendarbookingslist()
    };

    dataAdapter: any = new jqx.dataAdapter(this.source);
    date: any = new jqx.date();

    appointmentDataFields: any = {
        from: 'start',
        to: 'end',
        id: 'id',
        description: 'description',
        location: 'location',
        subject: 'subject',
        calendar: 'calendar'
    };

    resources: any = {
        // colorScheme: 'scheme05',
        dataField: 'calendar',
        orientation: 'horizontal',
        source: new jqx.dataAdapter(this.source)
    };

    views: any[] = [
        {
            type: 'dayView',
            timeRuler: { scale: 'fifteenMinutes' },
            appointmentHeight: 50,
            allDayRowHeight: 30
        }
    ];

    localization = null;

    calendarbooking = new BookingCalendar();
    events = [];
    currentSelectedDate: any;
    selectedResourcesIdsList: any[] = [];

    renderAppointment = (data) => {
        // data Object properties
        // appointment - Object with the properties from the Scheduler.s source object.
        // width - int
        // height- int
        // textColor - hex
        // background - hex
        // borderColor - hex
        // style - string
        // cssClass - string
        // html - html string
        // view - string

        // console.log(data.appointment);
        if (data.view == 'weekView' || data.view == 'dayView' || data.view == 'monthView') {
            var userPerfFormat = localStorage.getItem('dateFormate');
            if (!userPerfFormat || userPerfFormat == '') userPerfFormat = 'DD/MM/YYYY';

            var dateVal = moment(Date.parse(data.appointment.from)).format(userPerfFormat);
            var fromVal = moment(Date.parse(data.appointment.from)).format('HH:mm');
            var toVal = moment(Date.parse(data.appointment.to)).format('HH:mm');
            var hoverVal = `"${data.appointment.subject}\n ${dateVal} \n ${fromVal} - ${toVal} \n ${
                data.appointment.resourceName
            } \n ${this._clientMessage.getMessage(data.appointment.bookingStatus)} "`;

            data.html = '<i title=' + hoverVal + '>' + data.appointment.subject + '</i>';

            if (data.appointment.subject == 'Unavailable Time') {
                data.style = '#B5B5B5';
            }

            if (data.appointment.subject != 'Unavailable Time') {
                data.style = '#F27B11';
            }
        }
        return data;
    };

    info: any = '--';
    infoEnd: any = '--';
    subscribeToJqxEvents() {
        var contextMenuCreate = (menu, settings) => {
            var source = settings.source;
            source.push({ id: 'edit', label: 'Edit Booking' });
            source.push({ id: 'delete', label: 'Delete This Booking' });
            source.push({ id: 'deleteRecurence', label: 'Delete All Recurence Booking' });

            if (source.length > 2) source.splice(0, 2);
        };
        this.scheduler.contextMenuCreate(contextMenuCreate);

        var contextMenuOpen = (menu, appointment, event) => {
            menu.hide();
        };
        this.scheduler.contextMenuOpen(contextMenuOpen);

        // this.scheduler.ensureAppointmentVisible('id1');
        var openDialogEve = this.scheduler.onEditDialogOpen;
        openDialogEve.pipe(takeUntil(this._unsubscribeAll$)).subscribe((d) => {
            if (d.args.dialog) d.args.dialog[0].style.display = 'none';
        });

        var cellDoubleClicked = this.scheduler.onCellDoubleClick;
        cellDoubleClicked.pipe(takeUntil(this._unsubscribeAll$)).subscribe((d) => {});

        var onBookingChanges = this.scheduler.onAppointmentChange;
        onBookingChanges.pipe(takeUntil(this._unsubscribeAll$)).subscribe((d) => {
            //this.scheduler.deleteAppointment(d.args.appointment.id);
            // var dto = new BookingCalendarMultiResources();
            // dto.FromTime = this.calendarbooking.FromTime;
            // dto.ToTime = this.calendarbooking.ToTime;
            // dto.ResIds = this.selectedResourcesIdsList;
        });

        var calDateChange = this.scheduler.onDateChange;
        calDateChange.pipe(takeUntil(this._unsubscribeAll$)).subscribe((d) => {
            // console.log(d.args.date);
            // console.log(moment(  Date.parse(d.args.date)).format("DD/MM/YYYY")  );
            this.date = d.args.date;
        });

        var CellClick = this.scheduler.onCellClick;
        CellClick.pipe(takeUntil(this._unsubscribeAll$)).subscribe((d) => {
            //  var selection =  this.scheduler.getSelection();
            //  console.log(selection);

            var ss = this.scheduler.getSelection();

            var startDate = moment(Date.parse(this.date)).format('YYYY-MM-DD') + 'T00:00:00';
            var fromVal = moment(Date.parse(ss.from)).format('HH:mm');
            var toVal = moment(Date.parse(ss.to)).format('HH:mm');
            // console.log(startDate);

            if (toVal == '00:00') {
                const newDate = new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1));
                const newday = newDate.getDate();
                const newMonth = newDate.getMonth() + 1;
                const newYear = newDate.getFullYear();
                startDate = newYear + '-' + newMonth + '-' + newday + 'T00:00:00';
            }

            // console.log(startDate);
            // console.log(fromVal);
            // console.log(toVal);
            // console.log(
            //     this.checkTimeDefrence(
            //         moment(Date.parse(this.date)).format('YYYY-MM-DD') + 'T' + fromVal,
            //         moment(Date.parse(this.date)).format('YYYY-MM-DD') + 'T' + toVal
            //     )
            // );

            if (this.listOfSelectedDates.length == 1) {
                this.listOfSelectedDates.push({ date: startDate, time: toVal });
                this.checkDates(this.listOfSelectedDates);
            } else {
                this.listOfSelectedDates = [];
                this.listOfSelectedDates.push({ date: startDate, time: fromVal });
            }
        });
    }
    checkTimeDefrence(startTime, endTime) {
        var start = new Date(startTime);
        var end = new Date(endTime);
        var diffMs = end.getTime() - start.getTime();

        var defrence = Math.round(diffMs / 60000);

        return defrence;
    }
    newMessage(id, action) {
        this._errorService.changeMessage({ id: id, action: action });
    }
    checkDates(dateList) {
        this.spinner.emit(true);

        this._baseService
            .get(
                `Bbooking/can-book-here/${this.bookingId}/${this.resourceId}/${dateList[0].date}/${dateList[1].date}/${dateList[0].time}/${dateList[1].time}`
            )
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.spinner.emit(false);

                if (result) {
                    const findBooking = (element) => element.subject == 'new Booking';
                    const bookingIndex = this.listOfBookedDates.findIndex(findBooking);
                    if (bookingIndex > -1) {
                        this.listOfBookedDates.splice(bookingIndex, 1);
                    }

                    let appointment = {
                        id: this.resourceId,
                        description: '',
                        location: '',
                        bookingStatus: 'wp',
                        subject: 'new Booking',
                        start: new Date(dateList[0].date.substring(0, 11) + dateList[0].time),
                        end: new Date(dateList[1].date.substring(0, 11) + dateList[1].time)

                        //start: new Date(dateList[0].date + dateList[0].time),
                        //end: new Date(dateList[1].date + dateList[1].time)
                    };
                    this.listOfBookedDates.push(appointment);
                    this.source = {
                        dataType: 'array',
                        dataFields: [
                            { name: 'id', type: 'string' },
                            { name: 'description', type: 'string' },
                            { name: 'location', type: 'string' },
                            { name: 'subject', type: 'string' },
                            { name: 'calendar', type: 'string' },
                            { name: 'start', type: 'date' },
                            { name: 'bookingStatus', type: 'string' },
                            { name: 'end', type: 'date' }
                        ],
                        id: 'id',
                        localData: this.listOfBookedDates
                        //localData: this.getcalendarbookingslist()
                    };

                    this.dataAdapter = new jqx.dataAdapter(this.source);
                    this.setSelectedDates();
                } else {
                    // false message
                    this.newMessage(100, '');
                }
            });
    }
    isSelectable: boolean = false;
    applySelection() {
        // console.log(this.scheduler.getSelection());
        var ss = this.scheduler.getSelection();

        var startDate = moment(Date.parse(this.date)).format('DD-MM-YYYY');
        var fromVal = moment(Date.parse(ss.from)).format('HH:mm');
        var toVal = moment(Date.parse(ss.to)).format('HH:mm');

        var btn = document.getElementById('btnSelect');

        if (btn.innerText === this.btnConfirmTextStart) {
            this.info = ' ' + startDate + '   ' + fromVal;
            this.info = fromVal;
            this.infoEnd = ' ' + startDate + '   ' + toVal;
            this.infoEnd = toVal;
            btn.innerText = this.btnConfirmResetText;
            this.isSelectable = true;
        } else if (btn.innerText === this.btnConfirmResetText) {
            this.info = '--';
            this.infoEnd = '--';
            btn.innerText = this.btnConfirmTextStart;
            this.isSelectable = false;
            this.scheduler.clearSelection();
        }
    }

    setEmptyCalenderEvents() {
        const newEventList = [];

        const event = {
            id: null,
            description: null,
            location: null,
            subject: null,
            calendar: '--',
            start: null,
            end: null
        };

        event.start = new Date('01/01/1970');
        event.subject = 'empty';
        event.end = new Date('01/01/1970');
        event.id = '1';
        event.calendar = '--';
        newEventList.push(event);

        this.showCalender = true;
        return newEventList;
    }

    getWidth(): any {
        if (document.body.offsetWidth < 850) {
            return '30%';
        }

        return 850;
    }

    generateAppointments(): any {
        const appointments = new Array();
        const appointment1 = {
            id: 'id1',
            description: 'George brings projector for presentations.',
            location: '',
            subject: 'Quarterly Project Review Meeting',
            calendar: 'Room 1',
            start: new Date(2021, 12, 9, 9, 0, 0),
            end: new Date(2021, 12, 9, 10, 0, 0)
        };
        const appointment2 = {
            id: 'id2',
            description: '',
            location: '',
            subject: 'IT Group Mtg.',
            calendar: 'Room 1',
            start: new Date(2020, 10, 24, 10, 0, 0),
            end: new Date(20202, 10, 24, 15, 0, 0)
        };
        const appointment3 = {
            id: 'id3',
            description: '',
            location: '',
            subject: 'Course Social Media',
            calendar: 'Room 1',
            start: new Date(2020, 10, 27, 11, 0, 0),
            end: new Date(2020, 10, 27, 13, 0, 0)
        };
        const appointment4 = {
            id: 'id4',
            description: '',
            location: '',
            subject: 'New Projects Planning',
            calendar: 'Room 1',
            start: new Date(2020, 10, 23, 16, 0, 0),
            end: new Date(2020, 10, 23, 18, 0, 0)
        };
        const appointment5 = {
            id: 'id5',
            description: '',
            location: '',
            subject: 'Interview with James',
            calendar: 'Room 1',
            start: new Date(2020, 10, 25, 15, 0, 0),
            end: new Date(2020, 10, 25, 17, 0, 0)
        };
        const appointment6 = {
            id: 'id6',
            description: '',
            location: '',
            subject: 'Interview with Nancy',
            calendar: 'Room 1',
            start: new Date(2020, 10, 26, 14, 0, 0),
            end: new Date(2020, 10, 26, 16, 0, 0)
        };
        const appointment7 = {
            id: 'id6',
            description: '',
            location: '',
            subject: 'Interview with Nancy',
            calendar: 'Room 1',
            start: new Date(2020, 10, 26, 14, 0, 0),
            end: new Date(2020, 10, 26, 16, 0, 0)
        };
        appointments.push(appointment1);
        appointments.push(appointment2);
        appointments.push(appointment3);
        appointments.push(appointment4);
        appointments.push(appointment5);
        appointments.push(appointment6);
        appointments.push(appointment7);

        return appointments;
    }

    // called when the dialog is craeted.
    editDialogCreate = (dialog, fields, editAppointment) => {
        if (!fields || !dialog) return;
        // Hide built in dialog
        dialog[0].style.display = 'none';
        dialog[0].style.zIndex = -10000;
        dialog[0].className = 'd-none';
    };
    setSelectedDates() {
        if (this.listOfSelectedDates.length == 2) {
            // ${dateList[0].date}/${this.listOfSelectedDates[1].date}/${this.listOfSelectedDates[0].time}/${this.listOfSelectedDates[1].time}
            const newDates = {
                startDate: this.listOfSelectedDates[0].date,
                startTime: this.listOfSelectedDates[0].time,
                endDate: this.listOfSelectedDates[1].date,
                endTime: this.listOfSelectedDates[1].time
            };
            this.newDates.emit(newDates);
        }
    }
}
