import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ResourcesService } from '@shared/services/resources.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorService, UsersService } from '@shared/services';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { first, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { LocalizationComponent } from 'app/layout/localization/localization.component';
import { Guid } from 'guid-typescript';
import { MenuItem } from 'primeng/api';
interface FilterObject {
    name?: string | null;
    assetcode?: string | null;
    active?: boolean | null;
    searchValue?: string | null;
}
@Component({
    selector: 'app-fixed-assets',
    templateUrl: './fixed-assets.component.html',
    styleUrls: ['./fixed-assets.component.scss']
})
export class FixedAssetsComponent implements OnInit, OnDestroy {
    filterObj: FilterObject = {
        name: null,
        assetcode: null,
        active: null,
        searchValue: ''
    };
    applyAdvancedFilter() {
        debugger
        this.dataSource.filter = JSON.stringify(this.filterObj);
    }
    searchValueChange(searchValue) {
        debugger
        this.filterObj.searchValue = searchValue;
        this.applyAdvancedFilter();
    }
    items: MenuItem[];
    loading = true;
    constructor(
        private translate: TranslateService,
        public router: Router,
        public route: ActivatedRoute,
        private modalService: BsModalService,
        private _resourceService: ResourcesService,
        private fb: FormBuilder,
        private _errorService: ErrorService
    ) {}
    private _unsubscribeAll$ = new Subject<boolean>();

    searchValue = '';
    changeNewContant = false;
    laddaSearchProgress: boolean | number = false;
    newasset = false;
    assetsList = [];
    @Input('masterFile')
    masterFile = true;
    @Output('selectedAssets')
    selectedAssets: EventEmitter<any> = new EventEmitter<any>();
    formType = 'add';
    @ViewChild('formAddEditModal', { static: true }) formModal: TemplateRef<any>;
    modalRef: BsModalRef | null;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    displayedColumns: string[] = ['select', 'name', 'assetcode', 'isvalid'];
    dataSource = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);
    currentContact;
    selectedassets = [];
    assetForm: FormGroup;
    saveContant = false;
    fixedassetId = '1';
    cultureobj = [];
    list = [];

    selectedStatus = 'Active';
    //statusList = [{ name: 'All' }, { name: 'Active' }, { name: 'Inactive' }];
    statusList = [
        {
            label: 'Active',
            value: true
        },
        {
            label: 'InActive',
            value: false
        }
    ];
    actionStatus = null;
    actionsList = [{ name: 'Action' }, { name: 'Activate' }, { name: 'Delete' }, { name: 'Inactivate' }];
    selectAction() {
        if (this.actionStatus === 'Activate') {
            this.changeStatus('A');
        }
        if (this.actionStatus === 'Delete') {
            this.delete();
        }
        if (this.actionStatus === 'Inactivate') {
            this.changeStatus('NA');
        }
        this.actionStatus = null;
    }

    setCheckboxValue(event, atrr) {
        this.changeNewContant = true;
        if (event.checked) {
            this.assetForm.get(atrr).setValue(true);
        } else {
            this.assetForm.get(atrr).setValue(false);
        }
    }

    changeContant() {
        this.selectedAssets.emit(this.selection.selected);
    }
    @ViewChild('nameLocalization') nameLocalization: LocalizationComponent;
    //@ViewChild(LocalizationComponent) culturname: LocalizationComponent;
    saveCult() {
        // this.cult.saveAddEditGeneric(this.list);
    }
    GetCultureobj(obj) {
        obj.forEach((element) => {
            this.cultureobj.push(element);
        });
    }
    canAddCult = false;

    deleteSelected() {
        if (this.selection.selected.length == 0) {
            this.newMessage(60, 'error');
        } else {
            this.newMessage(9, 'delete');
        }
    }
    applyfilterStatus() {
        if (this.selectedStatus === 'Active') {
            this.filterStatus(true);
        }
        if (this.selectedStatus === 'Inactive') {
            this.filterStatus(false);
        }
        if (this.selectedStatus === 'All') {
            this.filterStatus('');
        }
    }
    Edit(row) {
        this.formType = 'edit';
        this.canAddCult = true;
        this.initForm();
        this.getById(row.fixedassetid);
        this.openModal();
    }
    AddNewItem() {
        this.formType = 'add';
        this.canAddCult = false;
        this.initForm();
        this.openModal();
    }

    openModal() {
        this.cultureobj = [];
        this.list = [];
        const configs = {
            keyboard: false,
            ignoreBackdropClick: true,
            class: ' modal-lg modal-primary ModelLayout commenModel animated fadeInRight',
            animated: false
        };
        this.modalRef = this.modalService.show(this.formModal, configs);
    }
    closeAddEditModal() {
        if (!this.changeNewContant) {
            this.newasset = false;
            this.modalRef.hide();
        } else this.newMessage(144, 'ClosePopup');
    }

    ClosePopup() {
        this.newasset = false;
        this.modalRef.hide();
        this.changeNewContant = false;
    }

    filterStatus(status) {
        const newList = [];
        this.dataSource = new MatTableDataSource(this.assetsList);

        if (status === '') {
        } else {
            this.dataSource.filteredData.forEach((element) => {
                if (element.isvalid === status) {
                    newList.push(element);
                }
            });
            this.dataSource = new MatTableDataSource(newList);
        }
        this.dataSource.sort = this.sort;

        this.applyFilter(this.searchValue);
    }
    getById(contactId) {
        this._resourceService
            .getfixedassetbyid(contactId)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    this.currentContact = result.data;
                    const controls = this.assetForm.controls;
                    Object.keys(controls).forEach((key) => {
                        if (this.currentContact[key] != null) {
                            this.assetForm.get(key).setValue(this.currentContact[key]);
                        }
                        this.changeNewContant = false;
                    });
                },
                (error) => {}
            );
    }

    ngOnInit() {
        this.items = [
            // {label: 'Update', icon: 'pi pi-refresh', command: () => {
            //     this.update();
            // }},
            {
                label: this.translate.instant('Delete'),
                //icon: 'pi pi-times',
                command: () => {
                    this.deleteSelected();
                }
            }
            // {label: 'Angular.io', icon: 'pi pi-info', url: 'http://angular.io'},
            // {separator:true},
            // {label: 'Setup', icon: 'pi pi-cog', routerLink: ['/setup']}
        ];

        this.getList();
        this.initForm();
        this.assetForm.valueChanges.pipe(takeUntil(this._unsubscribeAll$)).subscribe((changes) => {
            this.changeNewContant = true;
        });

        this._errorService.currentAction
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((action) => this.actionMsg(action));
    }
    // subscription: Subscription = new Subscription();

    ngOnDestroy() {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
        // this.subscription.unsubscribe();
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }
    getList() {
        this.searchValue = '';
        this._resourceService
            .getfixedassets()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.assetsList = result.data;
                this.dataSource = new MatTableDataSource(this.assetsList);
                this.dataSource.sort = this.sort;
                this.loading = false;
                this.applyfilterStatus();
                this.dataSource.filterPredicate = function (data, filterObjectString: string): boolean {
                    debugger;
                    const filterObj: FilterObject = JSON.parse(filterObjectString);
                    const name = filterObj.name? filterObj.name.toLocaleLowerCase() : null;
                    const assetcode = filterObj.assetcode? filterObj.assetcode.toLocaleLowerCase() : null;
                    const status = filterObj.active;
                    const searchValue = filterObj.searchValue.trim().toLocaleLowerCase();
                    const dataStr = Object.keys(data)
                        .reduce((currentTerm: string, key: string) => {
                            return currentTerm + data[key] + '◬';
                        }, '')
                        ?.toLowerCase();

                    return (
                        (data.name?.toLowerCase().indexOf(name) !== -1 || name == null) &&
                        (data.assetcode?.toLowerCase().indexOf(assetcode) !== -1 || assetcode == null) &&
                        (data.isvalid === status || status === null) &&
                        (dataStr.trim()?.toLowerCase().indexOf(searchValue) !== -1 || searchValue == '')
                    );
                };
                 //make default filter valid value only
                 this.filterObj.active = true;
                 this.applyAdvancedFilter();
            });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    checkboxLabel(row): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    private initForm(): void {
        const id = Guid.create();
        this.assetForm = this.fb.group({
            fixedassetid: new FormControl(id.toString()),
            assetcode: new FormControl(null, [Validators.required]),
            icon: new FormControl(null),
            photo: new FormControl(null),
            description: new FormControl(null),
            name: new FormControl(null, [Validators.required]),
            isvalid: new FormControl(true)
        });
    }
    setTumbnail(data) {
        this.assetForm.get('photo').setValue(data);
    }

    setFileName(data) {
        // console.log(data);
        this.assetForm.get('photo').setValue(data);
        this.changeNewContant = true;
    }
    seticonName(data) {
        // console.log(data);
        this.assetForm.get('icon').setValue(data);
        this.changeNewContant = true;
    }
    saveAddEdit() {
        this.laddaSearchProgress = 1;
        const controls = this.assetForm.controls;
        Object.keys(controls).forEach((key) => {
            controls[key].markAsTouched();
        });
        if (this.assetForm.status === 'VALID') {
            this.saveContant = false;
            if (this.formType == 'add') {
                this._resourceService
                    .addfixedasset(this.assetForm.value)
                    .pipe(takeUntil(this._unsubscribeAll$), first())
                    .subscribe(
                        (result) => {
                            if (result.isSuccess) {
                                // const dgwData = result.data;

                                //  this.cultureobj.forEach(element => {
                                //   element.masterItemId
                                //   =result.data
                                //   this.list.push(element);

                                // });
                                //   this.culturname.saveAddEditGeneric(this.cultureobj);
                                this.newMessage(93, '');
                                this.changeNewContant = false;
                                this.closeAddEditModal();
                            } else {
                                this.changeNewContant = false;
                                this.newMessage(77, '');
                            }
                            this.laddaSearchProgress = false;
                            this.getList();
                        },
                        (error) => {
                            this.laddaSearchProgress = false;
                        }
                    );
            } else {
                this._resourceService
                    .editfixedasset(this.assetForm.value)
                    .pipe(takeUntil(this._unsubscribeAll$), first())
                    .subscribe(
                        (result) => {
                            if (result.isSuccess) {
                                // const dgwData = result.data;
                                this.newMessage(4, '');
                                this.changeNewContant = false;
                                this.closeAddEditModal();
                            } else {
                                this.changeNewContant = false;
                                this.newMessage(77, '');
                            }
                            this.getList();
                            this.laddaSearchProgress = false;
                            this.changeNewContant = false;
                            this.saveContant = false;
                        },
                        (error) => {
                            this.laddaSearchProgress = false;
                        }
                    );
            }
        } else {
            this.saveContant = true;
            window.scrollTo(0, 0);
            this.laddaSearchProgress = false;
            this.changeNewContant = false;
            this.newMessage(3, '');
        }
        // this.modalRef.hide();
    }

    delete() {
        this._resourceService
            .deletefixedassets(this.selection.selected.map((obj) => obj.fixedassetid))
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    if (result.isSuccess) {
                        this.newMessage(54, '');
                    } else {
                        this.newMessage(55, '');
                    }
                    this.selection = new SelectionModel(true, []);
                    this.getList();
                    this.laddaSearchProgress = false;
                    this.changeNewContant = false;
                    this.saveContant = false;
                },
                (error) => {
                    this.laddaSearchProgress = false;
                }
            );
    }

    changeStatus(data) {}
    actionMsg(data) {
        if (data !== '') {
            this[data]();
            this._errorService.action('');
        }
    }
    newMessage(id, action) {
        this._errorService.changeMessage({ id: id, action: action });
    }
    closeLocalizationPopup(localizationComponant) {
        localizationComponant.closeAddEditModal();
    }
    resetAllFilters(){
        this.filterObj = {
            name: null,
            assetcode: null,
            active: null,
            searchValue: ''
        };
        this.applyAdvancedFilter();
    }
}
