import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService, ErrorService } from '@shared/services';
import { RoleService } from '@shared/services/RoleService.service';
import { UserRoleService } from '@shared/services/UserRole.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { isMaster } from 'cluster';
import { E } from '@angular/cdk/keycodes';
import { element } from 'protractor';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { ResourcesService } from '@shared/services/resources.service';
@Component({
    selector: 'app-user-security',
    templateUrl: './user-security.component.html',
    styleUrls: ['./user-security.component.scss']
})
export class UserSecurityComponent implements OnInit, OnDestroy {
    private _unsubscribeAll$ = new Subject<boolean>();
    contactId = '1';
    sub: any;
    RoleList = [];
    items: MenuItem[];
    // subscription: Subscription = new Subscription();

    @ViewChild(MatSort, { static: true }) sort1: MatSort;
    @Input('masterFile')
    masterFile = true;
    @Input('CurrentResourceRoles')
    CurrentResourceRoles = [];

    @Output('SelectedRoles')
    SelectedRoles: EventEmitter<any> = new EventEmitter<any>();
    ngOnDestroy() {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
        // this.subscription.unsubscribe();
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }
    constructor(
        public router: Router,
        public _resourcesService: ResourcesService,
        public route: ActivatedRoute,
        private modalService: BsModalService,
        private translate: TranslateService,
        private _baseService: BaseService,
        private _service: UserRoleService,
        private _Roleservice: RoleService,
        private fb: FormBuilder,
        private _errorService: ErrorService
    ) {
        this.sub = this.route.params.pipe(takeUntil(this._unsubscribeAll$)).subscribe((params) => {
            this.contactId = params['contactId'];
            if (this.contactId !== '1') {
                this.getAllList();

                if (this.masterFile == true && this.contactId) {
                    this.getList();
                }

                this.changeNewContant = false;
            } else {
            }
        });
    }
    getFavList() {
        this._resourcesService
            .getFavList()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                if (result.data != null) {
                    if (result.data.length != 0)
                        localStorage.setItem('lastSelectedResources', JSON.stringify(result.data));
                }
            });
    }
    isAllSelected2() {
        if (this.PopUpSelection.selected.length > 0) {
            this.showValidateEmptyList = false;
            this.changeNewContant = true;
        }
        const numSelected = this.PopUpSelection.selected.length;
        const numRows = this.PopUpDataSource.data.length;
        return numSelected === numRows;
    }
    deleteSelected() {
        if (this.selection.selected.length == 0) {
            this.newMessage(60, 'error');
        } else {
            this.newMessage(9, 'delete');
        }
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle2() {
        if (this.PopUpSelection.selected.length > 0) {
            this.showValidateEmptyList = false;
            this.changeNewContant = true;
        }
        this.isAllSelected2()
            ? this.PopUpSelection.clear()
            : this.PopUpDataSource.data.forEach((row) => this.PopUpSelection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel2(row?: any): string {
        if (this.PopUpSelection.selected.length > 0) {
            this.showValidateEmptyList = false;
        }
        if (!row) {
            return `${this.isAllSelected2() ? 'select' : 'deselect'} all`;
        }
        return `${this.PopUpSelection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }
    searchValue = '';
    changeNewContant = false;
    laddaSearchProgress: boolean | number = false;

    UserRoleList = [];

    @ViewChild('formAddEditModal', { static: true }) formModal: TemplateRef<any>;
    modalRef: BsModalRef | null;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    displayedColumns: string[] = ['select', 'rolename'];
    displayedColumns1: string[] = ['select', 'rolename', 'description'];

    dataSource = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);
    PopUpDataSource = new MatTableDataSource([]);
    PopUpSelection = new SelectionModel(true, []);
    currentSigns = [];
    currentSign;

    EntityForm: FormGroup;
    saveContant = false;

    formType = 'add';

    showValidateEmptyList = false;
    saveAddEdit() {
        if (this.masterFile == false) {
            //this.SelectedRoles.emit(this.list);

            this.ClosePopup();
            return;
        }
        this.laddaSearchProgress = 1;

        if (this.PopUpSelection.selected.length > 0) {
            this.saveContant = false;
            this.showValidateEmptyList = false;

            this._service
                .addUserRole(this.PopUpSelection.selected, this.contactId)
                .pipe(takeUntil(this._unsubscribeAll$), first())
                .subscribe(
                    (result) => {
                        if (result) {
                            this.newMessage(112, '');
                            this.laddaSearchProgress = false;
                            this.changeNewContant = false;
                            this.closeAddEditModal();
                            this.getList();
                            //this.PopUpSelection = new SelectionModel(true, []);
                        } else {
                            this.newMessage(77, '');
                            this.laddaSearchProgress = false;
                        }
                    },
                    (error) => {
                        this.laddaSearchProgress = false;
                    }
                );
        } else {
            this.showValidateEmptyList = true;
        }
    }

    AddNewItem() {
        this.formType = 'add';
        this.initForm();
        this.laddaSearchProgress = false;
        this.openModal();
        this.PopUpSelection = new SelectionModel(true, []);
    }

    openModal() {
        if (this.masterFile == false) {
            if (
                this.CurrentResourceRoles &&
                this.CurrentResourceRoles != null &&
                this.CurrentResourceRoles.length > 0
            ) {
                this.PopUpDataSource.data.forEach((element) => {
                    var check = this.CurrentResourceRoles.find((e) => e == element.roleId);
                    if (check) this.PopUpSelection.select(element);
                });
            }
        }

        const configs = {
            keyboard: false,
            ignoreBackdropClick: true,
            class: ' modal-lg modal-primary ModelLayout commenModel animated fadeInRight',
            animated: false
        };
        this.modalRef = this.modalService.show(this.formModal, configs);
    }
    closeAddEditModal() {
        if (!this.changeNewContant) {
            this.laddaSearchProgress = false;
            this.modalRef.hide();
            this.PopUpSelection = new SelectionModel(true, []);
        } else this.newMessage(144, 'ClosePopup');
    }
    ClosePopup() {
        this.laddaSearchProgress = false;
        this.modalRef.hide();
        if (this.masterFile) this.PopUpSelection = new SelectionModel(true, []);

        this.changeNewContant = false;
    }
    list = [];

    ngOnInit() {
        this.items = [
            // {label: 'Update', icon: 'pi pi-refresh', command: () => {
            //     this.update();
            // }},
            {
                label: this.translate.instant('Delete'),
                icon: 'pi pi-times',
                command: () => {
                    this.deleteSelected();
                }
            }
            // {label: 'Angular.io', icon: 'pi pi-info', url: 'http://angular.io'},
            // {separator:true},
            // {label: 'Setup', icon: 'pi pi-cog', routerLink: ['/setup']}
        ];
        if (this.contactId !== '1') {
            this.getAllList();

            if (this.masterFile == true && this.contactId) {
                this.getList();
            }
        }

        this.initForm();
        this.EntityForm.valueChanges.pipe(takeUntil(this._unsubscribeAll$)).subscribe((changes) => {
            this.changeNewContant = true;
        });

        this._errorService.currentAction
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((action) => this.actionMsg(action));

        if (this.masterFile == false) {
            this.PopUpSelection.changed.next = (val) => {
                this.SelectedRoles.emit(val);
            };
        }
    }
    actionMsg(data) {
        if (data !== '') {
            this[data]();
            this._errorService.action('');
        }
    }
    newMessage(id, action) {
        this._errorService.changeMessage({ id: id, action: action });
    }
    getList() {
        this.searchValue = '';
        this.searchValPopup = '';
        this._service
            .getAllByContactId(this.contactId)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.UserRoleList = result.data;
                this.dataSource = new MatTableDataSource(this.UserRoleList);
                this.dataSource.sort = this.sort;
                this.filterpermission();
                this.PopUpDataSource.sort = this.sort1;
            });
    }
    FilterRolelist = [];
    getAllList() {
        this.searchValue = '';
        this.searchValPopup = '';
        this._Roleservice
            .getAllActive()
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe((result) => {
                this.RoleList = result.data;
                if (this.masterFile == false) {
                    this.PopUpDataSource = new MatTableDataSource(this.RoleList);
                }
            });
    }
    filterpermission() {
        this.FilterRolelist = [];
        var list = this.RoleList.filter((item) => !this.UserRoleList.find((e) => e.roleId == item.roleId));
        this.FilterRolelist = list;
        this.PopUpDataSource = new MatTableDataSource(this.FilterRolelist);
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    checkboxLabel(row): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email + 1}`;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    applyFilter1(filterValue: string) {
        this.PopUpDataSource.filter = filterValue.trim().toLowerCase();
    }

    private initForm(): void {
        this.EntityForm = this.fb.group({
            roleId: new FormControl(null),
            userid: new FormControl(null, [Validators.required])
        });
    }

    delete() {
        this._service
            .deleteList(this.selection.selected)
            .pipe(takeUntil(this._unsubscribeAll$), first())
            .subscribe(
                (result) => {
                    if (result) {
                        this.getList();
                        this.getFavList();
                        this.newMessage(54, '');
                    } else {
                        this.newMessage(55, '');
                    }
                    this.selection = new SelectionModel(true, []);
                },
                (error) => {}
            );
    }

    lastDir = 'asc';
    applyManualSort(id: string, direction: string) {
        var lst = this._baseService.applyManualSort(id, direction, this.PopUpDataSource.data);
        this.lastDir = direction;
        this.PopUpDataSource = new MatTableDataSource(lst);
    }

    searchValPopup = '';
}
