import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { calendar } from 'ngx-bootstrap/chronos/moment/calendar';
import { BookingCalendar, BookingCalendarMultiResources } from '../_models';
import { Result, TaskType } from '../_models';
import { environment } from '../../../environments/environment';
import { IResource } from '@shared/_models/resource-model';

@Injectable({
    providedIn: 'root'
})
export class BookingService {
    private canonicalName: string;
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;
    APIURL = '';
    constructor(private http: HttpClient) {
        const newurl = window.location.href;

        if (this.isOnPremise) this.APIURL = this.appUrl;
        else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
        }

        if (this.APIURL.search('localhost') > -1) {
            //this.APIURL ='https://main.api.sharewinds.com/api/v1/';
            this.APIURL = environment.localHostUrl;
        }

        //this.APIURL = 'http://localhost:8988/api/v1/';
    }

    getBookingbyId(bookingid) {
        return this.http.get<Result>(`${this.APIURL}BBooking/getBokingbyId/` + bookingid);
    }

    getcalendarbookings(calendarbooking: BookingCalendar) {
        return this.http.post<Result>(`${this.APIURL}BBooking/get-calendarbookings`, calendarbooking);
    }

    getcalendarbookingsMultiRes(
        BookingCalendarMulti: BookingCalendarMultiResources,
        contactIdForMyBookingRequest = null
    ) {
        if (!contactIdForMyBookingRequest)
            // return this.http.post<Result>(`${this.APIURL}BBooking/get-multi-calendarbookings`, BookingCalendarMulti);
            return this.http.post<Result>(
                `${this.APIURL}OptimizedBooking/get-multi-calendarbookingsEnhanced`,
                BookingCalendarMulti
            );
        else {
            var Qpram = '?contactId=' + contactIdForMyBookingRequest;
            // return this.http.post<Result>(`${this.APIURL}BBooking/get-multi-calendarbookings${Qpram}`, BookingCalendarMulti);
            return this.http.post<Result>(
                `${this.APIURL}OptimizedBooking/get-multi-calendarbookingsEnhanced${Qpram}`,
                BookingCalendarMulti
            );
            //const localUrl = 'http://localhost:56622/api/v1/';
            //return this.http.post<Result>(`${localUrl}BBooking/get-multi-calendarbookings${Qpram}`, BookingCalendarMulti);
        }

        //const localUrl = 'http://localhost:56622/api/v1/';
        //return this.http.post<Result>(`${localUrl}BBooking/get-multi-calendarbookings?contactId=84aaa116-988e-407b-9e2b-7eba1ce07111`, BookingCalendarMulti);

        // return this.http.post<Result>(`${localUrl}BBooking/get-multi-calendarbookings`, BookingCalendarMulti);
    }

    getPredefinedcalendarbookingsMultiRes(BookingCalendarMulti: BookingCalendarMultiResources) {
        // return this.http.post<Result>(`${this.APIURL}BBooking/get-multi-calendarbookings`, BookingCalendarMulti);
        return this.http.post<Result>(
            `${this.APIURL}OptimizedBooking/get-multi-calendarbookingsEnhanced?bT=Pr-B`,
            BookingCalendarMulti
        );
    }

    addbooking(booking: any, isCleaningEnabled) {
        var Qterm = '';
        if (isCleaningEnabled == true) Qterm = '?isCleaningEnabled=true';

        return this.http.post<Result>(`${this.APIURL}BBooking/add-AttendeeResourceBooking` + Qterm, booking);
        //return this.http.post<Result>(`https://localhost:44342/api/v1/BBooking/add-AttendeeResourceBooking` + Qterm , booking);
    }

    addbookingWithCult(booking: any, isCleaningEnabled, titleCultureId, titleCulture, DesCultureId, descCulture) {
        debugger;
        var Qterm = '';
        var titCultId = '';
        var titCult = '';
        var desCultId = '';
        var desCult = '';
        if (isCleaningEnabled == true) Qterm = '?isCleaningEnabled=true';
        if (titleCultureId != null) titCultId = '&titleCultureId=' + titleCultureId;
        if (titleCulture != null) titCult = '&titleCulture=' + titleCulture;
        if (DesCultureId != null) desCultId = '&DesCultureId=' + DesCultureId;
        if (descCulture != null) desCult = '&descCulture=' + descCulture;

        return this.http.post<Result>(
            `${this.APIURL}BBooking/add-AttendeeResourceBooking` + Qterm + titCultId + titCult + desCultId + desCult,
            booking
        );
    }

    addbookingWithTrans(booking: any, isCleaningEnabled, titleTransObj, desTransObj) {
        debugger;
        var Qterm = '';

        if (isCleaningEnabled == true) Qterm = '?isCleaningEnabled=true';
        if (titleTransObj != null && isCleaningEnabled == true) titleTransObj = '&titleTransObj=' + titleTransObj;
        if (titleTransObj != null && isCleaningEnabled != true) titleTransObj = '?titleTransObj=' + titleTransObj;
        if (desTransObj != null) desTransObj = '&desTransObj=' + desTransObj;

        return this.http.post<Result>(
            `${this.APIURL}BBooking/add-AttendeeResourceBooking` + Qterm + titleTransObj + desTransObj,
            booking
        );
    }

    addPredefinedbooking(booking: any) {
        return this.http.post<Result>(`${this.APIURL}PR/Add_New_PR_Booking`, booking);
    }

    searchBookings(pageSize, PageNumber, searchDto: any) {
        return this.http.post<Result>(
            `${this.APIURL}OptimizedBooking/SearchBookings/${pageSize}/${PageNumber}`,
            searchDto
        );
    }

    addVisualBooking(serviceId, resourceGroupId, booking: any) {
        return this.http.post<Result>(
            `${this.APIURL}BBooking/add-VisualBooking/${serviceId}/${resourceGroupId}`,
            booking
        );
    }

    editVisualBooking(serviceId, resourceGroupId, booking: any) {
        return this.http.post<Result>(
            `${this.APIURL}BBooking/edit-VisualBooking/${serviceId}/${resourceGroupId}`,
            booking
        );
    }

    cancelVisualBooking(bookingId) {
        return this.http.post<Result>(`${this.APIURL}BBooking/CancelVisualBooking/${bookingId}`, {});
    }

    editbooking(booking: any, isCleaningEnabled) {
        var Qterm = '';
        if (isCleaningEnabled == true) Qterm = '?isCleaningEnabled=true';
        return this.http.post<Result>(`${this.APIURL}BBooking/edit-AttendeeResourceBooking` + Qterm, booking);
    }

    editCalendarbooking(booking: any) {
        return this.http.post<Result>(`${this.APIURL}BBooking/edit-CalendarBooking`, booking);
    }

    editbookingwithRecurrence(booking: any, isCleaningEnabled) {
        var Qterm = '';
        if (isCleaningEnabled == true) Qterm = '?isCleaningEnabled=true';
        return this.http.post<Result>(`${this.APIURL}BBooking/edit-AttendeeResourceBookingRecurrence` + Qterm, booking);
    }
    addRecurranceBooking(booking: any, isCleaningEnabled) {
        var Qterm = '';
        if (isCleaningEnabled == true) Qterm = '?isCleaningEnabled=true';
        return this.http.post<Result>(`${this.APIURL}BBooking/add-Reccurance` + Qterm, booking);
    }

    getresourcecalendars(resourceid: any) {
        return this.http.get<Result>(`${this.APIURL}BBooking/get-resourceCalendars/` + resourceid);
    }
    getBookingRecuurance(bookingId: any) {
        return this.http.get<Result>(`${this.APIURL}BBooking/book-Recuurance/` + bookingId);
    }
    deleteBooking(booking: any) {
        return this.http.post<Result>(`${this.APIURL}BBooking/delete-AttendeeResourceBooking`, booking);
    }
    deleteBookinginResource(booking: any) {
        return this.http.post<Result>(`${this.APIURL}BBooking/delete-AttendeeResourceBookingResource`, booking);
    }
    deleteRecurranceBooking(booking: any) {
        return this.http.post<Result>(`${this.APIURL}BBooking/delete-AttendeeResourceBookingWithRecurrance`, booking);
    }
    deletePrepareData(booking: any) {
        return this.http.post<Result>(`${this.APIURL}BBooking/delete-PrepareData`, booking);
    }

    getUserListOfApporvals() {
        return this.http.get<Result>(`${this.APIURL}BBooking/getUserListOfApporvals`);
        //return this.http.get<Result>(`https://localhost:44342/api/v1/BBooking/getUserListOfApporvals`);
    }

    approveAll(bookingId, resName: string, requsterEmail) {
        return this.http.get<Result>(
            `${this.APIURL}Approval/Accept/` + bookingId + '/' + resName + '/' + requsterEmail + '?internal=true'
        );
    }
    rejectAll(bookingId, resName, requsterEmail) {
        return this.http.get<Result>(
            `${this.APIURL}Approval/Reject/` + bookingId + '/' + resName + '/' + requsterEmail + '?internal=true'
        );
    }
    approveAllBookings(bookingDtos: any) {
        return this.http.post<Result>(`${this.APIURL}Approval/Approve-all-booking`, bookingDtos);
    }
    rejectAllBookings(bookingDtos: any) {
        return this.http.post<Result>(`${this.APIURL}Approval/Reject-all-booking`, bookingDtos);
    }
    PrepareDataForApproveAllBooking(bookingDtos: any) {
        return this.http.post<Result>(`${this.APIURL}Approval/Approve-PrepareData`, bookingDtos);
    }
    PrepareDataForRejectAllBooking(bookingDtos: any) {
        return this.http.post<Result>(`${this.APIURL}Approval/Reject-PrepareData`, bookingDtos);
    }
    getRecDetails(recId) {
        return this.http.get<Result>(`${this.APIURL}BBooking/getReccuranceDetials/` + recId);
        //return this.http.get<Result>(`https://localhost:44342/api/v1/BBooking/getReccuranceDetials/` + recId );
    }

    approveSelected(bookingDtos: any, mainBookingId) {
        return this.http.post<Result>(`${this.APIURL}BBooking/approveSelected` + mainBookingId, bookingDtos);
    }
    rejectSelected(bookingDtos: any, mainBookingId) {
        return this.http.post<Result>(`${this.APIURL}BBooking/rejectSelected` + mainBookingId, bookingDtos);
    }
    getValidResources(bookingId, startDate, endDate, startTime, endTime, ExcludeFixedBookingResources = false) {
        return this.http.get<IResource[]>(
            `${this.APIURL}BBooking/get-availableResources/` +
                bookingId +
                '/' +
                startDate +
                '/' +
                endDate +
                '/' +
                startTime +
                '/' +
                endTime +
                `?ExcludeFixedBookingResources=${ExcludeFixedBookingResources}`
        );
    }
    GetResourcesWorkingHours() {
        return this.http.get<Result>(`${this.APIURL}BBooking/GetResourcesWorkingHours`);
        //return this.http.get<Result>(`http://localhost:8988/api/v1/BBooking/GetResourcesWorkingHours`);
    }

    RetrieveLayoutWithBookingInfo(resourceGroupId, date, timeRange) {
        return this.http.get<any>(
            `${this.APIURL}layout/RetrieveLayoutWithBookingInfo/${resourceGroupId}/${date}/${timeRange}`
        );
    }

    //
    GetAvailableServices() {
        return this.http.get<any>(`${this.APIURL}BBooking/GetAvailableServices`);
    }

    getServiceResources(serviceId) {
        return this.http.get<any>(`${this.APIURL}Services/getServcieResources/${serviceId}`);
    }

    GetAvailableDateByServiceResource(serviceId, resourceId) {
        return this.http.get<any>(
            `${this.APIURL}BBooking/GetAvailableDateByServiceResource/${serviceId}/${resourceId}`
        );
    }

    GetAvailableSlotsByResource(serviceId, resourceId, date) {
        return this.http.get<any>(
            `${this.APIURL}BBooking/GetAvailableSlotsByResource/${serviceId}/${resourceId}/${date}`
        );
    }

    GetVisualBookingById(bookingId) {
        return this.http.get<any>(`${this.APIURL}BBooking/GetVisualBookingById/${bookingId}`);
    }

    RetrieveGenericLayoutWithBookingInfo(layoutId, date, timeRange) {
        return this.http.get<any>(
            `${this.APIURL}layout/RetrieveGenericLayoutWithBookingInfo/${layoutId}/${date}/${timeRange}`
        );

        // return this.http.get<any>(
        //     `http://localhost:8988/api/v1/layout/RetrieveGenericLayoutWithBookingInfo/${layoutId}/${date}/${timeRange}`
        // );
    }

    // visitor regoin
    getAllPurpose() {
        return this.http.get<Result>(`${this.APIURL}Vistor/GetAllVisitpurpose`);
    }
    getPurposeById(id) {
        return this.http.get<Result>(`${this.APIURL}Vistor/GetVisitpurposeById/` + id);
    }
    addPurpose(form) {
        return this.http.post<Result>(`${this.APIURL}Vistor/AddVisitpurpose`, form);
    }
    editPurpose(form) {
        return this.http.post<Result>(`${this.APIURL}Vistor/EditVisitpurpose`, form);
    }
    deletePurpose(id) {
        return this.http.get<Result>(`${this.APIURL}Vistor/DeleteVisitpurpose/` + id);
    }
    deletePurposes(ids) {
        return this.http.post<Result>(`${this.APIURL}Vistor/DeleteVisitpurposes`, ids);
    }

    getAllInstructions() {
        return this.http.get<Result>(`${this.APIURL}Vistor/GetAllVisitinstructions`);
    }
    getInstructionsById(id) {
        return this.http.get<Result>(`${this.APIURL}Vistor/GetVisitinstructionsById/` + id);
    }
    addInstructions(form) {
        return this.http.post<Result>(`${this.APIURL}Vistor/AddVisitinstructions`, form);
    }
    editInstructions(form) {
        return this.http.post<Result>(`${this.APIURL}Vistor/EditVisitinstructions`, form);
    }
    deleteInstructions(id) {
        return this.http.get<Result>(`${this.APIURL}Vistor/DeleteVisitinstructions/` + id);
    }
    deleteInstructionsList(ids) {
        return this.http.post<Result>(`${this.APIURL}Vistor/DeleteVisitinstructionsList`, ids);
    }

    getAllInvitations() {
        return this.http.get<Result>(`${this.APIURL}Vistor/GetAllInvitations`);
    }
    getInvitationById(id) {
        return this.http.get<Result>(`${this.APIURL}Vistor/GetInvitationById/` + id);
    }
    addInvitation(form) {
        return this.http.post<Result>(`${this.APIURL}Vistor/AddInvitation`, form);
    }
    editInvitation(form) {
        return this.http.post<Result>(`${this.APIURL}Vistor/EditInvitation`, form);
    }
    deleteInvitation(id) {
        return this.http.get<Result>(`${this.APIURL}Vistor/DeleteInvitation/` + id);
    }
    deleteInvitations(ids) {
        return this.http.post<Result>(`${this.APIURL}Vistor/DeleteInvitations`, ids);
    }

    getHowInAttend(form) {
        return this.http.post<Result>(`${this.APIURL}layout/GetHowIn/`, form);
    }
}
