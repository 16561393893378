import { WorkbookDto } from './';
import { RowDto } from './';

export class SheetDto {
    sheetId: number;
    sheetName: string;
    sheetDescription: string;
    workbookId: number;

    workbook: WorkbookDto;
    columns: string[] = [];
    rows: RowDto[]=[];
}