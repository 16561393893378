/**
 * This module is used to language translations.
 * The translations are saved in a json file in /src/app/assets/i18n directory
 * Docs: https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular7-app-with-ngx-translate
 */
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomLoader } from 'app/custom-loader';

// ngx-translate - required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [],
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomLoader,
                deps: [HttpClient]
            }
        })
    ],
    exports: [TranslateModule]
})
export class LanguageTranslationModule {
    pushRightClass = 'push-right';
    constructor(private translate: TranslateService, public router: Router) {
        // Gets Default language from browser if available, otherwise set English ad default
        this.translate.addLangs(['en', 'fr', 'ar', 'ur', 'es', 'it', 'fa', 'de', 'zh-CHS']);
        var langCode = localStorage.getItem('langCode');
        if (langCode !== null && langCode !== '' && langCode !== 'undefined') {
            this.translate.setDefaultLang(langCode.toLowerCase());
            this.translate.use(langCode.toLowerCase());
        } else {
            this.translate.setDefaultLang('en');
            this.translate.use('en');
        }

        var dir = localStorage.getItem('Dir');

        //  this.translate.use(langCode.toLowerCase());
        if (dir == 'R') {
            this.rlt();
        } else {
            this.ltr();
        }
    }

    rlt() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }
    ltr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('ltr');
    }
}
