export class Sales {
    birthDate: Date;
    city: string;
    color: string;
    country: string;
    dealerId: number;
    deleteDate: Date;
    designation: string;
    email: string;
    fax: string;
    gendar: string;
    homeTelephone: string;
    isPurchase: boolean;
    language: string;
    line: string;
    make: string;
    maritalStatus: string;
    mobile: string;
    modelYear: number;
    name: string;
    nationality: string;
    officeTelephone: string;
    otherVeichle: string;
    outletCode: number;
    outletDescription: string;
    outletType: string;
    poBox: string;
    postalCode: string;
    prefix: string;
    purchaseDate: Date;
    purchaseMode: string;
    registerNo: string;
    salesman: string;
    saleType: string;
    showroom: string;
    vin: string;
   
}