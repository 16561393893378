import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-capture-data-button',
  templateUrl: './capture-data-button.component.html',
  styleUrls: ['./capture-data-button.component.scss']
})
export class CaptureDataButtonComponent implements OnInit {

  constructor() { }


  @Input('mode')
  mode = 'dynamicMode';

  @Input('cardTitle')
  cardTitle;

  @Input('iconOfBtn')
  iconOfBtn;


  @Output('dataChanged')
  dataChanged: EventEmitter<any> = new EventEmitter<any>();
  outputData: any;


  @Input('disabled')
  disabled: false;


  @Input('btnName')
  btnName = 'Apply';

  @Input('addApplyBtn')
  addApplyBtn;

  @Input('functionToRun')
  functionToRun: (args: any) => string;

  @Input('fileds') // max 3 fileds
  fileds;

  // fildObjLike ----> {
  //   fName: '',
  //   fVal: any ,
  //   fDataType: ''                            /// for now 'text' or 'bool' or 'list',
  //   listItemsOfListType: [],
  //    displayLabelOflistType: '' ,
  //    valOfListType: '',
  //    chVal : '',
  //    chLabel : ''
  // }




  // if wanaa use static

  @Input('f1')
  f1;
  @Input('f2')
  f2;
  @Input('f3')
  f3;
  @Input('f4')
  f4;

  @Input('staticFiledsLables')
  staticFiledsLables =
    {
      f1: 'F1',
      f2: 'F2',
      f3: 'F3',
      f4: 'F4'
    };



  toggleOn = false;
  isAnyListOpen;
  changeZIndex(val) {
    this.isAnyListOpen = val;
  }

  ngOnInit(): void {

  }

  toggleAndPostion(btn) {
    // containerDivEle animated animate__flipInY
    this.messageReturing = '';
    this.messageReturingText = '';
    this.messageReturingHeader = '';
    this.toggleOn = !this.toggleOn;   
  }


  messageReturing = '';
  messageReturingText = '';
  messageReturingHeader = '';
  public submitInputAndFireOutPuts() {

    // console.log('fildesArr');
    // console.log(this.fileds);
    this.outputData = this.fileds;

    this.dataChanged.emit(this.outputData);
    if (this.functionToRun) {
      this.messageReturing = this.functionToRun(this.outputData);
      if (this.messageReturing && this.messageReturing.indexOf('####') > -1) {
        this.messageReturingText = this.messageReturing.split('####')[1];
        this.messageReturingHeader = this.messageReturing.split('####')[0];
      }
      else
        this.messageReturingText = this.messageReturing;
    }
  }

}
