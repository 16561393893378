import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-sw-generic-alert',
  templateUrl: './sw-generic-alert.component.html',
  styleUrls: ['./sw-generic-alert.component.scss']
})
export class SwGenericAlertComponent implements OnInit , OnDestroy {

  constructor() { 
   
  }

  public showAlert: boolean = false;
  public top: number = 0;
  public left: any = 0;  
  public right: any = 0;
  public mainMessage: string = '';
  public data: number = 0;
  public showingDuration: number = 2000; // in ms
  public isError : boolean = true;
  public icon : any = '';
  public isRtl : boolean = false;

  ngOnInit(): void {

  }

  public ShowAlertDialog(setting:any){

    this.top = setting.top;
    this.left = setting.left;
    this.data = setting.data;
    this.mainMessage = setting.mainMessage;
    this.showingDuration = setting.showingDuration;
    this.showAlert = true;
   
    var currentLang = localStorage.getItem('userLanguage');   
    if (currentLang == '8eb12a2b-4ff7-464b-b02f-2df8b2fce8c0') {
      this.isRtl = true;    
      this.right = this.left;
      this.left = 'auto';
    }
    else {
      this.isRtl = false;                 
      this.right = 'auto';
    }

    if (setting.isError === false)
          this.isError = false;
    if (setting.icon)
      this.icon = setting.icon;
   
   setTimeout(() => {    
     var mainDiv = document.getElementById('messageDiv');
     if(mainDiv)
       mainDiv.classList.add('fadeOut');

     setTimeout(() => {
       this.showAlert = false;
     }, 500);    

   }, this.showingDuration);

   
  }

  public hideDialog() {   
    this.showAlert = false;
  }

  ngOnDestroy(): void {
     clearTimeout();     
    this.hideDialog();
  }

}
