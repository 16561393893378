import { Branch } from './branch';
import { City } from './city';

export class Country {
    countryId: string;
    countryText: string;
    valid: boolean;
    branch: Branch;
    city: City;
}
