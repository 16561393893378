import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {
    transform(array: any[], key: string, value: string, secondKey?: string): any[] {
        if (value === '') return array;
        if (secondKey) {
            return array.filter((item) => {
                return `${item[key]}**${item[secondKey]}`.toLowerCase().includes(value.toLowerCase());
            });
        }
        return array.filter((item) => item[key].toLowerCase().includes(value.toLowerCase()));
    }
}
