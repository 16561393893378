import { DatabaseColumn } from './';

export class Table {
    name: string;
    fullName: string;
    schemaName:string;
    databaseName:string;
    columns: DatabaseColumn[];

}

export class TableWithNoCols
{
    name: string;
    fullName: string;
    schemaName:string;
    databaseName:string;
}