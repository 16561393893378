import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import swal from 'sweetalert2';
import { BaseService } from '@shared/index';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AntiBackGuardService implements CanDeactivate<any>, OnDestroy {
    private _unsubscribeAll$ = new Subject<boolean>();

    constructor(private _BaseService: BaseService) { }

    canDeactivate(component: any) {
        if (component.changeNewContant || component.changeNewContent) {
            return this.canDea(component);
        }
        return true;
    }
    canDea(component: any): Promise<any> | boolean {
        if (component.changeNewContant || component.changeNewContent) {
            return new Promise((resolve, reject) => {
                this._BaseService
                    .get('Message/1')
                    .pipe(takeUntil(this._unsubscribeAll$), first())
                    .subscribe((result) => {
                        // console.log(result.data);

                        const data = result.data;

                        swal.fire({
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            title: data.messageTitle,
                            text: data.messageText,
                            type: data.icon.toLowerCase(),
                            showCancelButton: data.buttonTwoTitle ? true : false,
                            confirmButtonColor: '#FF8989',
                            cancelButtonColor: '#BCBCCB',
                            cancelButtonText: data.buttonTwoTitle,
                            confirmButtonText: data.buttonOneTitle
                        }).then((action) => {
                            if (action.value) {
                                resolve(false);
                            } else {
                                resolve(true);
                            }
                        });
                    });
            });
        } else {
            return true;
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next(true);
        this._unsubscribeAll$.complete();
    }
}
