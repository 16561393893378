import { DatabaseForgeinKey } from './';
import { DatabaseDataType } from './';

export class DatabaseColumn {
    name: string;
    order: number;
    
    maxLen: number;
    precision: number;
    scale: number;
    isRowGuid: boolean;
    isNull: boolean;
    isIdentity: boolean;
    isComputed: boolean;
    isPrimaryKey: boolean;
    isForeignKey: boolean;
    isUnique: boolean;
    isUniqueConstraint: boolean;
    uniqueConstraintGroupName: string;
    default: string;
    foreignKey: DatabaseForgeinKey;    
    type: DatabaseDataType;

}