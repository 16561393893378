
export class ActivityType {
    activityTypeId: string;
    activityTypeName: string;
    requireApproval: boolean;
    isActive: boolean;
    createdOn?: Date;
    createdBy: string;
    modifiedOn?: Date;
    modifiedBy: string;
}
