
export class VinDecodeResult {

 
    vin:string;
    manufacturer:string;
    vehicLeType:string;
    gvwrRange:string;
    brakeSystem:string;
    model:string;
    make:string;
    seriesName:string;
    cabType:string;
    is2wd:boolean;
    is4wd:boolean;
    displacement:string;
    cylinders:string;
    fuel:string;
    modelyear:number;
    plant:string;
    city:string;
    country:string;
    serial:string;
    makeCode:string;
    modelCode:string;
    engineCOde:String;
    engineText:String;





}