export class TaskType {
    taskTypeId: string;
    taskTypeName: string;
    isActive: boolean;
    createdOn?: Date;
    createdBy: string;
    modifiedOn?: Date;
    modifiedBy: string;
}

